import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  public readonly API_ENDPOINT: string = 'https://chat-api.madscientist.tech'; 
  
  constructor(private http: HttpClient ) {

  }

  fetchChats(){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.get(this.API_ENDPOINT+"/chat/fetchUsers",{ headers: headers });
  }
  fetchChatMessages(mad_id:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.get(this.API_ENDPOINT+"/chat/get/"+mad_id,{ headers: headers });
  }
  markAsRead(payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/chat/markasread",payload,{ headers: headers });
  }
  sendMessage(mad_id:any,payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/chat/send/"+mad_id,payload,{ headers: headers });
  }

}
