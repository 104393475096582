<div class="settings_notifications_wrapper">
    <div class="settings_notifications no-scrollbar">
        <ul class="settings_notifications_setting_info_list">
            <li>
                <p>In-app notifications</p>
                <div class="settings_notifications_setting_info_list_label_group">
                    <p class="settings_notifications_setting_info_list_label_desc">By default, you will receive personal
                        notifications on the platform, such as</p>
                    <label class="settings_notifications_setting_info_list_label" for="appreciations">
                        <input type="checkbox" checked disabled name="appreciations" id="appreciations">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#10776B" />
                            <path d="M4 8L6.22569 10.2257C6.6415 10.6415 7.32457 10.6105 7.70102 10.1588L12 5"
                                stroke="#CCCCCC" stroke-linecap="round" />
                        </svg>
                        <p class="settings_notifications_setting_info_list_label_p">Appreciations</p>
                    </label>
                    <label class="settings_notifications_setting_info_list_label" for="comments">
                        <input type="checkbox" checked disabled name="comments" id="comments">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#10776B" />
                            <path d="M4 8L6.22569 10.2257C6.6415 10.6415 7.32457 10.6105 7.70102 10.1588L12 5"
                                stroke="#CCCCCC" stroke-linecap="round" />
                        </svg>
                        <p class="settings_notifications_setting_info_list_label_p">Comments</p>
                    </label>
                    <label class="settings_notifications_setting_info_list_label" for="forums">
                        <input type="checkbox" checked disabled name="forums" id="forums">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#10776B" />
                            <path d="M4 8L6.22569 10.2257C6.6415 10.6415 7.32457 10.6105 7.70102 10.1588L12 5"
                                stroke="#CCCCCC" stroke-linecap="round" />
                        </svg>
                        <p class="settings_notifications_setting_info_list_label_p">Forums</p>
                    </label>
                    <label class="settings_notifications_setting_info_list_label" for="followers">
                        <input type="checkbox" checked disabled name="followers" id="followers">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#10776B" />
                            <path d="M4 8L6.22569 10.2257C6.6415 10.6415 7.32457 10.6105 7.70102 10.1588L12 5"
                                stroke="#CCCCCC" stroke-linecap="round" />
                        </svg>
                        <p class="settings_notifications_setting_info_list_label_p">Followers</p>
                    </label>
                    <label class="settings_notifications_setting_info_list_label" for="report">
                        <input type="checkbox" checked disabled name="report" id="report">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#10776B" />
                            <path d="M4 8L6.22569 10.2257C6.6415 10.6415 7.32457 10.6105 7.70102 10.1588L12 5"
                                stroke="#CCCCCC" stroke-linecap="round" />
                        </svg>
                        <p class="settings_notifications_setting_info_list_label_p">Report</p>
                    </label>
                </div>
            </li>
            <li>
                <p>Announcements</p>
                <div>
                    <p class="settings_notifications_setting_info_list_label_p">Announcements, on the other hand, will be made public to inform everyone on the platform about
                        product features and new releases.</p>
                </div>
            </li>
        </ul>
        <ul class="settings_notifications_setting_info_list">
            <li>
                <p>Email notifications :</p>
                <div class="settings_notifications_email_list_wrapper">
                    <p>
                        We don't spam you too much with emails except for :
                    </p>
                    <ul class="settings_notifications_email_list">
                        <li>
                            <p class="settings_notifications_email_list_p">Password change</p>
                        </li>
                        <li>
                            <p class="settings_notifications_email_list_p">OTP for different use cases</p>
                        </li>
                        <li>
                            <p class="settings_notifications_email_list_p">Few information or promotional updates</p>
                        </li>
                        <li>
                            <p class="settings_notifications_email_list_p">Account deactivation</p>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</div>