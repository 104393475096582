import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddWriteupComponent } from 'src/app/popups/add-writeup/add-writeup.component';
import { ReportComponent } from 'src/app/popups/report/report.component';
import { WriteupViewComponent } from 'src/app/popups/writeup-view/writeup-view.component';
import { AlertService } from 'src/app/services/alert.service';
import { GeneralService } from 'src/app/services/general.service';
import { WriteupService } from 'src/app/services/writeup.service';

@Component({
  selector: 'app-writeup',
  templateUrl: './writeup.component.html',
  styleUrls: ['./writeup.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WriteupComponent {
  @Input() writeup: any = {
    writeup_id: 0,
    title: 'this is my writeup',
    data: 'my writeup ',
    created_by: 14,
    created_on: '2024-01-17T18:12:07.000Z',
    has_attachment: 'null',
    attachment_type: 'null',
    attachment_path: null,
    likes: null,
    mad_id: 14,
    user_name: 'superscientist',
    first_name: 'Super',
    last_name: 'Scientist',
    bio: "I'm crazy for Bhanu Mergoju and MadScientist !",
    tagline: 'Super crazy mad about MadScientist !',
    profile_photo_path: '1705227415053-1.jpg',
    total_comments: 0,
    total_likes: 0,
    total_views: 1,
  };
  mad_id: any;
  isHidden = false;

  constructor(
    public dialog: MatDialog,
    private _writeupService: WriteupService,
    private _generalService: GeneralService,
    private router: Router,
    private alertService: AlertService,
  ) {
    this.mad_id = localStorage.getItem('mad_id');
  }
  getDownloadUrl(path: String) {
    if (path) {
      return (
        this._generalService.API_ENDPOINT +
        '/users/file/download?filename=' +
        path
      );
    } else {
      return '';
    }
  }
  viewWriteup(writeup:any){
    let urlTxt=btoa(writeup.writeup_id);
    let path = "/home/writeups/" + urlTxt;
    this.router.navigate([path]);
  }

  openDialog(writeup: any) {
    const dialogRef = this.dialog.open(WriteupViewComponent, {
      data: writeup,
      width: '100%',
      height: 'auto',
      backdropClass: 'bdrop',
      position: { bottom: '0px', left: '10%', right: '0px', top: '0.8%' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if(result.edit){
        this.onEdit(result.writeup);
      }
    });
  }
  onEdit(writeup:any){
    const dialogRefIn = this.dialog.open(AddWriteupComponent, {
      data: {
        type: 'edit',
        writeup:writeup
      },
      width: '60%',
      height: '60%',
      backdropClass: "bdrop",
      position: {
        'bottom': '0px',
        'left': '20%',
        'right': '0px',
        'top': '10%'
      }
    }
    );
  }
  save() {
    let pararm = {
      mad_id: this.mad_id,
      post_type: 'writeups',
      post_id: this.writeup.writeup_id,
    };
    this._generalService.save(pararm).subscribe((data: any) => {
      if (data) {
        console.log(data);
        if (data.status && data.status.code == '00') {
          console.log('saved');
          this.isHidden = false;
        }
      } else {
      }
    });
  }
  deleteWriteup() {
    this._writeupService
      .deleteWriteup(this.writeup.writeup_id)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data.status && data.status.code == '00') {
            console.log('deleted');
            this.writeup = {};
            this.isHidden = false;
          }
        } else {
        }
      });
  }
  report() {
    const dialogRef = this.dialog.open(ReportComponent, {
      data: { post_type: 'writeups', post_id: this.writeup.writeup_id },
      width: 'min(100%, 33rem)',
      height: 'min(90%, 42rem)',
      backdropClass: 'bdrop',
      position: { bottom: '0px', left: '25%', right: '0px', top: '6.5%' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  navigateToProfile(username: any) {
    let path = '/home/profile/' + username;
    this.router.navigate([path]);
  }
  share() {
    let urlTxt=btoa("writeup|"+this.writeup.writeup_id);
    if (navigator.share) {
      navigator.share({
        title: 'MadScientist | Technology Network',
        text: this.writeup.title,
        url: window.location.origin+"/home/public/"+urlTxt,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    } else {
      this.fallbackShare(urlTxt);
    }
  }

  fallbackShare(urlTxt:any) {
    const url = window.location.origin+"/home/public/"+urlTxt;
    const textArea = document.createElement('textarea');
    textArea.value = url;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    //this.alertService.showAlert("success","Link copied to Clipboard");
  }
}
