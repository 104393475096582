import { SocialUser, GoogleLoginProvider, SocialAuthService, FacebookLoginProvider, AmazonLoginProvider, VKLoginProvider, MicrosoftLoginProvider } from '@abacritt/angularx-social-login';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AlertService } from 'src/app/services/alert.service';
import { AuthenticationService } from 'src/app/services/authentication.service';


interface WindowWithGapi extends Window {
  gapi: any;
}

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})

export class SignInComponent implements OnInit{
  user_name="";
  password="";
  alert:any;
  isLoading : boolean = false;
  userNamevalidationFlg=true;
  passwordValidationFlg=true;
  user: SocialUser | undefined;
  GoogleLoginProvider = GoogleLoginProvider;


  
  constructor(private _authService:AuthenticationService,
    private router: Router,
    private readonly _socialService: SocialAuthService,
    public alertService: AlertService,
    private cookieService: CookieService
  ){

  }

  ngOnInit() {
    if(localStorage.getItem("token")){
      console.log("token");
      this.router.navigate(["home"]);
    }
    this._socialService.authState.subscribe((user) => {
      this.user = user;
      console.log(this.user);
      this.onLogin('google');
    });
  }
  gsignIn(){
    this._socialService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
  gotoHome(){
    this.router.navigate([""]);
  }
  signup(){
    this.router.navigate(["signup"]);
  }
  forgotPass(){
    this.router.navigate(["resetpassword"]);
  }
  userNameValidation(){
    // this._authService.validateUserName({"user_name":this.user_name,"primary_mail":this.user_name})
    // .subscribe((data:any) => {
    //   if(data){
    //     if(data.exists==true){
    //       this.userNamevalidationFlg=true;
    //     }else{
    //       this.userNamevalidationFlg=false;
    //     }
    //   }else{
    //     this.userNamevalidationFlg=false;
    //   }
    // }
    // );
  }

  
  onLogin(domain: string) {
    let param: any = {};
    this.isLoading = true; 
    if (domain == 'web') {
      param = { "user_name": this.user_name, "password": this.password };
    } else if (domain == 'google') {
      if (this.user) {
        param = { "primary_mail": this.user.email, "googleId": this.user.id };
      }
    }
  
    console.log(param);
  
    this._authService.login(param)
      .subscribe(
        (data: any) => {
          this.isLoading = false; 
          if (data) {
            console.log(data);
            if (data.success) {
              if (data.activeStatus == "active") {
                this.cookieService.set('token', data.token, 90);
                localStorage.setItem("token", data.token);
                if (data.data) {
                  this.cookieService.set('mad_user', JSON.stringify(data.data), 90);
                  this.cookieService.set('mad_id', data.data.mad_id, 90);
                  localStorage.setItem("mad_user", JSON.stringify(data.data));
                  localStorage.setItem("mad_id", data.data.mad_id);
                  const userId = data.data.mad_id;
                  this.router.navigate(["home"]);
                }
              } else {
                this.passwordValidationFlg = false;
              }
            } else {
              if (domain != 'google') {
                this.passwordValidationFlg = false;
              } else {
                // Handle Google account not associated
                this._socialService.signOut();
              }
            }
          } else {
            if (domain != 'google') {
              this.passwordValidationFlg = false;
            } else {
              // Handle Google account not associated
              this._socialService.signOut();
            }
          }
        },
        (error) => {
          this.isLoading = false; 
          console.error(error);
        }
      );
  }
  





  signInWithFB(): void {
    this._socialService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signInWithAmazon(): void {
    this._socialService.signIn(AmazonLoginProvider.PROVIDER_ID);
  }

  signInWithVK(): void {
    this._socialService.signIn(VKLoginProvider.PROVIDER_ID);
  }

  // signInWithMicrosoft(): void {
  //   this._authService.signIn(MicrosoftLoginProvider.PROVIDER_ID);
  // }

  // signOut(): void {
  //   this._authService.signOut();
  // }

  refreshGoogleToken(): void {
    this._socialService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  }
  
}
