import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommunityService {
  public readonly API_ENDPOINT: string = 'https://mad-api.azurewebsites.net'; 

  constructor(private http: HttpClient ) {

  }

  GetCommunityData(){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.get(this.API_ENDPOINT+"/users/community",{ headers: headers });
  }
  
  getFollowers(page:number){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.get(this.API_ENDPOINT+"/users/connection/followers/"+page,{ headers: headers });
  }

  getFollowing(page:number){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.get(this.API_ENDPOINT+"/users/connection/following/"+page,{ headers: headers });
  }

  getCommunitySuggestions(page:number){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.get(this.API_ENDPOINT+"/users/connection/suggestion/"+page,{ headers: headers });
  }

  followUser(payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/connection/follow",payload,{ headers: headers });
  }
  unfollowUser(payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/connection/unfollow",payload,{ headers: headers });
  }


}
