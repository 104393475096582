<div class="discard_popup_box" *ngIf="discardFlag && !submitFlag">
  <div class="discard_popup">
    <h2 class="discard_title">Discard</h2>
    <div class="discard_text_box">
      <h2 class="discard_text">
        Are you sure you want to discard the Projection?
      </h2>
      <p class="discard_text_desc">
        Your Projection won't be saved if you leave
      </p>
    </div>
    <div class="discard_action_btns">
      <button
        class="discard_action_btn discard_action_btn--yes"
        (click)="close()"
      >
        <p>Yes</p>
      </button>
      <button
        class="discard_action_btn discard_action_btn--no"
        (click)="discardFlag = false"
      >
        <p>No</p>
      </button>
    </div>
  </div>
</div>
<div class="add_projection_header">
  <button class="close_btn" (click)="discardFlag = true">
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.10682 0.195262C1.84647 -0.0650874 1.42436 -0.0650874 1.16401 0.195262C0.903663 0.455612 0.903663 0.877722 1.16401 1.13807L6.02594 6L1.16401 10.8619C0.903663 11.1223 0.903663 11.5444 1.16401 11.8047C1.42436 12.0651 1.84647 12.0651 2.10682 11.8047L6.96875 6.94281L11.8307 11.8047C12.091 12.0651 12.5131 12.0651 12.7735 11.8047C13.0338 11.5444 13.0338 11.1223 12.7735 10.8619L7.91156 6L12.7735 1.13807C13.0338 0.877722 13.0338 0.455612 12.7735 0.195262C12.5131 -0.0650874 12.091 -0.0650874 11.8307 0.195262L6.96875 5.05719L2.10682 0.195262Z"
        fill="white"
      />
    </svg>
    <p>Discard</p>
  </button>
  <p class="add_post_title">Creating Projection</p>
  <button type="button" class="add_post_post_btn" (click)="addPost()">
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6464 4.64645C12.4512 4.84171 12.4512 5.15829 12.6464 5.35355C12.8417 5.54882 13.1583 5.54882 13.3536 5.35355L12.6464 4.64645ZM17.5 1C17.5 0.723858 17.2761 0.5 17 0.5H12.5C12.2239 0.5 12 0.723858 12 1C12 1.27614 12.2239 1.5 12.5 1.5H16.5V5.5C16.5 5.77614 16.7239 6 17 6C17.2761 6 17.5 5.77614 17.5 5.5V1ZM13.3536 5.35355L17.3536 1.35355L16.6464 0.646447L12.6464 4.64645L13.3536 5.35355Z"
        fill="white"
      />
      <path
        d="M9 1H5C2.79086 1 1 2.79086 1 5V13C1 15.2091 2.79086 17 5 17H13C15.2091 17 17 15.2091 17 13V9"
        stroke="white"
        stroke-width="1.5"
      />
    </svg>
    <p>Project</p>
  </button>
</div>
<div class="add_projection_box" *ngIf="!submitFlag">
  <div class="add_projection_content">
    <div class="add_projection_textarea_box">
      <textarea
        #textarea
        name="add_projection_textarea"
        id="add_projection_textarea"
        class="add_projection_textarea"
        [(ngModel)]="projection_caption"
        maxlength="3000"
      ></textarea>
      <div
        class="notext_default_placeholder_group"
        *ngIf="projection_caption == ''"
      >
        <h3 class="placeholder_text">
          You are in the right place. Start writing here!
        </h3>
        <p class="placeholder_helper_text">
          Consider beginning with your favorite interests in science &
          technology
        </p>
      </div>
      <span class="count"> {{ 3000 - projection_caption.length }}</span>
    </div>
  </div>
  <div class="add_file_box" *ngIf="!file">
    <input
      type="file"
      id="upload"
      style="display: none"
      (change)="onSelectFile($event)"
    />
    <label for="upload" role="button" class="add_file_btn">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1_7172_3520" fill="white">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6 0C3.79086 0 2 1.79086 2 4V20C2 22.2091 3.79086 24 6 24H18C20.2091 24 22 22.2091 22 20V12V6.5H18C16.6193 6.5 15.5 5.38071 15.5 4V0H12H6ZM16.5 0.5V4C16.5 4.82843 17.1716 5.5 18 5.5H21.5L16.5 0.5Z"
          />
        </mask>
        <path
          d="M22 6.5H23V5.5H22V6.5ZM15.5 0H16.5V-1H15.5V0ZM16.5 0.5L17.2071 -0.207107L15.5 -1.91421V0.5H16.5ZM21.5 5.5V6.5H23.9142L22.2071 4.79289L21.5 5.5ZM3 4C3 2.34315 4.34315 1 6 1V-1C3.23858 -1 1 1.23858 1 4H3ZM3 20V4H1V20H3ZM6 23C4.34315 23 3 21.6569 3 20H1C1 22.7614 3.23858 25 6 25V23ZM18 23H6V25H18V23ZM21 20C21 21.6569 19.6569 23 18 23V25C20.7614 25 23 22.7614 23 20H21ZM21 12V20H23V12H21ZM21 6.5V12H23V6.5H21ZM22 5.5H18V7.5H22V5.5ZM18 5.5C17.1716 5.5 16.5 4.82843 16.5 4H14.5C14.5 5.933 16.067 7.5 18 7.5V5.5ZM16.5 4V0H14.5V4H16.5ZM12 1H15.5V-1H12V1ZM6 1H12V-1H6V1ZM15.5 0.5V4H17.5V0.5H15.5ZM15.5 4C15.5 5.38071 16.6193 6.5 18 6.5V4.5C17.7239 4.5 17.5 4.27614 17.5 4H15.5ZM18 6.5H21.5V4.5H18V6.5ZM15.7929 1.20711L20.7929 6.20711L22.2071 4.79289L17.2071 -0.207107L15.7929 1.20711Z"
          fill="white"
          mask="url(#path-1-inside-1_7172_3520)"
        />
        <rect
          x="10.3"
          y="0.3"
          width="11.4"
          height="11.4"
          rx="5.7"
          fill="#191919"
          stroke="white"
          stroke-width="0.6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.9992 6.30078C19.1649 6.30078 19.2992 6.16647 19.2992 6.00078C19.2992 5.8351 19.1649 5.70078 18.9992 5.70078H16.2998V3.00078C16.2998 2.8351 16.1655 2.70078 15.9998 2.70078C15.8341 2.70078 15.6998 2.8351 15.6998 3.00078V5.70078H12.9992C12.8335 5.70078 12.6992 5.8351 12.6992 6.00078C12.6992 6.16647 12.8335 6.30078 12.9992 6.30078H15.6998V9.00078C15.6998 9.16647 15.8341 9.30078 15.9998 9.30078C16.1655 9.30078 16.2998 9.16647 16.2998 9.00078V6.30078H18.9992Z"
          fill="white"
        />
      </svg>
      <p>Add file</p>
    </label>
    <p class="add_file_helper">You can add Image, photo, video, 3D, PDF</p>
    <div class="error_msg_box">
      <p class="error_msg">{{ errorMsg }}</p>
    </div>
  </div>
  <div class="add_file_box" *ngIf="file">
    <div class="upload_file_box">
      <div class="upload_file">
        <div class="upload_file_icon_box">
          <div class="upload_file_icon">
            <img
              [src]="url"
              width="32"
              *ngIf="file.type && file.type.toLowerCase().includes('image')"
            />
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              *ngIf="file.type && !file.type.toLowerCase().includes('image')"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.5 9.5L24.5 8.5L21.5 5.5L20.5 4.5V5.91421V8C20.5 8.82843 21.1716 9.5 22 9.5H24.0858H25.5ZM21.5 6.91421L23.0858 8.5H22C21.7239 8.5 21.5 8.27614 21.5 8V6.91421ZM7 8C7 6.34315 8.34315 5 10 5H16H18.5V8C18.5 9.933 20.067 11.5 22 11.5H25V16V24C25 25.6569 23.6569 27 22 27H10C8.34315 27 7 25.6569 7 24V8ZM16 4H18.5H19.5V4.5V5V8C19.5 9.38071 20.6193 10.5 22 10.5H25H25.5H26V11.5V16V24C26 26.2091 24.2091 28 22 28H10C7.79086 28 6 26.2091 6 24V8C6 5.79086 7.79086 4 10 4H16ZM15.8139 19.0417V16.9007H15.4083V19.0417H15.8139ZM15.4461 16.0707C15.3989 16.1179 15.3753 16.1745 15.3753 16.2405C15.3753 16.3097 15.3973 16.3678 15.4413 16.415C15.4885 16.4621 15.5467 16.4857 15.6158 16.4857C15.685 16.4857 15.7432 16.4621 15.7903 16.415C15.8375 16.3678 15.861 16.3097 15.861 16.2405C15.861 16.1745 15.8359 16.1179 15.7856 16.0707C15.7384 16.0236 15.6818 16 15.6158 16C15.5498 16 15.4932 16.0236 15.4461 16.0707ZM13 16.0566V19.0417H13.4386V17.6788H14.5987V17.3157H13.4386V16.4197H14.8203V16.0566H13ZM16.965 16V19.0417H16.5594V16.2169L16.965 16ZM18.0128 18.108H19.5218V17.9665C19.5218 17.6647 19.4511 17.4147 19.3096 17.2167C19.1336 16.9683 18.8805 16.8441 18.5504 16.8441C18.28 16.8441 18.0521 16.9447 17.8666 17.1459C17.6622 17.3692 17.5601 17.6474 17.5601 17.9806C17.5601 18.3076 17.6544 18.5717 17.843 18.7729C18.0474 18.9898 18.3224 19.0983 18.6683 19.0983C18.9701 19.0983 19.231 19.0102 19.4511 18.8342L19.3285 18.6031C19.1273 18.7226 18.9292 18.7823 18.7343 18.7823C18.5331 18.7823 18.368 18.7226 18.2391 18.6031C18.1102 18.4805 18.0348 18.3155 18.0128 18.108ZM19.1068 17.792H17.9986C18.0143 17.5971 18.0725 17.4415 18.1731 17.3251C18.2769 17.2088 18.4073 17.1507 18.5645 17.1507C18.728 17.1507 18.8569 17.2072 18.9512 17.3204C19.0487 17.4305 19.1005 17.5876 19.1068 17.792Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="upload_file_icon_text">
            <p class="upload_file_icon_text_name">{{ file?.name }}</p>
            <p class="upload_file_icon_size">{{ file?.size | displaysize }}</p>
          </div>
        </div>
        <button class="upload_file_close_btn" (click)="cancelUpload()">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M14 6L6 14M6 6L14 14" stroke="white" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>
<label
  for="about_post_toggle"
  role="button"
  class="about_post_text_box"
  *ngIf="!submitFlag"
>
  <input type="checkbox" name="about_post_toggle" id="about_post_toggle" />
  <div class="about_post_header">
    <div class="about_post_title_box">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 8C10.5523 8 11 8.44771 11 9V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V9C9 8.44772 9.44771 8 10 8Z"
          fill="white"
        />
        <path
          d="M10 7C10.5523 7 11 6.55228 11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6C9 6.55228 9.44772 7 10 7Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
          fill="white"
        />
      </svg>
      <h4 class="about_post_title">What is projections?</h4>
    </div>
    <div class="dropdown_btn_box">
      <svg
        class="show_dropdown_svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="16"
          y="16"
          width="16"
          height="16"
          rx="4"
          transform="rotate(-180 16 16)"
          fill="#282828"
        />
        <path
          d="M13 6L8.88372 9.67334C8.39565 10.1089 7.60435 10.1089 7.11628 9.67334L3 6"
          stroke="white"
          stroke-linecap="round"
        />
      </svg>
      <svg
        class="hide_dropdown_svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="16" height="16" rx="4" fill="#282828" />
        <path
          d="M3 10L7.11628 6.32666C7.60435 5.89111 8.39565 5.89111 8.88372 6.32666L13 10"
          stroke="white"
          stroke-linecap="round"
        />
      </svg>
    </div>
  </div>
  <div class="about_post_content">
    <p>
      Content uploaded to MadScientist is referred to as a "Projection". A
      Projection can be anything you create and post on the MadScientist
      platform related to your interest in Science & Technology.
    </p>
    <br />
    <p>
      A Projection can be about writing on your innovation, discussing a topic
      in science, sharing your opinion about technology, or anything else you
      define in the modern tech world. Make it compelling and spread
      inspiration. Let your curiosity drive you.
    </p>
    <br />
    <p>Accepted Projection file types & sizes :</p>
    <br />
    <ul>
      <li>Image : PNG, JPEG/JPG, GIF - Max file size - 7 MB</li>
      <li>Video : MP4, MOV, MPEG, WEBM - Max file size - 60 MB</li>
      <li>Document: PDF - Max file size - 12 MB</li>
      <li>
        3D : GLB , GLTF (For better visualization, convert your other format 3D
        files into canvas-rendered GLTF/GLB 3D format and upload.) - Max file
        size - 80 MB
      </li>
    </ul>
    <br />
    <p>
      Note: You can upload one type of file format at a time. We are trying to
      improve this :)
    </p>
  </div>
</label>
<div *ngIf="submitFlag">
  <div class="black_bg_box">
    <div class="upload_file_box_wrapper popUpWrapper">
      <div class="progress_bar_box">
        <div class="progress_bar"></div>
      </div>
      <div class="upload_file_box" *ngIf="isLoading && !successFlg">
        <div class="upload_file_wrapper">
          <div class="upload_file">
            <!-- <svg width="35" height="34" viewBox="0 0 35 34" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="17.5" cy="17" r="16" stroke="#363636" stroke-width="2" />
                            <path
                                d="M28.502 5.38295C26.2044 3.20696 23.3131 1.76117 20.1937 1.2284C17.0744 0.695625 13.8671 1.09981 10.9775 2.38983C8.0879 3.67986 5.64571 5.79778 3.95978 8.47579C2.27385 11.1538 1.4199 14.2716 1.50591 17.4349C1.59193 20.5983 2.61405 23.665 4.44303 26.2475C6.272 28.8299 8.82568 30.812 11.7811 31.943C14.7366 33.0741 17.9611 33.3034 21.0468 32.6019"
                                stroke="#10776B" stroke-width="2" stroke-linecap="round" />
                        </svg> -->
            <svg
              width="35"
              height="34"
              viewBox="0 0 35 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17.5"
                cy="17"
                r="16"
                stroke="#363636"
                stroke-width="2"
              />
              <path
                id="loaderPath"
                d="M28.502 5.38295C26.2044 3.20696 23.3131 1.76117 20.1937 1.2284C17.0744 0.695625 13.8671 1.09981 10.9775 2.38983C8.0879 3.67986 5.64571 5.79778 3.95978 8.47579C2.27385 11.1538 1.4199 14.2716 1.50591 17.4349C1.59193 20.5983 2.61405 23.665 4.44303 26.2475C6.272 28.8299 8.82568 30.812 11.7811 31.943C14.7366 33.0741 17.9611 33.3034 21.0468 32.6019"
                stroke="#10776B"
                stroke-width="2"
                stroke-linecap="round"
              >
                <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  from="0 17 17"
                  to="360 17 17"
                  dur="2s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
            <div class="upload_file_text_group">
              <p class="upload_file_text">Uploading.</p>
              <p class="upload_file_desc">
                Please wait while projecting to MadScientist
              </p>
            </div>
          </div>
        </div>
        <div class="review_text_box">
          <p>
            Our team will verify your content. We may delete if it's not
            relevant to Madscientist, or any misinformation.
          </p>
        </div>
      </div>
      <div class="upload_success_box" *ngIf="successFlg">
        <div class="upload_success">
          <!-- <app-loader *ngIf="isLoading" style="width: 100%;"></app-loader>
          <div class="upload_success_text" *ngIf="isLoading">
            <p>Uploading Projection</p>
          </div> -->
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="24"
              cy="24"
              r="24"
              fill="url(#paint0_linear_7237_12527)"
            />
            <path
              d="M15.5 23.7579L20.6233 28.6099C21.1611 29.1192 22.029 29.1314 22.5825 28.6373L34.5 18"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_7237_12527"
                x1="24"
                y1="0"
                x2="24"
                y2="48"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#10776B" />
                <stop offset="1" stop-color="#009DA7" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
          <div class="upload_success_text">
            <p>Projected successfully</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
