import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  public readonly API_ENDPOINT: string = 'https://mad-api.azurewebsites.net'; 

  constructor(private http: HttpClient ) {

  }
  validateUserName(payload:any){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
    return this.http.post(this.API_ENDPOINT+"/users/checkvalid",payload,{ headers: headers });
  }
  save(payload:any){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/save",payload,{ headers: headers });
  }
  deactivate(payload:any){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/deactivate",payload,{ headers: headers });
  }

  addHelp(payload:any){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/help/add",payload,{ headers: headers });
  }
  report(payload:any){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/report/",payload,{ headers: headers });
  }
  profileReport(payload:any){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/report/profile",payload,{ headers: headers });
  }
  sendOtp(payload:any){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/otp/sendotp",payload,{ headers: headers });
  }
  validateOtp(payload:any){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/otp/checkotp",payload,{ headers: headers });
  }
  fileUpload(payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/file/upload?doCompress=true",payload,{ headers: headers });
  }
  fileUploadwoc(payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/file/upload?doCompress=false",payload,{ headers: headers });
  }

  getNotifications(pageNumber:number){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.get(this.API_ENDPOINT+"/users/notification/get/"+pageNumber,{ headers: headers }); 
  }
  markNotificationAsRead(payload:any){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/notification/read",payload,{ headers: headers }); 
  }

  getNotificationsCount(){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.get(this.API_ENDPOINT+"/users/notification/count",{ headers: headers }); 
  }

  
  userSearch(payload:any){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/search/user",payload,{ headers: headers });
  }
  fetchProjectionByProjId(pid:number){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/projection/getProjectionByProjectionId/"+pid,{},{ headers: headers });
  }
  fetchForumByProjId(fid:number){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/forums/getForumByForumId/"+fid,{},{ headers: headers });
  }

}
