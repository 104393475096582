import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public readonly API_ENDPOINT: string = 'https://mad-api.azurewebsites.net'; 

  constructor(private http: HttpClient ) { }

  validateUserName(payload:any){
    return this.http.post(this.API_ENDPOINT+"/users/checkvalid",payload);
  }

  login(payload:any){
    let headers = new HttpHeaders()
    .set('content-type','application/json')
    .set('Access-Control-Allow-Origin', '*')
    return this.http.post(this.API_ENDPOINT+"/users/login",payload,{ headers: headers });
  }
  resetPassword(payload:any){
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
    return this.http.post(this.API_ENDPOINT+"/users/resetpassword",payload,{ headers: headers });
  }




}
