import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo',
  pure: true
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (!value) return '';

    const currentDate = new Date();
    const pastDate = new Date(value);

    const seconds = Math.floor((currentDate.getTime() - pastDate.getTime()) / 1000);

    if(seconds==0){
      return "Just Now"
    }

    const intervals = [
      { label: 'year', seconds: 31536000 },
      { label: 'month', seconds: 2592000 },
      { label: 'week', seconds: 604800 },
      { label: 'day', seconds: 86400 },
      { label: 'hour', seconds: 3600 },
      { label: 'minute', seconds: 60 },
      { label: 'second', seconds: 1 },
    ];

    let counter;
    for (const interval of intervals) {
      counter = Math.floor(seconds / interval.seconds);
      if (counter > 0) {
        if (counter === 1) {
          return counter + ' ' + interval.label + ' ago';
        } else {
          return counter + ' ' + interval.label + 's ago';
        }
      }
    }

    return "Just Now";
  }

}
