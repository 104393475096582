import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ForumService } from 'src/app/services/forum.service';

@Component({
  selector: 'app-add-forum',
  templateUrl: './add-forum.component.html',
  styleUrls: ['./add-forum.component.css']
})
export class AddForumComponent {
  title="";
  your_view="";
  discardFlag=false;
  submitFlag=false;
  successFlg=false;
  isLoading=false;
  type="new";
  forum:any;

  constructor(private _forumService:ForumService,
    public dialogRef: MatDialogRef<AddForumComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){
      this.type=data.type;
      if(this.type=="edit"){
        this.forum=data.forum;
        this.title=this.forum.title;
        this.your_view=this.forum.your_view;  
      }
  }

  ngOnInit() {
    this.dialogRef.addPanelClass('add-forum-modal');
  }

  close(): void {
    this.dialogRef.close();
  }
  post(){
    this.submitFlag=true;
    if(this.type=="new"){
      let param={
        "title":this.title,
        "your_view":this.your_view,
        "created_by":localStorage.getItem("mad_id")
      }
      this._forumService.createForum(param)
      .subscribe((data:any) => {
        if(data){
          console.log(data);
          if(data.status){
            if(data.status.code=="00"){
              this.successFlg=true;
              console.log("Posted");
              this.close();
            }else{
              this.isLoading=false;
              this.successFlg=false;
              this.submitFlag=false;
            }
          }else{
            this.isLoading=false;
            this.successFlg=false;
            this.submitFlag=false;
          }
        }else{
          
        }
      }
      );  
    }else{
      let param={
        "title":this.title,
        "data":this.your_view,
        "post_id":this.forum.forum_id
      }
      this._forumService.editForum(param)
      .subscribe((data:any) => {
        if(data){
          console.log(data);
          if(data.status){
            if(data.status.code=="00"){
              this.successFlg=true;
              console.log("edited");
              this.close();
            }
          }
        }else{
          
        }
      }
      );  

    }
  }
}
