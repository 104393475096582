import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-deactivate',
  templateUrl: './deactivate.component.html',
  styleUrls: ['./deactivate.component.css']
})
export class DeactivateComponent {
  reason="";
  check=false;
  checkErr=false;
  username:any;

  constructor(
    public dialogRef: MatDialogRef<DeactivateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _generalService:GeneralService,
  ){
    let loggedUserData = JSON.parse(localStorage.getItem('mad_user') || '{}');
    this.username=loggedUserData.user_name;

    
  }
  togglePasswordType(inputElement: any) {
    if (inputElement.getAttribute('type') === 'password')
      inputElement.setAttribute('type', 'text');
    else inputElement.setAttribute('type', 'password');
  }
  close(param:any){
    this.dialogRef.close(param);
  }
  deactivate(){
    if(!this.check){
      this.checkErr=true;
      return;
    }
    const param={
      "mad_id":localStorage.getItem("mad_id"),
      "reason":this.reason
  };
    this._generalService.deactivate(param)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        if(data.status){
          if(data.status.code=="00"){
            console.log("deactivated");
            this.close('deactivated');
          }
        }
      }else{
        
      }
    }
    );
  }

}
