<div class="modalWrapper">
  <div class="newChatModal">
    <div class="newChatModalHeader">
      <div class="newChatSerchBox">
        <input 
        type="text"
        class="searchInput"
        (input)="search($event)"
        [(ngModel)]="search_text"
        placeholder="Search of people"
          >
      </div>
      <button class="newChatModalHeaderCloseBtn" (click)="onBack()">
        <svg width="20" height="20" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.1726 13.6346C12.4385 13.9005 12.8694 13.9005 13.1353 13.6346C13.4011 13.3688 13.4011 12.9379 13.1353 12.672L8.96425 8.50115L13.135 4.33056C13.4008 4.06474 13.4008 3.63377 13.135 3.36796C12.8691 3.10214 12.4382 3.10214 12.1723 3.36796L8.00162 7.53855L3.83027 3.36733C3.56444 3.10151 3.13346 3.10152 2.86763 3.36733C2.60181 3.63315 2.60181 4.06412 2.86763 4.32993L7.03898 8.50115L2.86734 12.6727C2.60151 12.9385 2.60151 13.3695 2.86734 13.6353C3.13316 13.9011 3.56415 13.9011 3.82997 13.6353L8.00162 9.46376L12.1726 13.6346Z"
            fill="white" />
        </svg>
      </button>
    </div>
    <div class="newChatModalBody">
      <div class="peoples-box">
        <div class="peoples no-scrollbar">
          <div
          class="people"
          *ngFor="let people of seachUserList"
          (click)="message(people.user_name)"
        >
          <div class="profile-pic-box">
            <img
              loading="lazy"
              [src]="getDownloadUrl(people.profile_photo_path)"
              alt="Profile Picture"
            />
          </div>
          <div class="people-about">
            <span class="people-about-name">
              {{ people.first_name }} {{ people.last_name }}
            </span>
            <span class="people-about-username"> @ {{ people.user_name }}</span>
          </div>
          <button>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.7221 13.4408L2.72174 12.9411H2.2221C1.28577 12.9411 0.5 12.1532 0.5 11.1478V2.14888C0.5 1.57012 1.12447 1.32325 1.49918 1.64955L1.98548 2.07303C2.87691 2.84928 4.0083 3.27708 5.17978 3.27708H15.5547C16.491 3.27708 17.2768 4.065 17.2768 5.07043V6.55886V7.05886H17.7768C18.7135 7.05886 19.5 7.8471 19.5 8.85221V17.8511C19.5 18.0014 19.4579 18.1294 19.389 18.2307V17.8511V8.85221C19.389 7.95776 18.6824 7.20553 17.7779 7.20553H17.2781L17.2779 7.70538L17.2768 11.1476V11.1478C17.2768 12.1532 16.491 12.9411 15.5547 12.9411H3.33424H2.83424V13.4411V14.9296C2.83424 15.824 3.54078 16.5762 4.44529 16.5762H14.8202C16.009 16.5762 17.1618 17.0101 18.0743 17.8047L18.5606 18.2282L18.8676 18.4956C18.7386 18.4913 18.6092 18.4449 18.5008 18.3504L18.0145 17.927C17.1231 17.1507 15.9917 16.7229 14.8202 16.7229H4.44529C3.50896 16.7229 2.72319 15.935 2.72319 14.9296L2.72319 14.9292L2.7221 13.4408Z" stroke="#EAEAEA"/>
              </svg>
          </button>
        </div>
        
        </div>
      </div>

    </div>
  </div>
</div>
