import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'socialCount',
  pure: true
})
export class SocialCountPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    if (value < 1000) {
      return value.toString(); // If count is less than 1000, simply return the value
    } else if (value >= 1000 && value < 1000000) {
      return (value / 1000).toFixed(1) + 'K'; // If count is in thousands
    } else if (value >= 1000000 && value < 1000000000) {
      return (value / 1000000).toFixed(1) + 'M'; // If count is in millions
    } else {
      return (value / 1000000000).toFixed(1) + 'B'; // If count is in billions
    }
  }

}
