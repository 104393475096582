import { Component, HostListener, OnInit } from '@angular/core';
import { CommunityService } from 'src/app/services/community.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.css']
})
export class CommunityComponent implements OnInit{
  current_tab="community";
  communityList=[];
  page=1;
  fetchingData = false;
  communityData:any={};
  constructor(private _generalService:GeneralService,private _communityService:CommunityService){

  }
  ngOnInit(): void {
    this.changeTab('community',true);
    this.getCommunityData();
  }
  getCommunityData(){
    this._communityService.GetCommunityData()
      .subscribe((data:any) => {
        if(data){
          console.log(data);
          this.communityData=data;
        }else{
          
        }
      }
      );
  }
  onFollowEvent(follow_type:any){
    if(follow_type=="follow"){
      this.communityData.following+=1;
    }
    if(follow_type=="unfollow"){
      this.communityData.following-=1;
    }
  }
  onScrollEvt(){
    this.changeTab(this.current_tab,true);
  }
  onExploreEvent(){
    this.changeTab('community',false);
  }
  
  changeTab(tab:any,scFlg:boolean){
    this.fetchingData = true;
    if(!scFlg){
      this.communityList=[];
      this.page=1;
    }
    console.log(this.page)
    console.log(scFlg)
    this.current_tab=tab;
    if(tab=="followers"){
      this._communityService.getFollowers(this.page)
      .subscribe((data:any) => {
        if(data){
          console.log(data);
          if(data.response){
            if(scFlg){
              this.communityList = this.communityList.concat(data.response);
              this.page += 1; 
            }else{
              this.communityList=data.response;
            }
            this.fetchingData = false;
          }
        }else{
          
        }
      }
      );
    }else if(tab=="following"){
      this._communityService.getFollowing(this.page)
      .subscribe((data:any) => {
        if(data){
          console.log(data);
          if(data.response){
            if(scFlg){
              this.communityList = this.communityList.concat(data.response);
              this.page += 1; 
            }else{
              this.communityList=data.response;
            }
            this.fetchingData = false;
          }
        }else{
          
        }
      }
      );

    }else if(tab=="community"){
      this._communityService.getCommunitySuggestions(this.page)
      .subscribe((data:any) => {
        if(data){
          console.log(data);
          if(data.response){
            if(scFlg){
              this.communityList = this.communityList.concat(data.response);
              this.page += 1; 
            }else{
              this.communityList=data.response;
            }
            this.fetchingData = false;
          }
        }else{
          
        }
      }
      );

    }
  }
}
