import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public readonly API_ENDPOINT: string = 'https://mad-api.azurewebsites.net'; 

  constructor(private http: HttpClient ) {

  }
  signUp(payload:any){
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users",payload,{ headers: headers });
  }
  addInterests(payload:any){
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/interests",payload,{ headers: headers });
  }

  getUserData(mad_id:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.get(this.API_ENDPOINT+"/users/"+mad_id,{ headers: headers });
  }
  getUserDataByUsername(username:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
    if(localStorage.getItem("token")){
      headers.set('Authorization',"Bearer " + localStorage.getItem("token"));
    }
    return this.http.get(this.API_ENDPOINT+"/users/username/"+username,{ headers: headers });
  }
  editProfile(payload:any){
    console.log(localStorage.getItem("token")); 
    let mad_id=localStorage.getItem("mad_id");
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.put(this.API_ENDPOINT+"/users/"+mad_id,payload,{ headers: headers });
  }
  passwordChange(payload:any){
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/changepassword",payload,{ headers: headers });
  }

  addPost(payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/projection",payload,{ headers: headers });
  }
  editPost(payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.put(this.API_ENDPOINT+"/users/projection/edit",payload,{ headers: headers });
  }

}
