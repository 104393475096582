import { Component, HostListener, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { WriteupService } from 'src/app/services/writeup.service';

@Component({
  selector: 'app-writeups',
  templateUrl: './writeups.component.html',
  styleUrls: ['./writeups.component.css'],
  // standalone: true,
  // imports: [MatButtonModule, MatDialogModule],
})
export class WriteupsComponent  implements OnInit{
  writeupList=[];
  fetchingData = false;
  offset=0;
  constructor(private _writeupService:WriteupService,
    private _generalService:GeneralService,) {}

  ngOnInit(): void {
    this.getWriteups();
  }
  @HostListener('scroll', ['$event'])
  scrollHandler(event:any) {
    const element =event.target;
    const scrollPercentage = (element.scrollTop + element.clientHeight) / element.scrollHeight * 100;
    
    if (scrollPercentage >= 70 && !this.fetchingData) {
      // console.log("User has scrolled to 70% of the content");
      this.getWriteups(); 
    }
  }
  

  getWriteups(){
    this.fetchingData = true;
    let param={
      "mad_id":localStorage.getItem("mad_id"),
      "range":10,
      "offset":this.offset
    }
    this._writeupService.getWriteups(param)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        if(data){
          if(data.response){
            this.writeupList=this.writeupList.concat(data.response);
            this.offset += 10; 
            console.log(this.writeupList);  
          }
          this.fetchingData = false;
        }
      }else{
        
      }
    }
    );

  }

}
