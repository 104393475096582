import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.css']
})
export class CreatePostComponent {

  constructor(public dialogRef: MatDialogRef<CreatePostComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.dialogRef.addPanelClass('create-post-modal');
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onAddProjection(): void {
    this.dialogRef.close("projection");
  }

  onAddWriteup(): void {
    this.dialogRef.close("writeup");
  }

  onAddForum(): void {
    this.dialogRef.close("forum");
  }
}
