<!-- <div class="dp_view"> -->
<div class="dp_view_header">
    <div class="dp_view_header_text_box">
        <p>Change profile image</p>
    </div>
    <button class="dp_view_header_close_btn" (click)="close('')">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.257 17.7C16.6557 18.0987 17.3022 18.0987 17.7009 17.7C18.0997 17.3013 18.0997 16.6548 17.7009 16.2561L11.4444 9.99978L17.7005 3.74389C18.0992 3.34516 18.0992 2.69871 17.7005 2.29998C17.3018 1.90126 16.6553 1.90126 16.2566 2.29998L10.0005 8.55587L3.74345 2.29904C3.34471 1.90032 2.69823 1.90032 2.2995 2.29904C1.90076 2.69777 1.90076 3.34422 2.2995 3.74295L8.55652 9.99978L2.29905 16.2571C1.90032 16.6558 1.90032 17.3022 2.29905 17.701C2.69779 18.0997 3.34426 18.0997 3.743 17.701L10.0005 11.4437L16.257 17.7Z"
                fill="white" />
        </svg>
    </button>
</div>
<div class="dp_view_content">
    <div class="dp_view_image">
        <img loading="lazy" [src]="avaurl" width="100%" *ngIf="profile_photo_type=='avatar'">
        <img loading="lazy" [src]="getDownloadUrl(userData?.profile_photo_path)" width="100%"
            *ngIf="!file && profile_photo_type!='avatar'">
        <img loading="lazy" [src]="url" width="100%" *ngIf="file.type && file.type.toLowerCase().includes('image')" />
    </div>
    <div class="dp_view_action_btns">
        <label for="upload" role="button" class="dp_view_action_btn">
            <input type="file" id="upload" style="display: none" (change)="onSelectFile($event)" />
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 12V16H18V12" stroke="#FFFBFB" stroke-linecap="round" />
                <path
                    d="M4 3.5C3.72386 3.5 3.5 3.72386 3.5 4C3.5 4.27614 3.72386 4.5 4 4.5V3.5ZM10 4H10.5V3.5H10V4ZM9.64645 14.3536C9.84171 14.5488 10.1583 14.5488 10.3536 14.3536L13.5355 11.1716C13.7308 10.9763 13.7308 10.6597 13.5355 10.4645C13.3403 10.2692 13.0237 10.2692 12.8284 10.4645L10 13.2929L7.17157 10.4645C6.97631 10.2692 6.65973 10.2692 6.46447 10.4645C6.2692 10.6597 6.2692 10.9763 6.46447 11.1716L9.64645 14.3536ZM4 4.5H10V3.5H4V4.5ZM9.5 4V14H10.5V4H9.5Z"
                    fill="#FFFBFB" />
            </svg>
            <p>Upload image</p>
        </label>
        <button class="dp_view_action_btn" (click)="save()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.73483 11.7652C3.88128 11.9116 4.11872 11.9116 4.26517 11.7652L6.65165 9.37868C6.7981 9.23223 6.7981 8.9948 6.65165 8.84835C6.5052 8.7019 6.26777 8.7019 6.12132 8.84835L4 10.9697L1.87868 8.84835C1.73223 8.7019 1.4948 8.7019 1.34835 8.84835C1.2019 8.9948 1.2019 9.23223 1.34835 9.37868L3.73483 11.7652ZM14 3.625H6V4.375H14V3.625ZM3.625 6V11.5H4.375V6H3.625ZM6 3.625C4.68832 3.625 3.625 4.68832 3.625 6H4.375C4.375 5.10254 5.10254 4.375 6 4.375V3.625ZM16.375 6C16.375 4.68832 15.3117 3.625 14 3.625V4.375C14.8975 4.375 15.625 5.10254 15.625 6H16.375Z"
                    fill="white" />
                <path
                    d="M16.2662 8.23487C16.1198 8.0884 15.8823 8.08837 15.7359 8.2348L13.3491 10.621C13.2027 10.7674 13.2026 11.0049 13.3491 11.1513C13.4955 11.2978 13.7329 11.2978 13.8794 11.1514L16.001 9.03033L18.122 11.1519C18.2685 11.2984 18.5059 11.2984 18.6524 11.152C18.7988 11.0055 18.7988 10.7681 18.6524 10.6216L16.2662 8.23487ZM3.625 14V15H4.375V14H3.625ZM6 17.375H14.0002V16.625H6V17.375ZM16.3752 15.0003L16.376 8.50004L15.626 8.49996L15.6252 15.0002L16.3752 15.0003ZM14.0002 17.375C15.3118 17.375 16.3751 16.3118 16.3752 15.0003L15.6252 15.0002C15.6251 15.8976 14.8976 16.625 14.0002 16.625V17.375ZM3.625 15C3.625 16.3117 4.68832 17.375 6 17.375V16.625C5.10254 16.625 4.375 15.8975 4.375 15H3.625Z"
                    fill="white" />
            </svg>
            <p>Save</p>
        </button>
    </div>
    <div class="dp_view_avatars_box">
        <p>or select your favorite MadScientist bots as your profile picture.</p>
        <div class="dp_view_avatars">
            <button class="dp_view_avatar">
                <img loading="lazy" src="assets/avatars/1708715493366-1.jpg" class="profileavatarsmall" alt="Profile Avatar"
                    width="44px" height="44px" (click)="changeAvatar('1708715493366-1.jpg')">
            </button>
            <button class="dp_view_avatar">
                <img loading="lazy" src="assets/avatars/1708715577720-1.jpg" class="profileavatarsmall" alt="Profile Avatar"
                    width="44px" height="44px" (click)="changeAvatar('1708715577720-1.jpg')">
            </button>
            <button class="dp_view_avatar">
                <img loading="lazy" src="assets/avatars/1708715606719-1.jpg" class="profileavatarsmall" alt="Profile Avatar"
                    width="44px" height="44px" (click)="changeAvatar('1708715606719-1.jpg')">
            </button>
            <button class="dp_view_avatar">
                <img loading="lazy" src="assets/avatars/1708715629741-1.jpg" class="profileavatarsmall" alt="Profile Avatar"
                    width="44px" height="44px" (click)="changeAvatar('1708715629741-1.jpg')">
            </button>
            <button class="dp_view_avatar">
                <img loading="lazy" src="assets/avatars/1708715652476-1.jpg" class="profileavatarsmall" alt="Profile Avatar"
                    width="44px" height="44px" (click)="changeAvatar('1708715652476-1.jpg')">
            </button>
        </div>
    </div>
</div>
<!-- </div> -->