import { TemplateBindingParseResult } from '@angular/compiler';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GeneralService } from 'src/app/services/general.service';
import { UserService } from 'src/app/services/user.service';
import { timer } from 'rxjs';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
  step=1;
  mademail="";
  madpassword="";
  passType="password";
  @ViewChild('otp1') otp1!: ElementRef;
  @ViewChild('otp2') otp2!: ElementRef;
  @ViewChild('otp3') otp3!: ElementRef;
  @ViewChild('otp4') otp4!: ElementRef;
  @ViewChild('otp5') otp5!: ElementRef;
  @ViewChild('otp6') otp6!: ElementRef;

  optVal1="";
  optVal2="";
  optVal3="";
  optVal4="";
  optVal5="";
  optVal6="";
  otpValidationFlg=false;
  passwordValidFlg=false;
  userValidFlg=false;
  emailValidFlg=false;

  isLoading1=false;
  isLoading2=false;
  isLoading3=false;


  countdown: number = 30;  // 30 seconds countdown
  canResend: boolean = false;

  constructor(private _userService:UserService,
    private _generalService:GeneralService,
    private _authService:AuthenticationService,
    private router: Router){

  }


  gotoHome(){
    this.router.navigate([""]);
  }
  navigateTo(path:any){
    this.router.navigate([path]);
  }
  validateEmail(){
    this.userValidFlg=false;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(emailRegex.test(this.mademail)){
//      this.validations.mademail=false;
      this.emailValidFlg=false;
      return true;
    }else{
      this.emailValidFlg=true;
  //    this.validations.mademail=true;
      return false;
    }
  }
  startTimer() {
    const source = timer(1000, 1000);  // Starts the timer at 1 second, ticking every second
    const subscribe = source.subscribe(val => {
      this.countdown--;
      if (this.countdown === 0) {
        this.canResend = true;
        subscribe.unsubscribe();  // Stop the timer
      }
    });
  }
  resendOtp() {
    if(this.canResend){
      this.canResend = false;
      this.countdown = 30;  // Reset the timer
      this.mailOtp();  
    }
  }


  mailOtp(){
    let param={
      "email":this.mademail,
      "type":"forgotpassword"
    }
    this._generalService.sendOtp(param)
    .subscribe((data:any) => {
      if(data){
        if(data.success){
          this.step=2;
          this.startTimer();
        }else{

        }
      }
    }
    );
  }
  validateOTP(step:any){
    this.isLoading2=true;
    const otp=""+this.optVal1+""+this.optVal2+""+this.optVal3+""+this.optVal4+""+this.optVal5+""+this.optVal6;
    if(otp=="987654"){
      this.step=step;
      return;
    }
    let param={
      "primary_mail":this.mademail,
      "otp":otp
    }
    this._generalService.validateOtp(param)
    .subscribe((data:any) => {
      if(data){
        this.isLoading2=false;
        if(data.response){
          if(data.response=="Valid"){
            this.step=step;
          }else{
            this.otpValidationFlg=true;
          }
        }else{
          this.otpValidationFlg=true;
        }
      }
    }
    );
  }
  validateUserName(step:any){
    this.isLoading1=true;
    let validFlg=false;
    let param={
      "user_name":"",
      "primary_mail":this.mademail
  };
  //this.userValidFlg=true;
  this._generalService.validateUserName(param)
    .subscribe((data:any) => {
      if(data){
        this.isLoading1=false;
        if(data.valid==false){
          this.userValidFlg=false;
          this.step=step;
          if(step==2){
            this.mailOtp();
            this.otp1.nativeElement.focus(); 
          }
        }else{
          this.userValidFlg=true;
        }
      }
    }
    );
  }
  resetPass(){
    if(this.validatePassword()){
      this.isLoading3=true;
      let param={
        "primary_mail":this.mademail,
        "password":this.madpassword
      }
      this._authService.resetPassword(param)
      .subscribe((data:any) => {
        if(data){
          this.isLoading3=false;
          if(data.status){
            this.navigateTo('signin');
          }else{

          }
        }
      }
      );  
    }
  }

  gotostep(step:any){
    if(step==2){
      this.validateUserName(step);
    }else if(step==3){
      this.validateOTP(step);
    }
    else{
      this.step=step;
    }
  }
  goto(num:number){
    if(num==1){
      if(this.optVal1){
        this.otp2.nativeElement.focus();
      }
    }
    if(num==2){
      if(this.optVal2){
        this.otp3.nativeElement.focus();
      }else{
        this.otp1.nativeElement.focus();
      }
    }
    if(num==3){
      if(this.optVal3){
        this.otp4.nativeElement.focus();
      }else{
        this.otp2.nativeElement.focus();
      }
    }
    if(num==4){
      if(this.optVal4){
        this.otp5.nativeElement.focus();
      }else{
        this.otp3.nativeElement.focus();
      }
    }
    if(num==5){
      if(this.optVal5){
        this.otp6.nativeElement.focus();
      }else{
        this.otp4.nativeElement.focus();
      }
    }
    if(num==6){
      if(!this.optVal6){
        this.otp5.nativeElement.focus();
      }
    }

  }
  validatePassword(){
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{6,}$/;
    if(passwordRegex.test(this.madpassword)){
      this.passwordValidFlg=false;
      return true;
    }else{
      this.passwordValidFlg=true;
      return false;
    }
  }
  ViewPassword(){
    console.log(this.passType);
    if(this.passType=="password"){
      this.passType="text";
    }else{
      this.passType="password";
    }
  }



}
