import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WriteupService {

  public readonly API_ENDPOINT: string = 'https://mad-api.azurewebsites.net'; 
  constructor(private http: HttpClient ) {
    
  }
  createWriteup(payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('Content-Type','application/x-www-form-urlencoded')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    let body = new URLSearchParams();
    body.set('title', payload.title);
    body.set('data', payload.data);
    body.set('created_by', payload.created_by);
    body.set('has_attachment', "");
    body.set('attachment_path', "");
    body.set('attachment_type', "");
    body.set('likes', "");
    body.set('requestType', "create");
    return this.http.post(this.API_ENDPOINT+"/users/writeups",body,{ headers: headers });  
  }
  getWriteups(payload:any){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/writeups/get",payload,{ headers: headers });  
  }
  getWriteupsById(payload:any){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/writeups/getWriteupsById",payload,{ headers: headers });  
  }
  getWriteupbyId(writeupId:number){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/writeups/getWriteUpByWriteupId/"+writeupId,{},{ headers: headers });  
  }
  deleteWriteup(writeupId:number){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.delete(this.API_ENDPOINT+"/users/writeups/delete/"+writeupId,{ headers: headers });
  }
  likeWriteup(writeupId:number){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    const formData = new FormData();
    formData.append('react_type','like');
    return this.http.post(this.API_ENDPOINT+"/users/writeups/"+writeupId+"/reaction/react",formData,{ headers: headers });
  }
  addView(writeupId:number){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/writeups/"+writeupId+"/view/add",{},{ headers: headers });
  }
  unlikeWriteup(writeupId:number){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.delete(this.API_ENDPOINT+"/users/writeups/"+writeupId+"/reaction/unreact",{ headers: headers });
  }


}
