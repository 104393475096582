<div class="report report--profile">
    <div class="report_header report_header--profile">
        <button class="profile_report_header_discard_btn" (click)="onBack()">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.53866 0.263993C1.18667 -0.0879973 0.615983 -0.0879974 0.263992 0.263993C-0.0879975 0.615983 -0.0879975 1.18667 0.263992 1.53866L4.72533 6L0.263992 10.4613C-0.0879975 10.8133 -0.0879975 11.384 0.263992 11.736C0.615983 12.088 1.18667 12.088 1.53866 11.736L6 7.27467L10.4613 11.736C10.8133 12.088 11.384 12.088 11.736 11.736C12.088 11.384 12.088 10.8133 11.736 10.4613L7.27467 6L11.736 1.53866C12.088 1.18667 12.088 0.615983 11.736 0.263993C11.384 -0.0879974 10.8133 -0.0879977 10.4613 0.263993L6 4.72533L1.53866 0.263993Z"
                    fill="white" />
            </svg>
            <p>Cancel</p>
        </button>
        <p class="profile_report_header_title">Profile report</p>
        <button class="profile_report_header_report_btn" (click)="report()">
            <p>Report</p>
        </button>
    </div>
    <div class="profile_report_content">
        <textarea name="reportText" maxlength="200" id="reportText" [(ngModel)]="report_message"
            cols="30"></textarea>
    </div>
    <div class="profile_report_count_box">
        <p class="count">{{200 - report_message.length}}</p>
    </div>
</div>