import { MatButtonModule } from '@angular/material/button';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatDialogRef,
  MatDialogModule,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { GeneralService } from 'src/app/services/general.service';
import { ForumService } from 'src/app/services/forum.service';
import { FormsModule } from '@angular/forms';
import { ReportComponent } from '../report/report.component';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-forum-view',
  templateUrl: './forum-view.component.html',
  styleUrls: ['./forum-view.component.css'],
})
export class ForumViewComponent {
  forum: any;
  answersList: any = [];
  answer = '';
  isHidden = false;
  mad_id: any;
  @ViewChild('commentF') commentField!: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ForumViewComponent>,
    private _generalService: GeneralService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _forumService: ForumService,
    public dialog: MatDialog,
    private router: Router,
    private alertService: AlertService,
  ) {
    this.forum = data;
    this.mad_id = localStorage.getItem('mad_id');
    this.getAnswers();
  }

  getHtmlString(data:string){
    const textWithLineBreaks = data.replace(/(?:\r\n|\r|\n)/g, '<br>');
    // console.log(this.convertLinksToAnchors(textWithLineBreaks))
    return this.convertLinksToAnchors(textWithLineBreaks);
  }
  private convertLinksToAnchors(text: string): string {
    const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
      const hyperlink = url.startsWith('www') ? `http://${url}` : url;
      return `<a href="${hyperlink}" target="_blank" style="color: #10776b;">${url}</a>`;
    });
  }
  onBack(): void {
    this.dialogRef.close();
  }
  focusAns(){
    this.commentField.nativeElement.focus();
  }
  getDownloadUrl(path: String) {
    if (path) {
      return (
        this._generalService.API_ENDPOINT +
        '/users/file/download?filename=' +
        path
      );
    } else {
      return '';
    }
  }
  navigateToProfile(username: any) {
    let path = '/home/profile/' + username;
    this.router.navigate([path]);
    this.onBack();
  }
  getAnswers() {
    const param = {
      range: 10,
      offset: 0,
    };
    this._forumService
      .getForumAnswers(this.forum.forum_id, param)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data) {
            if (data.success == false) {
              this.onBack();
            }
          }
          if (data.response) {
            if (data.response.list) {
              this.answersList = data.response.list;
              this.forum.total_comments = data.response.totalRecords;
            }
          }
        } else {
        }
      });
  }

  addAnswer() {
    console.log('clicked');
    let param = {
      comment_by: localStorage.getItem('mad_id'),
      comment: this.answer,
      forum_id: this.forum.forum_id,
    };
    this._forumService.addForumAnswer(param).subscribe((data: any) => {
      if (data) {
        console.log(data);
        if (data.status && data.status.code == '00') {
          console.log('answered');
          this.answer = '';
          this.answersList.unshift(data.response);
          this.forum.total_comments+=1;
        }
      } else {
      }
    });
  }
  onEdit(){
    this.dialogRef.close("edit");
  }
  save() {
    let pararm = {
      mad_id: this.mad_id,
      post_type: 'writeups',
      post_id: this.forum.forum_id,
    };
    this._generalService.save(pararm).subscribe((data: any) => {
      if (data) {
        console.log(data);
        if (data.status && data.status.code == '00') {
          console.log('saved');
          this.isHidden = false;
        }
      } else {
      }
    });
  }
  deleteWriteup() {
    this._forumService
      .deleteForum(this.forum.forum_id)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data.status && data.status.code == '00') {
            console.log('deleted');
            this.forum = {};
            this.onBack();
          }
        } else {
        }
      });
  }
  onLike() {
    this._forumService.likeForum(this.forum.forum_id).subscribe((data: any) => {
      if (data) {
        console.log(data);
        if (data.status && data.status.code == '00') {
          console.log('liked');
          this.forum.total_likes += 1;
          this.forum.is_liked = 1;
        }
      } else {
      }
    });
  }
  onUnLike() {
    // this._forumService
    //   .unlikeForum(this.forum.forum_id)
    //   .subscribe((data: any) => {
    //     if (data) {
    //       console.log(data);
    //       if (data.status && data.status.code == '00') {
    //         console.log('liked');
    //         this.forum.total_likes -= 1;
    //         this.forum.is_liked = 0;
    //       }
    //     } else {
    //     }
    //   });
  }
  report() {
    const dialogRef = this.dialog.open(ReportComponent, {
      data: { post_type: 'forums', post_id: this.forum.forum_id },
      width: 'min(100%, 33rem)',
      height: 'min(90%, 42rem)',
      backdropClass: 'bdrop',
      position: { bottom: '0px', left: '25%', right: '0px', top: '6.5%' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.isHidden = false;
      this.onBack();
    });
  }
  share() {
    let urlTxt=btoa("forum|"+this.forum.forum_id);
    if (navigator.share) {
      navigator.share({
        title: 'MadScientist | Technology Network',
        text: this.forum.title,
        url: window.location.origin+"/home/public/"+urlTxt,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    } else {
      this.fallbackShare(urlTxt);
    }
  }

  fallbackShare(urlTxt:any) {
    const url = window.location.origin+"/home/public/"+urlTxt;
    const textArea = document.createElement('textarea');
    textArea.value = url;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    //this.alertService.showAlert("success","Link copied to Clipboard");
  }
}
