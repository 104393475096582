<div class="feed">
  <div class="help">
    <div class="help_header">
      <div class="help_title_box">
        <div class="help_title_icon_box">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_7849_17733)">
              <rect
                x="3.005"
                y="3.005"
                width="17.99"
                height="17.99"
                stroke="black"
                stroke-width="0.01"
              />
              <rect
                x="4.005"
                y="2.005"
                width="15.99"
                height="19.99"
                stroke="black"
                stroke-width="0.01"
              />
              <rect
                x="2.005"
                y="4.005"
                width="19.99"
                height="15.99"
                stroke="black"
                stroke-width="0.01"
              />
              <circle
                cx="12"
                cy="12"
                r="9.995"
                stroke="black"
                stroke-width="0.01"
              />
              <circle
                cx="12"
                cy="12"
                r="4.995"
                stroke="black"
                stroke-width="0.01"
              />
              <path d="M12 0V24" stroke="black" stroke-width="0.01" />
              <path d="M24 12L0 12" stroke="black" stroke-width="0.01" />
              <path
                d="M0 0L24 24M0 24L24 0"
                stroke="black"
                stroke-width="0.01"
              />
              <path
                d="M10 17.875C5.74741 17.875 2.3 14.3492 2.3 10C2.3 5.65076 5.74741 2.125 10 2.125C11.5637 2.125 13.0185 2.60169 14.2331 3.42073L15.0251 2.6107C13.6004 1.59537 11.8682 1 10 1C5.32461 1 1.50082 4.72897 1.21691 9.4375H0.55C0.246243 9.4375 0 9.68934 0 10C0 10.3107 0.246243 10.5625 0.55 10.5625H1.21691C1.50082 15.271 5.32461 19 10 19C11.8682 19 13.6004 18.4046 15.0251 17.3893L14.2331 16.5793C13.0185 17.3983 11.5637 17.875 10 17.875Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.366 10.5625C14.0953 12.7823 12.2438 14.5 10 14.5C7.56995 14.5 5.6 12.4853 5.6 10C5.6 7.51472 7.56995 5.5 10 5.5C12.2438 5.5 14.0953 7.2177 14.366 9.4375H19.45C19.7538 9.4375 20 9.68934 20 10C20 10.3107 19.7538 10.5625 19.45 10.5625H14.366Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_7849_17733">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="help_title_text_box">
          <p>Help/Feedback</p>
        </div>
      </div>
    </div>
    <div class="help_header_intro_text_box">
      <p class="help_header_intro_text">
        Thank you for visualizing MadScientist. This page will be updated with
        additional information in the coming days. Meanwhile, you can reach out
        to us using this page to ask for help, submit feedback, report bugs, and
        request profile verifications. We'll get back to you as soon as
        possible.
      </p>
    </div>
    <div class="select_category_box">
      <div class="select_category_input_box">
        <label for="select_category_input" class="select_category_input_label">
          Select Category*
        </label>
        <select
          name="select_category_input"
          id="select_category_input"
          class="select_category_input_select"
          [(ngModel)]="category"
        >
          <option value="Feedback">Feedback</option>
          <option value="Help/Query">Help/Query</option>
          <option value="Bug Report">Bug Report</option>
          <option value="Suggestion">Suggestion</option>
          <option value="Acount Verification">Acount Verification</option>
        </select>
      </div>
    </div>
    <div class="explain_in_brief_textarea_box">
      <textarea
        placeholder="Explain in brief(Optional)"
        name="explain_in_brief_textarea"
        id="explain_in_brief_textarea"
        class="explain_in_brief_textarea"
        maxlength="2500"
        [(ngModel)]="description"
      ></textarea>
    </div>
    <div class="action_btns_box">
      <div class="add_file_btn_box" *ngIf="!file">
        <input type="file" id="upload" style="display: none" (change)="onSelectFile($event)" accept="image/*"/>
        <label for="upload" role="button" class="add_file_btn">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask id="path-1-inside-1_7172_3520" fill="white">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 0C3.79086 0 2 1.79086 2 4V20C2 22.2091 3.79086 24 6 24H18C20.2091 24 22 22.2091 22 20V12V6.5H18C16.6193 6.5 15.5 5.38071 15.5 4V0H12H6ZM16.5 0.5V4C16.5 4.82843 17.1716 5.5 18 5.5H21.5L16.5 0.5Z"
              />
            </mask>
            <path
              d="M22 6.5H23V5.5H22V6.5ZM15.5 0H16.5V-1H15.5V0ZM16.5 0.5L17.2071 -0.207107L15.5 -1.91421V0.5H16.5ZM21.5 5.5V6.5H23.9142L22.2071 4.79289L21.5 5.5ZM3 4C3 2.34315 4.34315 1 6 1V-1C3.23858 -1 1 1.23858 1 4H3ZM3 20V4H1V20H3ZM6 23C4.34315 23 3 21.6569 3 20H1C1 22.7614 3.23858 25 6 25V23ZM18 23H6V25H18V23ZM21 20C21 21.6569 19.6569 23 18 23V25C20.7614 25 23 22.7614 23 20H21ZM21 12V20H23V12H21ZM21 6.5V12H23V6.5H21ZM22 5.5H18V7.5H22V5.5ZM18 5.5C17.1716 5.5 16.5 4.82843 16.5 4H14.5C14.5 5.933 16.067 7.5 18 7.5V5.5ZM16.5 4V0H14.5V4H16.5ZM12 1H15.5V-1H12V1ZM6 1H12V-1H6V1ZM15.5 0.5V4H17.5V0.5H15.5ZM15.5 4C15.5 5.38071 16.6193 6.5 18 6.5V4.5C17.7239 4.5 17.5 4.27614 17.5 4H15.5ZM18 6.5H21.5V4.5H18V6.5ZM15.7929 1.20711L20.7929 6.20711L22.2071 4.79289L17.2071 -0.207107L15.7929 1.20711Z"
              fill="white"
              mask="url(#path-1-inside-1_7172_3520)"
            />
            <rect
              x="10.3"
              y="0.3"
              width="11.4"
              height="11.4"
              rx="5.7"
              fill="#191919"
              stroke="white"
              stroke-width="0.6"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.9992 6.30078C19.1649 6.30078 19.2992 6.16647 19.2992 6.00078C19.2992 5.8351 19.1649 5.70078 18.9992 5.70078H16.2998V3.00078C16.2998 2.8351 16.1655 2.70078 15.9998 2.70078C15.8341 2.70078 15.6998 2.8351 15.6998 3.00078V5.70078H12.9992C12.8335 5.70078 12.6992 5.8351 12.6992 6.00078C12.6992 6.16647 12.8335 6.30078 12.9992 6.30078H15.6998V9.00078C15.6998 9.16647 15.8341 9.30078 15.9998 9.30078C16.1655 9.30078 16.2998 9.16647 16.2998 9.00078V6.30078H18.9992Z"
              fill="white"
            />
          </svg>
          <p>Add file</p>
        </label>

        <div class="error_msg_box">
          <p class="error_msg">{{ errorMsg }}</p>
        </div>

        <!-- <button class="add_file_btn">
          <div class="add_file_btn_icon">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.609375 10C0.609375 15.1863 4.8137 19.3906 10 19.3906C15.1863 19.3906 19.3906 15.1863 19.3906 10C19.3906 4.8137 15.1863 0.609375 10 0.609375C4.8137 0.609375 0.609375 4.8137 0.609375 10ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM9.69531 10.3047H5V9.69531H9.69531V4.998H10.3047V9.69531H15V10.3047H10.3047V14.998H9.69531V10.3047Z"
                fill="#BABABA"
              />
            </svg>
          </div>
          <p class="add_file_btn_text">Add file</p>
        </button> -->
      </div>
      <div class="add_file_btn_box" *ngIf="file">
        <div class="upload_file_box">
          <div class="upload_file">
            <div class="upload_file_icon_box">
              <div class="upload_file_icon">
                <img
                  [src]="url"
                  width="32"
                  *ngIf="file.type && file.type.toLowerCase().includes('image')"
                />
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  *ngIf="
                    file.type && !file.type.toLowerCase().includes('image')
                  "
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M25.5 9.5L24.5 8.5L21.5 5.5L20.5 4.5V5.91421V8C20.5 8.82843 21.1716 9.5 22 9.5H24.0858H25.5ZM21.5 6.91421L23.0858 8.5H22C21.7239 8.5 21.5 8.27614 21.5 8V6.91421ZM7 8C7 6.34315 8.34315 5 10 5H16H18.5V8C18.5 9.933 20.067 11.5 22 11.5H25V16V24C25 25.6569 23.6569 27 22 27H10C8.34315 27 7 25.6569 7 24V8ZM16 4H18.5H19.5V4.5V5V8C19.5 9.38071 20.6193 10.5 22 10.5H25H25.5H26V11.5V16V24C26 26.2091 24.2091 28 22 28H10C7.79086 28 6 26.2091 6 24V8C6 5.79086 7.79086 4 10 4H16ZM15.8139 19.0417V16.9007H15.4083V19.0417H15.8139ZM15.4461 16.0707C15.3989 16.1179 15.3753 16.1745 15.3753 16.2405C15.3753 16.3097 15.3973 16.3678 15.4413 16.415C15.4885 16.4621 15.5467 16.4857 15.6158 16.4857C15.685 16.4857 15.7432 16.4621 15.7903 16.415C15.8375 16.3678 15.861 16.3097 15.861 16.2405C15.861 16.1745 15.8359 16.1179 15.7856 16.0707C15.7384 16.0236 15.6818 16 15.6158 16C15.5498 16 15.4932 16.0236 15.4461 16.0707ZM13 16.0566V19.0417H13.4386V17.6788H14.5987V17.3157H13.4386V16.4197H14.8203V16.0566H13ZM16.965 16V19.0417H16.5594V16.2169L16.965 16ZM18.0128 18.108H19.5218V17.9665C19.5218 17.6647 19.4511 17.4147 19.3096 17.2167C19.1336 16.9683 18.8805 16.8441 18.5504 16.8441C18.28 16.8441 18.0521 16.9447 17.8666 17.1459C17.6622 17.3692 17.5601 17.6474 17.5601 17.9806C17.5601 18.3076 17.6544 18.5717 17.843 18.7729C18.0474 18.9898 18.3224 19.0983 18.6683 19.0983C18.9701 19.0983 19.231 19.0102 19.4511 18.8342L19.3285 18.6031C19.1273 18.7226 18.9292 18.7823 18.7343 18.7823C18.5331 18.7823 18.368 18.7226 18.2391 18.6031C18.1102 18.4805 18.0348 18.3155 18.0128 18.108ZM19.1068 17.792H17.9986C18.0143 17.5971 18.0725 17.4415 18.1731 17.3251C18.2769 17.2088 18.4073 17.1507 18.5645 17.1507C18.728 17.1507 18.8569 17.2072 18.9512 17.3204C19.0487 17.4305 19.1005 17.5876 19.1068 17.792Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div class="upload_file_icon_text">
                <p class="upload_file_icon_text_name">{{ file?.name }}</p>
                <p class="upload_file_icon_size">
                  {{ file?.size | displaysize }}
                </p>
              </div>
            </div>
            <button class="upload_file_close_btn" (click)="cancelUpload()">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M14 6L6 14M6 6L14 14" stroke="white" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="submit_btn_box">
        <button class="submit_btn" (click)="save()">
          <p class="submit_btn_text">Submit</p>
        </button>
      </div>
    </div>
  </div>
</div>
