import { Component, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ViewProfilePhotoComponent } from 'src/app/popups/view-profile-photo/view-profile-photo.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent {

  current_view="edit_profile";
  userData:any={};
  loggedUserData:any={};
  public mad_id: string="";
  logged_mad_id:string="";


  constructor(private _userService:UserService,
    private router: Router,
    private elRef: ElementRef,
    public dialog: MatDialog
    ){

  }
  ngOnInit(): void {
    if(localStorage.getItem("mad_user")){
      this.loggedUserData=JSON.parse(localStorage.getItem('mad_user') || '{}');
    }
    this.getuserData();
    // const parentElement = this.elRef.nativeElement.parentElement;
    // if (parentElement) {
    //   parentElement.removeAttribute('style');
    // }
  }

  // ngOnDestroy(): void {
  //   const parentElement = this.elRef.nativeElement.parentElement;
  //   if (parentElement) {
  //     parentElement.style.overflowY = "scroll";
  //   }
  // }
  

  changeTab(tab:any){
    this.current_view=tab;
  }
  getuserData(){
    let mad_id=this.loggedUserData.mad_id;
    this._userService.getUserData(mad_id)
    .subscribe((data:any) => {
      if(data){
        // console.log(data);
        this.userData=data;
      }else{
        
      }
    }
    );
  }

}
