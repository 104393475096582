<div class="feed">
  <div class="community-navbar">
    
    <div class="community-navlink-box">
      <a (click)="changeTab('community', false)" [ngClass]="current_tab == 'community' ? 'community-navlink community-navlink--active' : 'community-navlink'">
        <p>Community</p>
      </a>
      <a (click)="changeTab('followers', false)" [ngClass]="current_tab == 'followers' ? 'community-navlink community-navlink--active' : 'community-navlink'">
        <p>Followers <span class="follow-count">{{ communityData?.followers }}</span></p>
      </a>
      <a (click)="changeTab('following', false)" [ngClass]="current_tab == 'following' ? 'community-navlink community-navlink--active' : 'community-navlink'">
        <p>Following <span class="follow-count">{{ communityData?.following }}</span></p>
      </a>
    </div>
    <div class="helper-text">
      <p>{{ helperText }}</p>
    </div>
  </div>
  <div class="helper-text-mobile">
    <p>{{ helperText }}</p>
  </div>
  <app-community-box
    [fetchingData]="fetchingData"
    [communityList]="communityList"
    [tab]="current_tab"
    (scrollEvent)="onScrollEvt()"
    (exploreEvent)="onExploreEvent()"
    (followEvent)="onFollowEvent($event)"
  ></app-community-box>
</div>
