import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AddForumComponent } from 'src/app/popups/add-forum/add-forum.component';
import { AddProjectionComponent } from 'src/app/popups/add-projection/add-projection.component';
import { AddWriteupComponent } from 'src/app/popups/add-writeup/add-writeup.component';
import { ProfileReportComponent } from 'src/app/popups/profile-report/profile-report.component';
import { ViewProfilePhotoComponent } from 'src/app/popups/view-profile-photo/view-profile-photo.component';
import { AlertService } from 'src/app/services/alert.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CommunityService } from 'src/app/services/community.service';
import { ForumService } from 'src/app/services/forum.service';
import { GeneralService } from 'src/app/services/general.service';
import { ProjectionService } from 'src/app/services/projection.service';
import { UserService } from 'src/app/services/user.service';
import { WriteupService } from 'src/app/services/writeup.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  current_view = "projection";
  userData: any = {};
  loggedUserData: any = {};
  public mad_id: string="";
  public username: string="";
  logged_mad_id:string="";
  feed: any = [];
  isLoading=true;

  offset=0;
  fetchingData = false;


  constructor(private _userService: UserService,
    private router: Router,
    private _projectionService: ProjectionService,
    private _generalService: GeneralService,
    private _forumService: ForumService,
    private _writeupService: WriteupService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private elRef: ElementRef,
    private _communityService:CommunityService,
    private alertService: AlertService,
  ) {

  }

  ngOnInit(): void {
    const username = this.route.snapshot.paramMap.get('username');
    if(username){
      this.username=username;
    }
    if (localStorage.getItem("mad_user")) {
      this.loggedUserData = JSON.parse(localStorage.getItem('mad_user') || '{}');
      this.logged_mad_id=this.loggedUserData.mad_id;
    }
    this.getuserData();
    const parentElement = this.elRef.nativeElement.parentElement;
    if (parentElement) {
      // parentElement.style.display = 'flex';
      parentElement.style.marginBottom = '1rem';
    }
  }
  ngOnDestroy(): void {
    const parentElement = this.elRef.nativeElement.parentElement;
    if (parentElement) {
      // parentElement.style.display = 'block';
      parentElement.style.marginBottom = '0rem';
    }
  }

  @HostListener('scroll', ['$event'])
  scrollHandler(event:any) {
    const element =event.target;
    const scrollPercentage = (element.scrollTop + element.clientHeight) / element.scrollHeight * 100;
    if(this.logged_mad_id){
      if (scrollPercentage >= 70 && !this.fetchingData) {
        // console.log("User has scrolled to 70% of the content");
        if (this.current_view == "projection") {
          this.fetchProjectionsByUser();
        } else if (this.current_view == "writeup") {
          this.fetchWriteupsByUser();
        } else if (this.current_view == "forum") {
          this.fetchForumsByUser();
        } else if (this.current_view == "saved_projection") {
          this.fetchSavedProjections();
        } else if (this.current_view == "saved_writeup") {
          this.fetchSaveWriteups();
        } else if (this.current_view == "saved_forum") {
          this.fetchSavedForums();
        }
      }  
    }
  }
  getLink(channel:any){
    if(channel=='github'){
      return "https://github.com/"+this.userData.github;
    }else if(channel=='google_scholar'){
      return this.userData.google_scholar;
    }else if(channel=='medium'){
      return "https://medium.com/"+this.userData.medium;
    }else if(channel=='youtube'){
      return "https://www.youtube.com/"+this.userData.youtube;
    }else if(channel=='website'){
      return this.userData.website;
    }else if(channel=='hugging_face'){
      return "https://huggingface.co/"+this.userData.hugging_face;
    }else if(channel=='kaggle'){
      return "https://www.kaggle.com/"+this.userData.kaggle;
    }
  }
  getDownloadUrl(path: String) {
    if (path) {
      return this._generalService.API_ENDPOINT + "/users/file/download?filename=" + path;
    } else {
      return "";
    }
  }
  openProfile() {
    if(this.mad_id==this.logged_mad_id){
      const dialogRef = this.dialog.open(ViewProfilePhotoComponent,{
        data: this.userData,
        width: '40%',
        height:'73%',
        backdropClass: "bdrop",
        position:{'bottom': '0px',
        'left': '35%',
        'right': '0px',
        'top': '6.5%'}
      }
      );
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
  }

  changeTab(tab: any) {
    console.log(tab);
    this.current_view = tab;
    this.offset=0;
    if (tab == "projection") {
      this.feed=[];
      this.fetchProjectionsByUser();
    } else if (tab == "writeup") {
      this.feed=[];
      this.fetchWriteupsByUser();
    } else if (tab == "forum") {
      this.feed=[];
      this.fetchForumsByUser();
    } else if (tab == "saved_projection") {
      this.feed=[];
      this.fetchSavedProjections();
    } else if (tab == "saved_writeup") {
      this.feed=[];
      this.fetchSaveWriteups();
    } else if (tab == "saved_forum") {
      this.feed=[];
      this.fetchSavedForums();
    }
  }
  navigateTo(path: any) {
    this.router.navigate(["home", path]);
  }
  gotoHome(){
    this.router.navigate(["home"]);
  }
  getuserData() {
    this._userService.getUserDataByUsername(this.username)
      .subscribe((data: any) => {
        if (data) {
          // console.log(data);
          this.userData = data;
          this.mad_id=data.mad_id;
          if(this.logged_mad_id){
            this.fetchProjectionsByUser();
          }
        } else {

        }
      }
      );
  }
  share() {
    if (navigator.share) {
      navigator.share({
        title: 'MadScientist | Technology Network',
        text: "Check out this MadScientist profile to collaborate and explore the future of Technology! 🚀🔬",
        url: window.location.origin+"/home/profile/"+this.username,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    } else {
      this.fallbackShare(this.username);
    }
  }

  fallbackShare(urlTxt:any) {
    const url = window.location.origin+"/home/profile/"+urlTxt;
    const textArea = document.createElement('textarea');
    textArea.value = url;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    //this.alertService.showAlert("success","Link copied to Clipboard");
  }
  fetchSavedProjections() {
    this.fetchingData = true;
    const param = {
      "range": 10,
      "offset": this.offset
    }
    this._projectionService.fetchSavedProjections(param)
      .subscribe((data: any) => {
        if (data) {
          if (data.response) {
            if (data.response) {
              if (data.response.list) {
                this.feed = this.feed.concat(data.response.list);
                this.offset += 10; 
              }
              this.fetchingData = false;
            }
          }
        } else {

        }
      }
      );
  }
  fetchSaveWriteups() {
    this.fetchingData = true;
    const param = {
      "range": 10,
      "offset": this.offset
    }
    this._projectionService.fetchSavedWriteups(param)
      .subscribe((data: any) => {
        if (data) {
          if (data.response) {
            if (data.response) {
              if (data.response.list) {
                this.feed = this.feed.concat(data.response.list);
                this.offset += 10;     
              }
              this.fetchingData = false;
            }
          }
        } else {

        }
      }
      );

  }
  fetchSavedForums() {
    this.fetchingData = true;
    const param = {
      "range": 10,
      "offset": this.offset
    }
    this._projectionService.fetchSavedForums(param)
      .subscribe((data: any) => {
        if (data) {
          if (data.response) {
            if (data.response) {
              // this.feed = data.response;
              if (data.response.list) {
                this.feed = this.feed.concat(data.response.list);
                this.offset += 10;     
              }
              this.fetchingData = false;
            }
          }
        } else {

        }
      }
      );

  }

  fetchProjectionsByUser() {
    this.fetchingData = true;
    const param = {
      "mad_id": this.mad_id,
      "range": 10,
      "offset": this.offset
    }
    this._projectionService.fetchProjectionsByUser(param)
      .subscribe((data: any) => {
        if (data) {
          if (data.response) {
            if (data.response.list) {
              this.feed = this.feed.concat(data.response.list);
              this.offset += 10;   
            }
            this.fetchingData = false;
          }
        } else {

        }
      }
      );
  }
  fetchForumsByUser() {
    this.fetchingData = true;
    let param = {
      "mad_id": this.mad_id,
      "range": 10,
      "offset": this.offset
    }
    this._forumService.getForumsByUser(param)
      .subscribe((data: any) => {
        if (data) {
          if (data.response) {
            this.feed = this.feed.concat(data.response);
            this.offset += 10; 

            // if (data.response.list) {
            //   this.feed = data.response.list;
            // }
            this.fetchingData = false;
          }
        } else {

        }
      }
      );
  }
  fetchWriteupsByUser() {
    this.fetchingData = true;
    let param = {
      "mad_id": this.mad_id,
      "range": 10,
      "offset": this.offset
    }
    this._writeupService.getWriteupsById(param)
      .subscribe((data: any) => {
        if (data) {
          if (data.response) {
            this.feed = this.feed.concat(data.response);
            this.offset += 10; 

            // if(data.response.list){
            //   this.feed=data.response.list;
            // }
            this.fetchingData = false;
          }
        } else {

        }
      }
      );
  }
  report(){
    const dialogRef = this.dialog.open(ProfileReportComponent,{
      data: this.userData,
      width: '55%',
      height:'45%',
      backdropClass: "",
      position:{'bottom': '0px',
      'left': '30%',
      'right': '0px',
      'top': '15%'}
    }
    );

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  message(){
    let path = "/home"; // Navigate to a different route
    this.router.navigateByUrl(path, { skipLocationChange: true }).then(() => {
      // Navigate back to the profile route
      path = "/home/messages/" + this.username;
      this.router.navigate([path]);
    });
  }
  follow(){
    let projData = {
      following_id: this.userData.mad_id
    }
    this._communityService.followUser(projData)
      .subscribe((data:any) => {
        if(data){
          console.log(data);
          if(data.status){
            if(data.status.code=="00"){
              console.log("followed");
              this.userData.is_following=1;
            }
          }
        }else{
          
        }
      }
      );
  }
  unfollow(){
    let projData = {
      follower_id: this.userData.mad_id
    }
    this._communityService.unfollowUser(projData)
      .subscribe((data:any) => {
        if(data){
          console.log(data);
          if(data.status){
            if(data.status.code=="00"){
              console.log("unfollowed");
              this.userData.is_following=0;
            }
          }
        }else{
          
        }
      }
      );
  }
  addProjection(){
    const dialogRefIn = this.dialog.open(AddProjectionComponent, {
      data: {
        type: 'new',
        projection:""
      },
      width: '55%',
      height: '70%',
      backdropClass: "bdrop",
      position: {
        'bottom': '0px',
        'left': '22.5%',
        'right': '0px',
        'top': '6.5%'
      }
    }
    );
  }
  addWriteup(){
    const dialogRefIn = this.dialog.open(AddWriteupComponent, {
      data: {
        type: 'new',
        writeup:""
      },
      width: '80%',
      height: '85%',
      backdropClass: "bdrop",
      position: {
        'bottom': '0px',
        'left': '10%',
        'right': '0px',
        'top': '3.5%'
      }
    }
    );
  }
  addForum(){
    const dialogRefIn = this.dialog.open(AddForumComponent, {
      data: {
        type: 'new',
        forum:""
      },
      width: '60%',
      height: '60%',
      backdropClass: "bdrop",
      position: {
        'bottom': '0px',
        'left': '20%',
        'right': '0px',
        'top': '10%'
      }
    }
    );
  }

}
