import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-profile-report',
  templateUrl: './profile-report.component.html',
  styleUrls: ['./profile-report.component.css']
})
export class ProfileReportComponent {
  userData:any;
  report_message="";
  constructor(public dialogRef: MatDialogRef<ProfileReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _generalService:GeneralService,
    ) {
    this.userData=data;
  }
  ngOnInit() {
    this.dialogRef.addPanelClass('report-other-profile-modal');
  }
  report(){
    let pararm=
  {
    "reported_by": localStorage.getItem("mad_id"),
    "report_on": this.userData.mad_id,
    "report_message": this.report_message
};
    this._generalService.profileReport(pararm)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        if(data.status && data.status.code=="00"){
          console.log("reported");
          this.onBack();
        }
      }else{
        
      }
    }
    );

  }
  onBack(){
    this.dialogRef.close();
  }

}
