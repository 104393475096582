import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeneralService } from 'src/app/services/general.service';
import { ProjectionViewComponent } from '../projection-view/projection-view.component';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent {
  post_type="";
  post_id="";
  reportFlgList=[false,false,false,false,false,false,false,false,false,false,false];
  reportList=[
    "Misinformation",
    "IPR Infringement or Plagiarism",
    "Adult Content (Nudity)",
    "Spam or Bots",
    "Irrelevant on MadScientist",
    "Hate Speech or Bullying",
    "Sexual Abuse or Harassment (Children, Adult & Animal)",
    "Bad Image",
    "Illegal Fraud or Money Scams",
    "Spreading Violence or Terrorism",
    "Others"
  ];

  constructor(public dialogRef: MatDialogRef<ReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _generalService:GeneralService,
    ) {
    this.post_type=data.post_type;
    this.post_id=data.post_id;
  }
  onBack(): void {
    this.dialogRef.close();
  }
  report(){
    let reportText: string = '';
    for (let i = 0; i < this.reportFlgList.length; i++) {
        if (this.reportFlgList[i]) {
          reportText += this.reportList[i] + ', ';
        }
    }
    reportText = reportText.slice(0, -2);
    let pararm={
      "reported_by":localStorage.getItem("mad_id"),
      "values":reportText,
      "post_type":this.post_type,
      "post_id":this.post_id
  };
    this._generalService.report(pararm)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        if(data.status && data.status.code=="00"){
          console.log("reported");
          this.onBack();
        }
      }else{
        
      }
    }
    );  

  }

}
