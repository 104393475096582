import { MatButtonModule } from '@angular/material/button';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  ViewChild,
  Renderer2,
  QueryList,
  ViewChildren
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatDialogRef,
  MatDialogModule,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { ProjectionService } from 'src/app/services/projection.service';
import { FormsModule } from '@angular/forms';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-feed-actions',
  templateUrl: './feed-actions.component.html',
  styleUrls: ['./feed-actions.component.css'],
  // standalone: true,
  // imports: [MatButtonModule, MatDialogModule,CommonModule,FormsModule],
})
export class FeedActionsComponent implements AfterViewInit {
  @ViewChild('commentF')
  commentField!: ElementRef;
  view = 'likes';
  list: any = [];
  likesCount = 0;
  commentsCount = 0;
  commentText = '';
  projectionId = '';
  commentType = 'comment';
  replyCommentId = 0;
  replyCommentCount = -1;
  replies: any = [];
  repliesCommentId = 0;
  mad_id: any;
  showControls: any = {};
  showControlCommentId = 0;
  editCommentId = 0;
  editComment: any;
  textareaRows: number = 1;
  maxTextareaRows: number = 4;

  @ViewChildren('commentBodyContent') commentBodyContents!: QueryList<ElementRef>;
  showMoreBtns: boolean[] = [];

  constructor(
    private _generalService: GeneralService,
    private _projectionService: ProjectionService,
    public dialogRef: MatDialogRef<FeedActionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private renderer: Renderer2
  ) {
    this.mad_id = localStorage.getItem('mad_id');
    this.view = data.view;
    this.likesCount = data.likesCount;
    this.commentsCount = data.commentsCount;
    this.projectionId = data.projectionId;
    if (data.view == 'likes') {
      this.fetchReactions(data.projectionId);
    } else if (data.view == 'comments') {
      this.fetchComments(data.projectionId);
    }
  }
  getHtmlString(data:string){
    const textWithLineBreaks = data.replace(/(?:\r\n|\r|\n)/g, '<br>');
    // console.log(this.convertLinksToAnchors(textWithLineBreaks))
    return this.convertLinksToAnchors(textWithLineBreaks);
  }
  private convertLinksToAnchors(text: string): string {
    const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
      const hyperlink = url.startsWith('www') ? `http://${url}` : url;
      return `<a href="${hyperlink}" target="_blank" style="color: #10776b;">${url}</a>`;
    });
  }
  onEditComment(comment: any) {
    this.editComment = comment;
    this.editCommentId = comment.comment_id;
    this.commentText = comment.comment;
  }
  editCommentButton() {
    console.log(this.commentText);
    let param = {
      projectionId: this.editComment.projection_id,
      commentId: this.editComment.comment_id,
      comment: this.commentText,
      comment_by: localStorage.getItem('mad_id'),
    };
    this._projectionService
      .editCommentProjection(param)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data.status && data.status.code == '00') {
            this.commentText = '';
            this.editComment = null;
            this.editCommentId = 0;
            this.showControlCommentId = 0;
            console.log('edited');
            this.textareaRows = 1;
          }
        } else {
        }
      });
  }
  onDeleteComment(comment: any, ind: number, type: string) {
    let param = {
      projectionId: comment.projection_id,
      commentId: comment.comment_id,
    };
    this._projectionService.deleteComment(param).subscribe((data: any) => {
      if (data) {
        console.log(data);
        if (data.status && data.status.code == '00') {
          console.log('deleted');
          this.showControlCommentId = 0;
          if (type == 'comment') {
            this.list.splice(ind, 1);
            this.commentsCount-=1;
          }
          if (type == 'reply') {
            this.replies.splice(ind, 1);
          }
        }
      } else {
      }
    });
  }

  onReplyButton(commentId: number) {
    this.replyCommentId = commentId;
    this.commentField.nativeElement.focus();
  }
  closeReplyButton() {
    this.replyCommentId = 0;
  }
  showCommentControls(comment: any) {
    console.log(comment);
    console.log(this.showControlCommentId);
    if (this.showControlCommentId) {
      if (this.showControlCommentId == comment.comment_id) {
        this.showControlCommentId = 0;
        this.editComment = null;
        this.editCommentId = 0;
      } else {
        this.showControlCommentId = comment.comment_id;
        this.showControls = comment;
      }
    } else {
      this.showControlCommentId = comment.comment_id;
      this.showControls = comment;
    }
  }
  adjustTextareaRows(event: Event): void {
    const textarea: HTMLTextAreaElement = event.target as HTMLTextAreaElement;

    const lastCharacter: string = textarea.value.charAt(
      textarea.value.length - 1
    );
    const lineHeight: number = parseFloat(
      window.getComputedStyle(textarea).lineHeight
    );
    const paddingTop: number = parseFloat(
      window.getComputedStyle(textarea).paddingTop
    );
    const paddingBottom: number = parseFloat(
      window.getComputedStyle(textarea).paddingBottom
    );
    const paddingLeft: number = parseFloat(
      window.getComputedStyle(textarea).paddingLeft
    );
    const paddingRight: number = parseFloat(
      window.getComputedStyle(textarea).paddingRight
    );

    const tempDiv = this.renderer.createElement('div');
    this.renderer.setStyle(tempDiv, 'position', 'absolute');
    this.renderer.setStyle(tempDiv, 'visibility', 'hidden');
    this.renderer.setStyle(tempDiv, 'paddingTop', paddingTop);
    this.renderer.setStyle(tempDiv, 'paddingBottom', paddingBottom);
    this.renderer.setStyle(tempDiv, 'paddingLeft', paddingLeft);
    this.renderer.setStyle(tempDiv, 'paddingRight', paddingRight);
    this.renderer.setStyle(tempDiv, 'width', `${textarea.clientWidth}px`);
    this.renderer.appendChild(
      tempDiv,
      this.renderer.createText(textarea.value)
    );
    document.body.appendChild(tempDiv);
    const tempDivHeight = tempDiv.clientHeight;

    const contentHeight: number = tempDivHeight;

    let calculatedRows: number = Math.ceil(contentHeight / lineHeight);

    document.body.removeChild(tempDiv);

    this.textareaRows =
      Math.min(calculatedRows - 1, this.maxTextareaRows) < 0
        ? 1
        : Math.min(calculatedRows - 1, this.maxTextareaRows);
  }
  showMoreBtnFn(): void {
    if (this.commentBodyContents) {
      this.commentBodyContents.forEach((commentBodyContent, index) => {
        const element = commentBodyContent.nativeElement;
        const contentHeight: number = element.scrollHeight;
        const containerHeight: number = element.clientHeight;
        
        this.showMoreBtns[index] = contentHeight > containerHeight;
      });
    }
  }

  ngAfterViewInit() {
    this.commentField.nativeElement.focus();
  }
  comment(){
    this.commentField.nativeElement.focus();
  }
  onBack(): void {
    this.dialogRef.close();
  }
  getReplies(commentId: number) {
    this.repliesCommentId = commentId;
    const param = {
      comment_id: commentId,
      range: 10,
      offset: 0,
    };
    this._projectionService
      .fetchCommentReplies(param)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data.response) {
            if (data.response.list) {
              this.replies = data.response.list;
              if (data.response.hasMorePages) {
                this.replyCommentCount =
                  data.response.totalRecords -
                  data.response.offset +
                  data.response.range;
              } else {
                this.replyCommentCount = 0;
              }
            }
          }
        } else {
        }
      });
  }
  fetchReactions(projection_id: number) {
    const param = {
      offset: 0,
      range: 10,
    };
    this._projectionService
      .fetchReactions(projection_id, param)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data.response) {
            if (data.response.list) {
              this.list = data.response.list;
            }
          }
        } else {
        }
      });
  }
  fetchComments(projection_id: number) {
    const param = {
      offset: 0,
      range: 10,
    };
    this._projectionService
      .fetchComments(projection_id, param)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data.response) {
            if (data.response.list) {
              this.list = data.response.list;
              setTimeout(()=> this.showMoreBtnFn(), 0)
            }
          }
        } else {
        }
      });
  }
  addComment() {
    console.log(this.commentText);
    if(this.commentText==""){
      return;
    }
    let param = {
      projectionId: this.projectionId,
      comment: this.commentText,
      comment_by: localStorage.getItem('mad_id'),
    };
    this._projectionService.commentProjection(param).subscribe((data: any) => {
      if (data) {
        console.log(data);
        if (data.status && data.status.code == '00') {
          this.commentText = '';
          console.log('liked');
          this.textareaRows = 1;
          let commObj=data.response;
          this.list.unshift(commObj);
          this.commentsCount+=1;

        }
      } else {
      }
    });
  }
  addCommentReply() {
    console.log(this.commentText);
    let param = {
      projectionId: this.projectionId,
      commentId: this.replyCommentId,
      comment: this.commentText,
      comment_by: localStorage.getItem('mad_id'),
    };
    this._projectionService
      .commentReplyProjection(param)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data.status && data.status.code == '00') {
            console.log('liked no');
            this.textareaRows = 1;
            this.replyCommentCount=-1;
            console.log(this.replyCommentId);
            console.log(this.list);
            const objectIndex = this.list.findIndex((obj: { comment_id: number; }) => obj.comment_id == this.replyCommentId);
            console.log(objectIndex);
            if (objectIndex !== -1) {
                this.list[objectIndex]["total_replies"] = this.list[objectIndex]["total_replies"]+1;
            }
            this.commentText = '';
            this.replyCommentId = 0;
          }
        } else {
        }
      });
  }
  getDownloadUrl(path: String) {
    if (path) {
      return (
        this._generalService.API_ENDPOINT +
        '/users/file/download?filename=' +
        path
      );
    } else {
      return '';
    }
  }
}
