import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';
import { GeneralService } from 'src/app/services/general.service';
import { UserService } from 'src/app/services/user.service';
import { finalize } from 'rxjs/operators';
import { NewChatComponent } from 'src/app/popups/new-chat/new-chat.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class MessagesComponent implements OnInit, AfterViewChecked {
  @ViewChild('scrollContainer') private scrollContainer!: ElementRef;
  screen = "list";
  messageText = "";
  messagesList: any = []
  chatList: any = [];
  logged_mad_id: any;
  current_chat: any;
  username: any;
  userData: any;
  loading = true;
  shouldScroll: any;
  current_chat_user : String = "";
  msgLoad=false;
  constructor(    
    public dialog: MatDialog,
    private _userService: UserService,
    private router: Router,
    private _generalService: GeneralService,
    private _chatService: ChatService,
    private route: ActivatedRoute,

  ) {
  }
  ngOnInit(): void {
    const username = this.route.snapshot.paramMap.get('username');
    this.logged_mad_id = localStorage.getItem('mad_id');
    if (username) {
      console.log("username");
      console.log(username);
      this.username = username;
      this.getuserData();
    } else {
      this.fetchChats();
    }
  }
  getuserData() {
    this._userService.getUserDataByUsername(this.username)
      .subscribe((data: any) => {
        if (data) {
          this.userData = data;
          let cchat = {
            "mad_id": this.userData.mad_id,
            "user_name": this.userData.user_name,
            "first_name": this.userData.first_name,
            "last_name": this.userData.last_name,
            "profile_photo_path": this.userData.profile_photo_path,
            "tagline": this.userData.tagline,
            "last_message_time": "",
            "message_content": ""
          }
          this.current_chat = cchat;
          this.screen = "single";
          this.messageText = "";
          this.messagesList = [];
          this.fetchChatMessages(cchat.mad_id);
          this.markAsRead(cchat.mad_id);
        } else {

        }
      }
      );
  }

  // ngAfterViewChecked() {
  //   this.scrollToBottom();
  // }
  ngAfterViewChecked() {
    if (this.shouldScroll) {
      this.scrollToBottom();
      this.shouldScroll = false;
    }
  }
  getDownloadUrl(path: String) {
    if (path) {
      return this._generalService.API_ENDPOINT + "/users/file/download?filename=" + path;
    } else {
      return "";
    }
  }
  getClass(mad_id: any) {
    if (mad_id == this.logged_mad_id) {
      return "message message-self";
    } else {
      return "message";
    }

  }
  private scrollToBottom(): void {
    try {
      this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
    } catch (err) {
      // console.error(err);
    }
  }
  getHtmlString(data: string) {
    const textWithLineBreaks = data.replace(/(?:\r\n|\r|\n)/g, ' <br>');
    // console.log(this.convertLinksToAnchors(textWithLineBreaks))
    return this.convertLinksToAnchors(textWithLineBreaks);
  }
  private convertLinksToAnchors(text: string): string {
    const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
      const hyperlink = url.startsWith('www') ? `http://${url}` : url;
      return `<a href="${hyperlink}" target="_blank" class="link">${url}</a>`;
    });
  }
  openChat(chat: any) {
    this.current_chat = chat;
    this.screen = "single";
    this.messageText = "";
    this.messagesList = [];
    this.current_chat_user = this.current_chat.user_name;
    this.fetchChatMessages(chat.mad_id);
    this.markAsRead(chat.mad_id);
  }
  getChatClass(count: any) {
    if (count > 0) {
      return "message-outer-active";
    } else {
      return "message-outer";
    }
  }
  back() {
    this.screen = "list";
    this.messageText = "";
    this.current_chat = null;
    this.fetchChats();
  }
  sendMessage() {
    // Check for empty or whitespace-only message and loading state
    if (!this.messageText.trim() || this.msgLoad) {
      return;
    }
  
    const param = { "message": this.messageText };
    
    this.msgLoad = true; // Start loading state
  
    this._chatService.sendMessage(this.current_chat.mad_id, param)
      .pipe(
        finalize(() => this.msgLoad = false) // Automatically reset loading state
      )
      .subscribe({
        next: (data: any) => {
          if (data) {
            console.log(data);
            this.messagesList = [...this.messagesList, data]; // Cleaner message addition
            this.messageText = ""; // Clear input after successful send
          }
        },
        error: (error) => {
          console.error("Failed to send message:", error);
          alert("Error sending message. Please try again.");
        }
      });
  }
  fetchChats() {
    this._chatService.fetchChats()
      .subscribe((data: any) => {
        if (data) {
          console.log('ss',data);
          this.chatList = data;
        } else {

        }
      }
      );
  }
  // fetchChatMessages(mad_id:any){
  //   this._chatService.fetchChatMessages(mad_id)
  //   .subscribe((data:any) => {
  //     if(data){
  //       console.log(data);
  //       this.messagesList=data;
  //     }else{

  //     }
  //   }
  //   );
  // }
  fetchChatMessages(mad_id: any) {
    this.loading = true;
    this._chatService.fetchChatMessages(mad_id)
      .subscribe(
        (data: any) => {
          if (data) {
            console.log(data);
            this.messagesList = data;
            this.shouldScroll = true;
          } else {
            this.messagesList = [];
          }
        },
        (error) => {
          console.error('Error fetching chat messages:', error);
          this.messagesList = [];
        },
        () => {
          this.loading = false;
        }
      );
  }

  markAsRead(mad_id: any) {
    let param = {
      "senderId": mad_id
    }
    this._chatService.markAsRead(param)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
        } else {

        }
      }
      );
  }

  navigateToProfile(username: any) {
    let path = '/home/profile/' + username;
    this.router.navigate([path]);
  }
  onEnterPress(event: Event) {
    event.preventDefault();  
      this.sendMessage();  
    
  }

  autoGrow() {
    const textarea = document.getElementById('message-input') as HTMLTextAreaElement;
    textarea.style.height = 'auto'; // Reset height
    textarea.style.height = Math.min(textarea.scrollHeight, 6 * 1.5 * 16) + 'px'; // Calculate new height
  }
  onKeyDown(event: KeyboardEvent) {
    const textarea = event.target as HTMLTextAreaElement;
    
    // Allow Shift+Enter only if there's at least one character in the input
    if (event.shiftKey && event.key === 'Enter' && textarea.value.trim().length === 0) {
      event.preventDefault();
    }
  }

  gotoHome(){
    this.router.navigate(["home"]);
  }

  openNewChat(){

    const dialogRef = this.dialog.open(NewChatComponent, {
      data: {
        // view: view,
        // projectionId: this.proj.projection_id,
        // likesCount: this.proj.total_likes,
        // commentsCount: this.proj.total_comments,
      },
      width: '100%',
      height: 'auto',
      backdropClass: 'bdrop',
      position: { bottom: '0px', left: '10%', right: '0px', top: '0.8%' },
    });

    // dialogRef.afterClosed().subscribe((result) => {
    //   console.log(`Dialog result: ${result}`);
    // });
  }
}

