import { Component, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent {
  toggle:boolean=false;
  notificationsList:any=[];
  fetchingData = false;
  page:number=1;

  constructor(public dialog: MatDialog,
    private router: Router,
    private _generalService:GeneralService,
    private alertService: AlertService) {}
  ngOnInit(): void {
    this.getNotifications();
  }
  @HostListener('scroll', ['$event'])
  scrollHandler(event:any) {
    const element =event.target;
    const scrollPercentage = (element.scrollTop + element.clientHeight) / element.scrollHeight * 100;
    
    if (scrollPercentage >= 70 && !this.fetchingData) {
      console.log("User has scrolled to 70% of the content");
      this.getNotifications(); 
    }
  }

  getDownloadUrl(path: String) {
    if (path) {
      return (
        this._generalService.API_ENDPOINT +
        '/users/file/download?filename=' +
        path
      );
    } else {
      return '';
    }
  }
  navigateToProfile(username: any) {
    let path = '/home/profile/' + username;
    this.router.navigate([path]);
  }
  navigateTo(notify:any){
    // let urlTxt=btoa("projection|"+this.notify.projection_id);
  }
  
  changeToggle(){
    this.toggle=!this.toggle;
  }
  getNotifications(){
    this.fetchingData = true;
    this._generalService.getNotifications(this.page)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        if(data.response){
          this.notificationsList = this.notificationsList.concat(data.response);
          this.markAllRead(data.response);
          this.page+=1;
        }
        this.fetchingData = false;
      }else{
        
      }
    }
    );
  }
  markAllRead(data:any){
    data.forEach( (value: any) => {
      if(value.status=="unseen" || value.status==""){
        this.markAsRead(value.notification_id);
      }
    });
  }
  markAsRead(id:number){
    let param={
      "notification_id":id
    }
    this._generalService.markNotificationAsRead(param)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
      }else{        
      }
    }
    );
  }

}
