
import { MatButtonModule } from '@angular/material/button';
import {Component, Inject} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  MatDialogRef,
  MatDialogModule,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { GeneralService } from 'src/app/services/general.service';
import { PDFProgressData, PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-projection-view',
  templateUrl: './projection-view.component.html',
  styleUrls: ['./projection-view.component.css'],
})
export class ProjectionViewComponent {
  proj:any;
  currentPage=1;
  totalPages=0;
  progress=0;
  zoom=1;
  style="width: 50%;height: 2.5px;background-color: #d9d9d9;";

  constructor(public dialogRef: MatDialogRef<ProjectionViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _generalService:GeneralService,
    ) {
    this.proj=data;
  }
  onBack(): void {
    this.dialogRef.close();
  }
  getDownloadUrl(path:String){
    if(path){
    return this._generalService.API_ENDPOINT+"/users/file/download?filename="+path;
  }else{
return "";
}
  }
  next(){
    this.currentPage+=1;
    this.progress=this.currentPage/this.totalPages*100;
    this.style="width: "+this.progress+"%;height: 2.5px;background-color: #d9d9d9;";
  }
  back(){
    if (this.currentPage > 1) {
      this.currentPage-=1;
      this.progress=this.currentPage/this.totalPages*100;
      this.style="width: "+this.progress+"%;height: 2.5px;background-color: #d9d9d9;";
    }
  }
  zoomIn(){
    this.zoom+=0.3;
  }
  zoomOut(){
    this.zoom-=0.3;
  }


  onProgress(progressData: PDFProgressData) {
    // do anything with progress data. For example progress indicator
    console.log(progressData);
  }
  pageRendered(e: CustomEvent) {
    console.log('(page-rendered)', e);
  }
  callBackFn(pdf: PDFDocumentProxy) {
    // do anything with "pdf"
    console.log('(page-loaded)', pdf);
    console.log(pdf._pdfInfo.numPages);
    this.totalPages=pdf._pdfInfo.numPages;
    this.progress=this.currentPage/this.totalPages*100;
    this.style="width: "+this.progress+"%;height: 2.5px;background-color: #d9d9d9;";
 }

}