<div class="account_settings_wrapper">
    <input type="checkbox" name="toggle" id="deactivate_account_toggle">
    <div class="account_settings no-scrollbar">
        <div class="account_settings_box">
            <div class="account_settings_box_title">
                <p>Details</p>
            </div>
            <div class="account_settings_box_desc">
                <p>
                    Your personal information, such as your email and phone number, is private
                    and will not be disclosed anywhere. You cannot change the email associated
                    with your MadScientist account.
                </p>
            </div>
            <div class="account_settings_input_box">
                <label class="account_settings_input_box_label" for="account_settings_email">
                    <p class="label">Email <span>(You can't change this email id)</span></p>
                </label>
                <div class="account_settings_input_wrapper">
                    <div class="account_settings_input_icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M16 5H4C3.91898 5 3.84021 5.00964 3.76477 5.02782L9.65977 10.5018C9.85161 10.6799 10.1484 10.6799 10.3402 10.5018L16.2352 5.02782C16.1598 5.00964 16.081 5 16 5ZM3 6C3 5.90258 3.01393 5.80841 3.03991 5.71938L7.4543 9.81845L3 13.8273V6ZM3.40767 14.8058C3.57347 14.9279 3.77831 15 4 15H16C16.2217 15 16.4265 14.9279 16.5923 14.8058L11.81 10.5016L11.0207 11.2345C10.4452 11.769 9.55484 11.769 8.97932 11.2345L8.19004 10.5016L3.40767 14.8058ZM12.5457 9.81845L17 13.8273V6C17 5.90258 16.9861 5.80841 16.9601 5.71938L12.5457 9.81845ZM4 4C2.89543 4 2 4.89543 2 6V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V6C18 4.89543 17.1046 4 16 4H4Z"
                                fill="#BABABA" />
                        </svg>
                    </div>
                    <input type="email" name="account_settings_email" id="account_settings_email"
                        value="" [(ngModel)]="userData.primary_mail" readonly>
                </div>
            </div>
            <div class="account_settings_input_box">
                <label class="account_settings_input_box_label" for="account_settings_secondary_email">
                    <p class="label">Secondary email</p>
                    <p class="err_message" *ngIf="validations.secondary_mail">Invalid Email</p>
                </label>
                <div class="account_settings_input_wrapper">
                    <div class="account_settings_input_icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M16 5H4C3.91898 5 3.84021 5.00964 3.76477 5.02782L9.65977 10.5018C9.85161 10.6799 10.1484 10.6799 10.3402 10.5018L16.2352 5.02782C16.1598 5.00964 16.081 5 16 5ZM3 6C3 5.90258 3.01393 5.80841 3.03991 5.71938L7.4543 9.81845L3 13.8273V6ZM3.40767 14.8058C3.57347 14.9279 3.77831 15 4 15H16C16.2217 15 16.4265 14.9279 16.5923 14.8058L11.81 10.5016L11.0207 11.2345C10.4452 11.769 9.55484 11.769 8.97932 11.2345L8.19004 10.5016L3.40767 14.8058ZM12.5457 9.81845L17 13.8273V6C17 5.90258 16.9861 5.80841 16.9601 5.71938L12.5457 9.81845ZM4 4C2.89543 4 2 4.89543 2 6V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V6C18 4.89543 17.1046 4 16 4H4Z"
                                fill="#BABABA" />
                        </svg>
                    </div>
                    <input type="email" name="account_settings_secondary_email" id="account_settings_secondary_email"
                        value="" [(ngModel)]="userData.secondary_mail" (input)="validateEmail()">
                </div>
            </div>
            <div class="account_settings_input_box account_settings_input_box--phone">
                <label class="account_settings_input_box_label" for="account_settings_phone">
                    <p class="label">Phone number</p>
                    <p class="err_message" *ngIf="validations.primary_mobile">Invalid Phone Number</p>
                </label>
                <div class="phone_input_group">
                    <!-- <div class="account_settings_input_wrapper account_settings_input_wrapper--country-code">
                        <input type="text" pattern="" name="account_settings_phone_country_code"
                            id="account_settings_phone_country_code" value="+91" maxlength="4"
                            (input)="addPlusSign(countryCodeInput)" #countryCodeInput>
                    </div> -->
                    <div class="account_settings_input_wrapper account_settings_input_wrapper--phone">
                        <div class="account_settings_input_icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M3.40646 13.032C4.00079 10.7132 5.20743 8.59668 6.9001 6.90401C8.59278 5.21133 10.7093 4.0047 13.0281 3.41036C14.8123 2.9562 16.3281 4.49037 16.3281 6.33203V7.16537C16.3281 7.62537 15.954 7.99453 15.4965 8.04037C14.7377 8.11516 13.9929 8.29435 13.2831 8.57287L12.0165 7.3062C9.95403 8.29482 8.29091 9.95793 7.30229 12.0204L8.56896 13.287C8.29016 13.9967 8.11068 14.7416 8.03563 15.5004C7.99063 15.9587 7.62146 16.332 7.16146 16.332H6.32813C4.48646 16.332 2.95229 14.8162 3.40646 13.032Z"
                                    stroke="#BABABA" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <input type="text" name="account_settings_phone" id="account_settings_phone" value="79959 29246"
                            (input)="verifyPhone(phoneInput)" #phoneInput [(ngModel)]="userData.primary_mobile" (input)="validateMobile()">
                    </div>
                </div>
            </div>
        </div>
        <div class="account_settings_box">
            <div class="account_settings_box_title">
                <p>Change password</p>
            </div>
            <div class="account_settings_box_desc">
                <p>
                    We suggest that you choose a strong password. Change your forgot password
                    through sign in page by logging out.
                </p>
            </div>
            <div class="account_settings_input_box">
                <label class="account_settings_input_box_label" for="account_settings_old_pwd">
                    <p class="label">Old password</p>
                    <p class="err_message" *ngIf="validations.old_password">Incorrect Password</p>
                </label>
                <div class="account_settings_input_wrapper">
                    <input type="password" name="account_settings_old_pwd" id="account_settings_old_pwd"
                        value="" #pwd2 [(ngModel)]="old_password">
                    <div class="account_settings_input_icon account_settings_input_icon--pwd"
                        (click)="togglePasswordType(pwd2)">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="initial_pwd_icon">
                            <path d="M2.5 7.49935V7.49935C6.91139 4.06827 13.0886 4.06827 17.5 7.49935V7.49935"
                                stroke="white" stroke-width="0.833333" stroke-linecap="round" />
                            <circle cx="9.9987" cy="11.6667" r="3.75" fill="white" stroke="white"
                                stroke-width="0.833333" />
                        </svg>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="final_pwd_icon">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M17.9132 13.3333C18.1452 13.3333 18.3345 13.1451 18.3228 12.9133C18.2748 11.9625 18.0641 11.0258 17.699 10.1443C17.2802 9.13326 16.6664 8.2146 15.8926 7.44078C15.1187 6.66696 14.2001 6.05313 13.189 5.63434C12.178 5.21555 11.0944 5 10 5C8.90567 5 7.82203 5.21555 6.81099 5.63434C5.79994 6.05313 4.88128 6.66696 4.10746 7.44078C3.33364 8.2146 2.71981 9.13326 2.30102 10.1443C1.93589 11.0258 1.72525 11.9625 1.67727 12.9133C1.66558 13.1451 1.8548 13.3333 2.08685 13.3333C2.31891 13.3333 2.5058 13.1451 2.5188 12.9134C2.56598 12.0729 2.7545 11.2454 3.0774 10.4659C3.45395 9.5568 4.00588 8.73078 4.70167 8.03499C5.39746 7.3392 6.22348 6.78727 7.13257 6.41071C8.04166 6.03416 9.01602 5.84034 10 5.84034C10.984 5.84034 11.9584 6.03416 12.8675 6.41071C13.7765 6.78727 14.6026 7.3392 15.2984 8.03499C15.9941 8.73078 16.5461 9.5568 16.9226 10.4659C17.2455 11.2454 17.434 12.0729 17.4812 12.9134C17.4942 13.1451 17.6811 13.3333 17.9132 13.3333ZM12.2543 13.757C13.3751 14.303 14.1667 14.6886 14.1667 13.3333C14.1667 11.0321 12.3012 9.16667 10 9.16667C7.69883 9.16667 5.83335 11.0321 5.83335 13.3333C5.83335 14.6196 6.286 14.3378 7.08713 13.8389C7.71928 13.4454 8.56841 12.9167 9.58335 12.9167C10.5293 12.9167 11.472 13.3759 12.2543 13.757Z"
                                fill="#333333" />
                            <path d="M16.6667 3.3335L2.5 17.5002" stroke="#333333" stroke-width="0.833333"
                                stroke-linecap="round" />
                        </svg>
                    </div>
                </div>
            </div>
            <div class="account_settings_input_box">
                <label class="account_settings_input_box_label" for="account_settings_new_pwd">
                    <p class="label">New password <span>[min 6 char, Number, Upper case]</span>
                    </p>
                    <p class="err_message" *ngIf="validations.new_password">Invalid Password</p>
                </label>
                <div class="account_settings_input_wrapper">
                    <input type="password" name="account_settings_new_pwd" id="account_settings_new_pwd"
                        value="" #pwd3 [(ngModel)]="new_password" (input)="validatePassword()">
                    <div class="account_settings_input_icon account_settings_input_icon--pwd"
                        (click)="togglePasswordType(pwd3)">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="initial_pwd_icon">
                            <path d="M2.5 7.49935V7.49935C6.91139 4.06827 13.0886 4.06827 17.5 7.49935V7.49935"
                                stroke="white" stroke-width="0.833333" stroke-linecap="round" />
                            <circle cx="9.9987" cy="11.6667" r="3.75" fill="white" stroke="white"
                                stroke-width="0.833333" />
                        </svg>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="final_pwd_icon">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M17.9132 13.3333C18.1452 13.3333 18.3345 13.1451 18.3228 12.9133C18.2748 11.9625 18.0641 11.0258 17.699 10.1443C17.2802 9.13326 16.6664 8.2146 15.8926 7.44078C15.1187 6.66696 14.2001 6.05313 13.189 5.63434C12.178 5.21555 11.0944 5 10 5C8.90567 5 7.82203 5.21555 6.81099 5.63434C5.79994 6.05313 4.88128 6.66696 4.10746 7.44078C3.33364 8.2146 2.71981 9.13326 2.30102 10.1443C1.93589 11.0258 1.72525 11.9625 1.67727 12.9133C1.66558 13.1451 1.8548 13.3333 2.08685 13.3333C2.31891 13.3333 2.5058 13.1451 2.5188 12.9134C2.56598 12.0729 2.7545 11.2454 3.0774 10.4659C3.45395 9.5568 4.00588 8.73078 4.70167 8.03499C5.39746 7.3392 6.22348 6.78727 7.13257 6.41071C8.04166 6.03416 9.01602 5.84034 10 5.84034C10.984 5.84034 11.9584 6.03416 12.8675 6.41071C13.7765 6.78727 14.6026 7.3392 15.2984 8.03499C15.9941 8.73078 16.5461 9.5568 16.9226 10.4659C17.2455 11.2454 17.434 12.0729 17.4812 12.9134C17.4942 13.1451 17.6811 13.3333 17.9132 13.3333ZM12.2543 13.757C13.3751 14.303 14.1667 14.6886 14.1667 13.3333C14.1667 11.0321 12.3012 9.16667 10 9.16667C7.69883 9.16667 5.83335 11.0321 5.83335 13.3333C5.83335 14.6196 6.286 14.3378 7.08713 13.8389C7.71928 13.4454 8.56841 12.9167 9.58335 12.9167C10.5293 12.9167 11.472 13.3759 12.2543 13.757Z"
                                fill="#333333" />
                            <path d="M16.6667 3.3335L2.5 17.5002" stroke="#333333" stroke-width="0.833333"
                                stroke-linecap="round" />
                        </svg>
                    </div>
                </div>
            </div>
            <div class="account_settings_button_box">
                <button class="account_settings_button" (click)="changePassword()">
                    <p>Save password</p>
                </button>
            </div>
        </div>
        <div class="account_settings_box account_settings_box--area-51">
            <div class="account_settings_box_title">
                <p>Area 51</p>
            </div>
            <div class="account_settings_button_box" (click)="deactivate()">
                <label class="account_settings_button" for="deactivate_account_toggle">
                    <p>Deactivate account</p>
                </label>
            </div>
        </div>
    </div>
    <div class="save_changes_box">
        <button class="save_changes_btn" (click)="editProfile()">
            <p>Save Changes</p>
        </button>
    </div>
</div>