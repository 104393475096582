<div class="feed">
    <!-- Insert Content here -->
    <div class="search-box">
      <div class="search-navbar">
        <div class="search-navbar-navlinks">
          <a 
          (click)="changeTab('projection')"
          [ngClass]="current_view == 'projection'? 'search-navbar-navlink search-navbar-navlink--active': 'search-navbar-navlink'"
          >
            <p>Projections</p>
          </a>
          <a (click)="changeTab('user')"
          [ngClass]="current_view == 'user'? 'search-navbar-navlink search-navbar-navlink--active': 'search-navbar-navlink'"
          >
            <p>People</p>
          </a>
          <a (click)="changeTab('writeup')" [ngClass]="current_view == 'writeup'? 'search-navbar-navlink search-navbar-navlink--active': 'search-navbar-navlink'"
          >
            <p>Writeups</p>
          </a>
          <a (click)="changeTab('forum')" [ngClass]="current_view == 'forum'? 'search-navbar-navlink search-navbar-navlink--active': 'search-navbar-navlink'"
          >
            <p>Forums</p>
          </a>
        </div>
        <div class="search-navbar-close-btn-box">
          <button class="search-navbar-close-btn" (click)="closeSearch()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.257 19.7C18.6557 20.0987 19.3022 20.0987 19.7009 19.7C20.0997 19.3013 20.0997 18.6548 19.7009 18.2561L13.4444 11.9998L19.7005 5.74389C20.0992 5.34516 20.0992 4.69871 19.7005 4.29998C19.3018 3.90126 18.6553 3.90126 18.2566 4.29998L12.0005 10.5559L5.74345 4.29904C5.34471 3.90032 4.69823 3.90032 4.2995 4.29904C3.90076 4.69777 3.90076 5.34422 4.2995 5.74295L10.5565 11.9998L4.29905 18.2571C3.90032 18.6558 3.90032 19.3022 4.29905 19.701C4.69779 20.0997 5.34426 20.0997 5.743 19.701L12.0005 13.4437L18.257 19.7Z"
                fill="white" />
            </svg>
          </button>
        </div>
      </div>
      <div class="search">
        <div class="search_projections" *ngIf="current_view=='projection'">
          <div *ngFor="let post of feed" >
            <app-projections [proj]="post"></app-projections>
          </div>  
        </div>
        <div class="search_peoples" *ngIf="current_view=='user'">
          <div class="search_people" *ngFor="let user of feed">
            <div class="search_people_upper_box">
              <div class="search_people_img_box">
                <img loading="lazy"
                  [src]="getDownloadUrl(user.profile_photo_path)"
                  alt="">
              </div>
              <div class="search_people_about">
                <h2 class="search_people_about_name">{{user.first_name}} {{user.last_name}}</h2>
                <p class="search_people_about_tagline">{{user.tagline}}</p>
              </div>
            </div>
            <div class="search_people_bottom_box">
              <div class="search_people_followers_stats">
                <h3 class="search_people_followers_stats_text">Followers</h3>
                <p class="search_people_followers_stats_count">{{user.followers | socialCount}}</p>
              </div>
              <div class="search_people_action_btn_box">
                <button class="search_people_action_btn" *ngIf="user.is_following!=1" (click)="follow(user.mad_id)">
                  <p>Follow</p>
                </button>
                <button class="search_people_action_btn" *ngIf="user.is_following==1" (click)="unfollow(user.mad_id)">
                  <p>Unfollow</p>
                </button>
              </div>
              
            </div>
          </div>
        </div>
        <div class="search_writeups" *ngIf="current_view=='writeup'">
          <app-writeup [writeup]="post" *ngFor="let post of feed"></app-writeup>
        </div>
        <div class="search_forums" *ngIf="current_view=='forum'">
          <app-forum [forum]="post" *ngFor="let post of feed"></app-forum>
        </div>
        <div style="display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          text-align: center;">
            <p *ngIf="current_view=='projection'">No Projections Found</p>
            <p *ngIf="current_view=='user'">No People Found</p>
            <p *ngIf="current_view=='writeup'">No Writeups Found</p>
            <p *ngIf="current_view=='forum'">No Forum Found</p>
          </div>
      </div>
    </div>
  </div>