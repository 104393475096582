import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class SocketIoService {

  private socket!: Socket;

  constructor() {}

  connect(url: string, userId: string): void {
    const options = {
      query: {
        userId: userId
      }
    };
    this.socket = io(url, options);
  }

  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  // Emit event to the server
  emit(event: string, data: any): void {
    this.socket.emit(event, data);
  }

  // Listen for events from the server
  on(event: string, callback: (data: any) => void): void {
    this.socket.on(event, callback);
  }

  // Remove event listener
  off(event: string, callback?: (data: any) => void): void {
    this.socket.off(event, callback);
  }
}
