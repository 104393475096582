<div style="--grid-size: 30px;width: 500px;">
    <image-cropper 
        [imageChangedEvent]="imageChangedEvent" 
        [cropperMinWidth]="300" 
        [cropperMinHeight]="300"
        [maintainAspectRatio]="true" 
        [aspectRatio]="1 / 1" 
        [resizeToWidth]="500" 
        format="png"
        (imageCropped)="onImageCropped($event)"
        style="max-height: 600px;width: auto;"
    >
    </image-cropper>
    <button class="crop-btn" (click)="crop()">Crop</button>
</div>