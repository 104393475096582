<div class="discard_popup_box" *ngIf="discardFlag && !submitFlag">
    <div class="discard_popup">
        <h2 class="discard_title">Discard</h2>
        <div class="discard_text_box">
            <h2 class="discard_text">Are you sure you want to discard the Projection?</h2>
            <p class="discard_text_desc">Your Projection won't be saved if you leave</p>
        </div>
        <div class="discard_action_btns">
            <button class="discard_action_btn discard_action_btn--yes" (click)="close()">
                <p>Yes</p>
            </button>
            <button class="discard_action_btn discard_action_btn--no" (click)="discardFlag=false">
                <p>No</p>
            </button>
        </div>
    </div>
</div>    
<div class="add_forum_header" *ngIf="!submitFlag">
    <button class="close_btn" (click)="discardFlag=true">
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.10682 0.195262C1.84647 -0.0650874 1.42436 -0.0650874 1.16401 0.195262C0.903663 0.455612 0.903663 0.877722 1.16401 1.13807L6.02594 6L1.16401 10.8619C0.903663 11.1223 0.903663 11.5444 1.16401 11.8047C1.42436 12.0651 1.84647 12.0651 2.10682 11.8047L6.96875 6.94281L11.8307 11.8047C12.091 12.0651 12.5131 12.0651 12.7735 11.8047C13.0338 11.5444 13.0338 11.1223 12.7735 10.8619L7.91156 6L12.7735 1.13807C13.0338 0.877722 13.0338 0.455612 12.7735 0.195262C12.5131 -0.0650874 12.091 -0.0650874 11.8307 0.195262L6.96875 5.05719L2.10682 0.195262Z"
                fill="white" />
        </svg>
        <p>Discard</p>
    </button>
    <p *ngIf="type!='edit'" class="add_post_title">Creating Forum</p>
    <p *ngIf="type=='edit'" class="add_post_title">Edit Forum</p>
    <button class="add_post_post_btn" (click)="post()">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.6464 4.64645C12.4512 4.84171 12.4512 5.15829 12.6464 5.35355C12.8417 5.54882 13.1583 5.54882 13.3536 5.35355L12.6464 4.64645ZM17.5 1C17.5 0.723858 17.2761 0.5 17 0.5H12.5C12.2239 0.5 12 0.723858 12 1C12 1.27614 12.2239 1.5 12.5 1.5H16.5V5.5C16.5 5.77614 16.7239 6 17 6C17.2761 6 17.5 5.77614 17.5 5.5V1ZM13.3536 5.35355L17.3536 1.35355L16.6464 0.646447L12.6464 4.64645L13.3536 5.35355Z"
                fill="white" />
            <path d="M9 1H5C2.79086 1 1 2.79086 1 5V13C1 15.2091 2.79086 17 5 17H13C15.2091 17 17 15.2091 17 13V9"
                stroke="white" stroke-width="1.5" />
        </svg>
        <p *ngIf="type!='edit'">Post</p>
        <p *ngIf="type=='edit'">Edit</p>
    </button>
</div>
<div class="add_forum_content no-scrollbar" *ngIf="!submitFlag">
    <div class="add_forum_textarea_box">
        <textarea name="add_forum_textarea" id="add_forum_textarea" class="add_forum_textarea add_forum_scrollbar"
            [(ngModel)]="title" maxlength="200"></textarea>
        <h3 class="add_forum_placeholder" *ngIf="title==''">
            What's your question for the community?
        </h3>
        <span class="count">{{200 - title.length}}</span>
    </div>
    <div class="add_forum_view_textarea_box">
        <textarea name="add_forum_view_textarea" id="add_forum_view_textarea"
            class="add_forum_view_textarea add_forum_scrollbar" [(ngModel)]="your_view" maxlength="1000"></textarea>
        <h3 class="add_forum_view_placeholder" *ngIf="your_view==''">
            More brief about the question. (Optional)
        </h3>
        <span class="count">{{1000 - your_view.length}}</span>
    </div>
</div>

<div *ngIf="submitFlag">
    <div class="black_bg_box">
        <div class="upload_file_box_wrapper popUpWrapper">
            <div class="progress_bar_box">
                <div class="progress_bar"></div>
            </div>
            <div class="upload_file_box" *ngIf="isLoading && !successFlg">
                <div class="upload_file_wrapper">
                    <div class="upload_file">
                        <!-- <svg width="35" height="34" viewBox="0 0 35 34" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="17.5" cy="17" r="16" stroke="#363636" stroke-width="2" />
                            <path
                                d="M28.502 5.38295C26.2044 3.20696 23.3131 1.76117 20.1937 1.2284C17.0744 0.695625 13.8671 1.09981 10.9775 2.38983C8.0879 3.67986 5.64571 5.79778 3.95978 8.47579C2.27385 11.1538 1.4199 14.2716 1.50591 17.4349C1.59193 20.5983 2.61405 23.665 4.44303 26.2475C6.272 28.8299 8.82568 30.812 11.7811 31.943C14.7366 33.0741 17.9611 33.3034 21.0468 32.6019"
                                stroke="#10776B" stroke-width="2" stroke-linecap="round" />
                        </svg> -->
                        <svg width="35" height="34" viewBox="0 0 35 34" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="17.5" cy="17" r="16" stroke="#363636" stroke-width="2" />
                            <path id="loaderPath"
                                d="M28.502 5.38295C26.2044 3.20696 23.3131 1.76117 20.1937 1.2284C17.0744 0.695625 13.8671 1.09981 10.9775 2.38983C8.0879 3.67986 5.64571 5.79778 3.95978 8.47579C2.27385 11.1538 1.4199 14.2716 1.50591 17.4349C1.59193 20.5983 2.61405 23.665 4.44303 26.2475C6.272 28.8299 8.82568 30.812 11.7811 31.943C14.7366 33.0741 17.9611 33.3034 21.0468 32.6019"
                                stroke="#10776B" stroke-width="2" stroke-linecap="round">
                                <animateTransform attributeName="transform" attributeType="XML"
                                    type="rotate" from="0 17 17" to="360 17 17" dur="2s"
                                    repeatCount="indefinite" />
                            </path>
                        </svg>
                        <div class="upload_file_text_group">
                            <p class="upload_file_text">Uploading.</p>
                            <p class="upload_file_desc">
                                Please wait while projecting to MadScientist
                            </p>
                        </div>
                    </div>
                </div>
                <div class="review_text_box">
                    <p>
                        Our team will verify your content. We may delete if it's not relevant to
                        Madscientist, or any misinformation.
                    </p>
                </div>
            </div>
            <div class="upload_success_box" *ngIf="successFlg">
                <div class="upload_success">
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle cx="24" cy="24" r="24" fill="url(#paint0_linear_7237_12527)" />
                        <path
                            d="M15.5 23.7579L20.6233 28.6099C21.1611 29.1192 22.029 29.1314 22.5825 28.6373L34.5 18"
                            stroke="white" stroke-width="2" stroke-linecap="round" />
                        <defs>
                            <linearGradient id="paint0_linear_7237_12527" x1="24" y1="0" x2="24" y2="48"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#10776B" />
                                <stop offset="1" stop-color="#009DA7" stop-opacity="0" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <div class="upload_success_text">
                        <p>Projected successfully</p>
                    </div>
                </div>
            </div>
            <div class="edit_btn_box">
                <button class="edit_btn">
                    <p>Edit</p>
                </button>
            </div>
        </div>
    </div>
</div>