import { Component } from '@angular/core';

@Component({
  selector: 'app-notifications-setting',
  templateUrl: './notifications-setting.component.html',
  styleUrls: ['./notifications-setting.component.css']
})
export class NotificationsSettingComponent {

}
