import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css']
})
export class InviteComponent implements OnInit{
  link="";
  userData:any;
  constructor(private _generalService: GeneralService){

  }

  ngOnInit(): void {
    if (localStorage.getItem("mad_user")) {
      const loggedUserData = JSON.parse(localStorage.getItem('mad_user') || '{}');
      this.userData=loggedUserData;
      this.link="https://www.madscientist.tech/home/profile/"+loggedUserData.user_name;
    }
  }
  getDownloadUrl(path: String) {
    if (path) {
      return this._generalService.API_ENDPOINT + "/users/file/download?filename=" + path;
    } else {
      return "";
    }
  }

}
