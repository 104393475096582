import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AddForumComponent } from 'src/app/popups/add-forum/add-forum.component';
import { AddProjectionComponent } from 'src/app/popups/add-projection/add-projection.component';
import { AddWriteupComponent } from 'src/app/popups/add-writeup/add-writeup.component';
import { CreatePostComponent } from 'src/app/popups/create-post/create-post.component';
import { GeneralService } from 'src/app/services/general.service';
import { AlertService } from 'src/app/services/alert.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { SocketIoService } from 'src/app/services/socket-io.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  mad_data: any;
  toggleFlag = false;
  seachUserList:any=[]
  search_text="";
  year = new Date().getFullYear()
  search_focus=false;
  loggedUserId:any;
  notificationCount:any=0;
  messagesCount:any=0;
  logoutFlag=false;
  navFlg=false;
  add_iconFlg=true;
  current_url="";

  constructor(public dialog: MatDialog,
    private router: Router,
    private elementRef: ElementRef,
    private _generalService: GeneralService,
    public alertService: AlertService,
    private readonly _socialService: SocialAuthService,
    private socketService: SocketIoService
  ) {
    router.events.subscribe((url:any) => {
      console.log(url);
      if(url.url){
        this.current_url=url.url;
        if(url.url=="/home/messages" || url.url.startsWith("/home/profile/")){
          this.navFlg=false;
        }else{
          this.navFlg=true;
        }
        if(url.url=="/home/settings" || url.url=="/home/invite" ||url.url=="/home/help" || url.url.startsWith("/home/messages")){
          this.add_iconFlg=false;
        }else{
          this.add_iconFlg=true;
        }

        // if(url.url.startsWith("/home/profile/")){
        //   this.navFlg=false;
        // }else{
        //   this.navFlg=true;
        // }
      }

    }
  );
  }
  ngOnInit(): void {
    this.toggleFlag=false;
    if (localStorage.getItem("mad_user")) {
      this.mad_data = JSON.parse(localStorage.getItem('mad_user') || '{}');
      this.loggedUserId=this.mad_data.mad_id;
      const userId = this.mad_data.mad_id; // Replace with your logic to get the user ID
      const socketUrl = 'https://chat-io.madscientist.tech'; // Replace with your Socket.IO server URL
      this.socketService.connect(socketUrl, userId);

      this.socketService.on('newMessage', (data) => {
        console.log('Message received:', data);
        this.alertService.showAlert("success","New Message Received");
        
      });
    }else{
      // this.router.navigate(["signin"]);
    }
    this.getNotificationsCount();
  }
  getDownloadUrl(path: String) {
    if (path) {
      return this._generalService.API_ENDPOINT + "/users/file/download?filename=" + path;
    } else {
      return "";
    }
  }
  navigateToProfileSearch(username:any){
    this.search_focus=false;
    let path = "/home"; // Navigate to a different route
    this.router.navigateByUrl(path, { skipLocationChange: true }).then(() => {
      // Navigate back to the profile route
      path = "/home/profile/" + username;
      this.router.navigate([path]);
      this.toggleFlag=false;
      console.log(this.toggleFlag);
    });
  }

  navigateTo(path: any) {
    this.toggleFlag=false;
    console.log(path);
    console.log(this.current_url);

    if(this.current_url=="/"+path){
      console.log("hi");
      let cpath = "/home"; 
      this.router.navigateByUrl(cpath, { skipLocationChange: true }).then(() => {
        this.router.navigate([path]);
      });
    }else{
      this.router.navigate([path]);
    }
    this.search_focus=false;
    console.log(this.search_focus);
    this.search_text="";
    this.seachUserList=[];
  }
  navigateToProfile() {
    // let path="/home/profile/"+this.mad_data.mad_id;
    // this.router.navigate([path]);
    let path = "/home"; // Navigate to a different route
    this.router.navigateByUrl(path, { skipLocationChange: true }).then(() => {
      // Navigate back to the profile route
      path = "/home/profile/" + this.mad_data.user_name;
      this.router.navigate([path]);
      this.toggleFlag=false;
      this.search_focus=false;
      this.search_text="";
      this.seachUserList=[];  
      console.log(this.toggleFlag);
    });
  }
  gotoSearch(){
    let path = "/home"; // Navigate to a different route
    this.router.navigateByUrl(path, { skipLocationChange: true }).then(() => {
      // Navigate back to the profile route
      path = "/home/search/" + this.search_text;
      this.router.navigate([path]);
      this.toggleFlag=false;
      this.search_focus=false;
      // this.search_text="";
      this.seachUserList=[];  
      console.log(this.toggleFlag);
    });
  }

  closeSearch(event: MouseEvent) {
    this.search_text="";
    this.search_focus=false;
    const searchBarContainer = document.querySelector(".searchWrapper") as HTMLElement;
    const closeBtn = event.currentTarget as HTMLElement;

    if (searchBarContainer && closeBtn) {
      closeBtn.blur();
      searchBarContainer.focus();
    }
  }

  changeToggleFlg() {
    this.toggleFlag = !this.toggleFlag;
    console.log(this.toggleFlag);
  }
  logout() {
    localStorage.clear();
    this._socialService.signOut();
    this.logoutFlag=false;
    this.router.navigate(["signin"]);
  }

  openDialog() {
    // const dialogRef = this.dialog.open(CreatePostComponent,{
    //   data: {
    //     animal: 'panda'
    //   },
    //   width: '100%',
    //   height:'98%',
    //   backdropClass: "bdrop",
    //   position:{'bottom': '0px',
    //   'left': '10%',
    //   'right': '0px',
    //   'top': '0.8%'}
    // }
    // );
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });

    const dialogRef = this.dialog.open(CreatePostComponent, {
      data: {
        animal: 'panda'
      },
      width: '55%',
      height: '70%',
      backdropClass: "bdrop",
      position: {
        'bottom': '0px',
        'left': '22.5%',
        'right': '0px',
        'top': '6.5%'
      }
    }
    );
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == "projection") {
        const dialogRefIn = this.dialog.open(AddProjectionComponent, {
          data: {
            type: 'new',
            projection:""
          },
          width: '55%',
          height: '70%',
          backdropClass: "bdrop",
          position: {
            'bottom': '0px',
            'left': '22.5%',
            'right': '0px',
            'top': '6.5%'
          }
        }
        );
        dialogRefIn.afterClosed().subscribe(result => {
          let path = "/home";
          this.router.navigateByUrl(path, { skipLocationChange: true }).then(() => {
            path = "/home";
            this.router.navigate([path]);
            this.toggleFlag=false;
            this.search_focus=false;
            this.search_text="";
            this.seachUserList=[];  
          });
        });
      } else if (result == "writeup") {
        const dialogRefIn = this.dialog.open(AddWriteupComponent, {
          data: {
            type: 'new',
            writeup:""
          },
          width: '80%',
          height: '85%',
          backdropClass: "bdrop",
          position: {
            'bottom': '0px',
            'left': '10%',
            'right': '0px',
            'top': '3.5%'
          }
        }
        );
        dialogRefIn.afterClosed().subscribe(result => {
          let path = "/home";
          this.router.navigateByUrl(path, { skipLocationChange: true }).then(() => {
            path = "/home/writeups";
            this.router.navigate([path]);
            this.toggleFlag=false;
            this.search_focus=false;
            this.search_text="";
            this.seachUserList=[];  
          });
        });
      } else if (result == "forum") {
        const dialogRefIn = this.dialog.open(AddForumComponent, {
          data: {
            type: 'new',
            forum:""
          },
          width: '60%',
          height: '60%',
          backdropClass: "bdrop",
          position: {
            'bottom': '0px',
            'left': '20%',
            'right': '0px',
            'top': '10%'
          }
        }
        );
        dialogRefIn.afterClosed().subscribe(result => {
          let path = "/home";
          this.router.navigateByUrl(path, { skipLocationChange: true }).then(() => {
            path = "/home/forums";
            this.router.navigate([path]);
            this.toggleFlag=false;
            this.search_focus=false;
            this.search_text="";
            this.seachUserList=[];  
          });
        });

      }
    });
  }
  search(event: any) {
      const param = {
        "text": this.search_text,
        "userId":localStorage.getItem('mad_id')
      };
      this._generalService.userSearch(param)
        .subscribe((data: any) => {
          if (data) {
            this.seachUserList=data;
          } else {

          }
        }
        );

    // }
  }
  getNotificationsCount(){
    this._generalService.getNotificationsCount()
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        if(data.response){
          this.notificationCount=data.response.count;
        }
      }else{
        
      }
    }
    );

  }

  // dynamicStyle: any = { backgroundColor: 'white' };

  // constructor(private router: Router, private activatedRoute: ActivatedRoute) {
  //   this.router.events.subscribe((event) => {
  //     if (event instanceof NavigationEnd) {
  //       // Get the current route and component
  //       const route = this.activatedRoute;
  //       while (route.firstChild) {
  //         route.firstChild;
  //       }
  //       const component = route.component;
  //       this.updateDynamicStyleBasedOnComponent(component);
  //     }
  //   });
  // }
  // updateDynamicStyleBasedOnComponent(component: any) {
  //   // Check the component and set the style class or style object
  //   console.log(component);
  //   if (component === 'ComponentA') {
  //     this.dynamicStyle = { width: '100%' };
  //   } else {
  //     this.dynamicStyle = {};
  //   }
  // }

  onSearchFocus() {
    this.search_focus = true;
  }
  isRouteActive(route: string): boolean {
    return this.router.url === route;
  }
  
}
