<!-- <div class="create_post_box"> -->
<div class="create_post_header">
  <div class="create_post_title_box">
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4383_47237)">
        <rect x="0.96875" y="0.5" width="16" height="16" rx="4" fill="#10776B" />
        <path
          d="M9.41319 4.94444C9.41319 4.69898 9.21421 4.5 8.96875 4.5C8.72329 4.5 8.52431 4.69898 8.52431 4.94444V8.05556H5.41319C5.16773 8.05556 4.96875 8.25454 4.96875 8.5C4.96875 8.74546 5.16773 8.94444 5.41319 8.94444H8.52431V12.0556C8.52431 12.301 8.72329 12.5 8.96875 12.5C9.21421 12.5 9.41319 12.301 9.41319 12.0556V8.94444H12.5243C12.7698 8.94444 12.9688 8.74546 12.9688 8.5C12.9688 8.25454 12.7698 8.05556 12.5243 8.05556H9.41319V4.94444Z"
          fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_4383_47237">
          <rect width="16" height="16" fill="white" transform="translate(0.96875 0.5)" />
        </clipPath>
      </defs>
    </svg>
    <p class="create_post_title">Create with MadScientist</p>
  </div>
  <button class="close_btn" (click)="onClose()">
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_8395_56120)">
        <rect x="4.96875" y="4" width="24" height="24" rx="8" fill="#272727" />
        <rect x="5.46875" y="4.5" width="23" height="23" rx="7.5" stroke="#313131" />
        <path
          d="M12.1068 10.1953C11.8465 9.93491 11.4244 9.93491 11.164 10.1953C10.9037 10.4556 10.9037 10.8777 11.164 11.1381L16.0259 16L11.164 20.8619C10.9037 21.1223 10.9037 21.5444 11.164 21.8047C11.4244 22.0651 11.8465 22.0651 12.1068 21.8047L16.9688 16.9428L21.8307 21.8047C22.091 22.0651 22.5131 22.0651 22.7735 21.8047C23.0338 21.5444 23.0338 21.1223 22.7735 20.8619L17.9116 16L22.7735 11.1381C23.0338 10.8777 23.0338 10.4556 22.7735 10.1953C22.5131 9.93491 22.091 9.93491 21.8307 10.1953L16.9688 15.0572L12.1068 10.1953Z"
          fill="white" />
      </g>
      <defs>
        <filter id="filter0_d_8395_56120" x="0.96875" y="0" width="32" height="32" filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8395_56120" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8395_56120" result="shape" />
        </filter>
      </defs>
    </svg>
  </button>
</div>
<div class="create_post_body no-scrollbar">
  <div class="create_post_add_btns_group">
    <button class="create_post_add_btn" (click)="onAddProjection()">
      <svg class="go_to_arrow_icon" width="13" height="12" viewBox="0 0 13 12" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4383_47190)">
          <path
            d="M2.43842 9.95495C2.14553 10.2478 2.14553 10.7227 2.43842 11.0156C2.73131 11.3085 3.20619 11.3085 3.49908 11.0156L2.43842 9.95495ZM12.204 2C12.204 1.58579 11.8682 1.25 11.454 1.25H4.70403C4.28982 1.25 3.95403 1.58579 3.95403 2C3.95403 2.41421 4.28982 2.75 4.70403 2.75H10.704V8.75C10.704 9.16421 11.0398 9.5 11.454 9.5C11.8682 9.5 12.204 9.16421 12.204 8.75V2ZM3.49908 11.0156L11.9844 2.53033L10.9237 1.46967L2.43842 9.95495L3.49908 11.0156Z"
            fill="#EAEAEA" />
        </g>
        <defs>
          <clipPath id="clip0_4383_47190">
            <rect width="12" height="12" fill="white" transform="translate(0.96875)" />
          </clipPath>
        </defs>
      </svg>
      <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.71875" y="0.75" width="30.5" height="30.5" rx="15.25" stroke="#EAEAEA" stroke-width="1.5" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M16.4695 21.752C16.4695 22.1662 16.8053 22.502 17.2195 22.502C17.6337 22.502 17.9695 22.1662 17.9695 21.752V17.0051H22.7207C23.135 17.0051 23.4707 16.6693 23.4707 16.2551C23.4707 15.8409 23.135 15.5051 22.7207 15.5051H17.9695V10.75C17.9695 10.3358 17.6337 10 17.2195 10C16.8053 10 16.4695 10.3358 16.4695 10.75V15.5051H11.7188C11.3045 15.5051 10.9688 15.8409 10.9688 16.2551C10.9688 16.6693 11.3045 17.0051 11.7188 17.0051H16.4695V21.752Z"
          fill="#EAEAEA" />
      </svg>
      <div class="create_post_add_btn_content_box">
        <h3 class="create_post_add_btn_title">Add Projection</h3>
        <h3 class="create_post_add_btn_helper_text">Add Image, Video, 3D, Audio, PDF, Text</h3>
      </div>
    </button>
    <button class="create_post_add_btn" (click)="onAddWriteup()">
      <svg class="go_to_arrow_icon" width="13" height="12" viewBox="0 0 13 12" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4383_47190)">
          <path
            d="M2.43842 9.95495C2.14553 10.2478 2.14553 10.7227 2.43842 11.0156C2.73131 11.3085 3.20619 11.3085 3.49908 11.0156L2.43842 9.95495ZM12.204 2C12.204 1.58579 11.8682 1.25 11.454 1.25H4.70403C4.28982 1.25 3.95403 1.58579 3.95403 2C3.95403 2.41421 4.28982 2.75 4.70403 2.75H10.704V8.75C10.704 9.16421 11.0398 9.5 11.454 9.5C11.8682 9.5 12.204 9.16421 12.204 8.75V2ZM3.49908 11.0156L11.9844 2.53033L10.9237 1.46967L2.43842 9.95495L3.49908 11.0156Z"
            fill="#EAEAEA" />
        </g>
        <defs>
          <clipPath id="clip0_4383_47190">
            <rect width="12" height="12" fill="white" transform="translate(0.96875)" />
          </clipPath>
        </defs>
      </svg>
      <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M8.6 3.3125H25.4C27.7679 3.3125 29.6875 5.23208 29.6875 7.6V24.4C29.6875 26.7679 27.7679 28.6875 25.4 28.6875H8.6C6.23208 28.6875 4.3125 26.7679 4.3125 24.4V7.6C4.3125 5.23208 6.23208 3.3125 8.6 3.3125ZM3 7.6C3 4.5072 5.50721 2 8.6 2H25.4C28.4928 2 31 4.50721 31 7.6V24.4C31 27.4928 28.4928 30 25.4 30H8.6C5.5072 30 3 27.4928 3 24.4V7.6ZM22.622 7.97411C21.9886 7.53062 21.1156 7.68455 20.6721 8.31791L15.8541 15.1988L12.0216 20.6722L11.3799 24.0295L14.3152 22.2782L18.1477 16.8048L22.9658 9.92393C23.4093 9.29056 23.2553 8.41759 22.622 7.97411Z"
          fill="#EAEAEA" />
      </svg>
      <div class="create_post_add_btn_content_box">
        <h3 class="create_post_add_btn_title">Add Write-up</h3>
        <h3 class="create_post_add_btn_helper_text">Write your next tech article</h3>
      </div>
    </button>
    <button class="create_post_add_btn" (click)="onAddForum()">
      <svg class="go_to_arrow_icon" width="13" height="12" viewBox="0 0 13 12" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4383_47190)">
          <path
            d="M2.43842 9.95495C2.14553 10.2478 2.14553 10.7227 2.43842 11.0156C2.73131 11.3085 3.20619 11.3085 3.49908 11.0156L2.43842 9.95495ZM12.204 2C12.204 1.58579 11.8682 1.25 11.454 1.25H4.70403C4.28982 1.25 3.95403 1.58579 3.95403 2C3.95403 2.41421 4.28982 2.75 4.70403 2.75H10.704V8.75C10.704 9.16421 11.0398 9.5 11.454 9.5C11.8682 9.5 12.204 9.16421 12.204 8.75V2ZM3.49908 11.0156L11.9844 2.53033L10.9237 1.46967L2.43842 9.95495L3.49908 11.0156Z"
            fill="#EAEAEA" />
        </g>
        <defs>
          <clipPath id="clip0_4383_47190">
            <rect width="12" height="12" fill="white" transform="translate(0.96875)" />
          </clipPath>
        </defs>
      </svg>
      <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M24.814 3.95349H4.95349C4.41405 3.95349 3.97674 3.51619 3.97674 2.97674C3.97674 2.4373 4.41405 2 4.95349 2H24.814C28.2304 2 31 4.76959 31 8.18605V15.0233C31 15.5627 30.5627 16 30.0233 16C29.4838 16 29.0465 15.5627 29.0465 15.0233V8.18605C29.0465 5.84847 27.1515 3.95349 24.814 3.95349Z"
          fill="#EAEAEA" />
        <path
          d="M3.97674 16C4.51619 16 4.95349 16.4373 4.95349 16.9767V23.814C4.95349 26.1515 6.84847 28.0465 9.18605 28.0465H29.0465C29.586 28.0465 30.0233 28.4838 30.0233 29.0233C30.0233 29.5627 29.586 30 29.0465 30H9.18605C5.76959 30 3 27.2304 3 23.814V16.9767C3 16.4373 3.4373 16 3.97674 16Z"
          fill="#EAEAEA" />
        <path d="M8.60156 20.1992H25.4016" stroke="#EAEAEA" stroke-width="1.4" stroke-linecap="round" />
        <path d="M8.60156 13.1992H25.4016" stroke="#EAEAEA" stroke-width="1.4" stroke-linecap="round" />
      </svg>
      <div class="create_post_add_btn_content_box">
        <h3 class="create_post_add_btn_title">Create Forum</h3>
        <h3 class="create_post_add_btn_helper_text">Ask community for help, question & discuss</h3>
      </div>
    </button>
  </div>
  <div class="guidelines_text_box">
    <svg width="69" height="50" viewBox="0 0 69 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.9128 2C29.9128 2.73638 30.5097 3.33333 31.2461 3.33333C31.9825 3.33333 32.5794 2.73638 32.5794 2C32.5794 1.26362 31.9825 0.666667 31.2461 0.666667C30.5097 0.666667 29.9128 1.26362 29.9128 2ZM31.2461 14V14.25H31.4961V14H31.2461ZM11.9128 14C11.9128 14.7364 12.5097 15.3333 13.2461 15.3333C13.9825 15.3333 14.5794 14.7364 14.5794 14C14.5794 13.2636 13.9825 12.6667 13.2461 12.6667C12.5097 12.6667 11.9128 13.2636 11.9128 14ZM30.9961 2V14H31.4961V2H30.9961ZM31.2461 13.75H13.2461V14.25H31.2461V13.75Z"
        fill="url(#paint0_linear_4383_47223)" />
      <path
        d="M6.96875 21.4818C7.70513 21.4818 8.30208 20.8848 8.30208 20.1484C8.30208 19.4121 7.70513 18.8151 6.96875 18.8151C6.23237 18.8151 5.63542 19.4121 5.63542 20.1484C5.63542 20.8848 6.23237 21.4818 6.96875 21.4818ZM18.9688 20.1484H19.2188V19.8984H18.9688V20.1484ZM18.9688 39.4818C19.7051 39.4818 20.3021 38.8848 20.3021 38.1484C20.3021 37.4121 19.7051 36.8151 18.9688 36.8151C18.2324 36.8151 17.6354 37.4121 17.6354 38.1484C17.6354 38.8848 18.2324 39.4818 18.9688 39.4818ZM6.96875 20.3984L18.9688 20.3984V19.8984L6.96875 19.8984V20.3984ZM18.7188 20.1484L18.7188 38.1484H19.2188L19.2188 20.1484H18.7188Z"
        fill="url(#paint1_linear_4383_47223)" />
      <path
        d="M40.7461 7.33333C41.4825 7.33333 42.0794 6.73638 42.0794 6C42.0794 5.26362 41.4825 4.66667 40.7461 4.66667C40.0097 4.66667 39.4128 5.26362 39.4128 6C39.4128 6.73638 40.0097 7.33333 40.7461 7.33333ZM52.7461 6H52.9961V5.75H52.7461V6ZM40.7461 6.25L52.7461 6.25V5.75L40.7461 5.75V6.25ZM52.4961 6V24H52.9961V6H52.4961Z"
        fill="url(#paint2_linear_4383_47223)" />
      <path d="M24.7461 19H47.2461" stroke="white" stroke-width="1.5" stroke-linecap="round" />
      <path d="M24.7461 29H47.2461" stroke="white" stroke-width="1.5" stroke-linecap="round" />
      <path d="M24.7461 24H47.2461" stroke="white" stroke-width="1.5" stroke-linecap="round" />
      <path d="M24.7461 34H47.2461" stroke="white" stroke-width="1.5" stroke-linecap="round" />
      <path
        d="M34.9961 28.8197C34.9961 27.8808 35.6588 27.0724 36.5795 26.8883C38.7337 26.4574 40.8439 25.8301 42.8837 25.0142L43.8513 24.6272C44.4257 24.3974 45.0665 24.3974 45.6409 24.6272L46.6085 25.0142C48.6483 25.8301 50.7585 26.4574 52.9127 26.8883C53.8334 27.0724 54.4961 27.8808 54.4961 28.8197V32C54.4961 35.7483 52.9052 39.3205 50.1191 41.828L46.0434 45.4961C45.3059 46.1599 44.1863 46.1599 43.4488 45.4961L39.3731 41.828C36.587 39.3205 34.9961 35.7483 34.9961 32V28.8197Z"
        fill="#10776B" stroke="#1E1E1E" stroke-width="0.5" />
      <path d="M41.7461 34L43.7461 36L45.7461 34L47.7461 32" stroke="white" stroke-width="1.5" stroke-linecap="round" />
      <path d="M0.96875 42V49H67.9688V42" stroke="white" stroke-width="0.5" stroke-linecap="round" />
      <defs>
        <linearGradient id="paint0_linear_4383_47223" x1="31.7461" y1="1" x2="10.7461" y2="12"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint1_linear_4383_47223" x1="3.24609" y1="25.5" x2="18.7461" y2="41"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint2_linear_4383_47223" x1="40.7461" y1="6" x2="54.2461" y2="24"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
    <p class="guidelines_text">
      Please follow our community guidelines and content policy. We are committed to fighting misinformation and
      promoting responsible content creation. Make your work should be inspiring and thoughtful.
    </p>
    <p class="be_mad_text">Be a MadScientist🥸</p>
  </div>
</div>
<!-- </div> -->