import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportComponent } from 'src/app/popups/report/report.component';
import { AlertService } from 'src/app/services/alert.service';
import { GeneralService } from 'src/app/services/general.service';
import { WriteupService } from 'src/app/services/writeup.service';

@Component({
  selector: 'app-view-writeup',
  templateUrl: './view-writeup.component.html',
  styleUrls: ['./view-writeup.component.css']
})
export class ViewWriteupComponent implements OnInit{
  writeup_id:any;
  full_writeup: any = {};
  isHidden = false;
  mad_id: any;
  constructor(
    private route: ActivatedRoute,
    private _generalService: GeneralService,
    private _writeupService: WriteupService,
    private router: Router,
    private alertService: AlertService,
    public dialog: MatDialog,
  ) {    
  }

  ngOnInit(): void {
    this.mad_id = localStorage.getItem('mad_id');
    const hashWriteup = this.route.snapshot.paramMap.get('writeup_id');
    if(hashWriteup){
      this.writeup_id=atob(hashWriteup);
      this.getWriteup();
    }
  }
  onBack(): void {
    this.router.navigate(["home/writeups"])
  }
  onEdit(){
  }

  getWriteup() {
    this._writeupService
      .getWriteupbyId(this.writeup_id)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data) {
            if (data.response) {
              this.full_writeup = data.response[0];
              setTimeout(() => {
                this.addView();
              }, 5000);
            }
          }
        } else {
        }
      });
  }
  addView(){
    this._writeupService
      .addView(this.full_writeup.writeup_id)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data.status && data.status.code == '00') {
            // console.log('liked');
            // this.full_writeup.total_likes += 1;
            // this.full_writeup.is_liked = 1;
          }
        } else {
        }
      });

  }
  navigateToProfile(username: any) {
    let path = '/home/profile/' + username;
    this.router.navigate([path]);
    this.onBack();
  }
  getDownloadUrl(path: String) {
    if (path) {
      return (
        this._generalService.API_ENDPOINT +
        '/users/file/download?filename=' +
        path
      );
    } else {
      return '';
    }
  }
  save() {
    let pararm = {
      mad_id: this.mad_id,
      post_type: 'writeups',
      post_id: this.full_writeup.writeup_id,
    };
    this._generalService.save(pararm).subscribe((data: any) => {
      if (data) {
        console.log(data);
        if (data.status && data.status.code == '00') {
          console.log('saved');
          this.isHidden = false;
        }
      } else {
      }
    });
  }
  deleteWriteup() {
    this._writeupService
      .deleteWriteup(this.full_writeup.writeup_id)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data.status && data.status.code == '00') {
            console.log('deleted');
            this.full_writeup = {};
            this.onBack();
          }
        } else {
        }
      });
  }
  report() {
    const dialogRef = this.dialog.open(ReportComponent, {
      data: { post_type: 'writeups', post_id: this.full_writeup.writeup_id },
      width: 'min(100%, 33rem)',
      height: 'min(90%, 42rem)',
      backdropClass: 'bdrop',
      position: { bottom: '0px', left: '25%', right: '0px', top: '6.5%' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.isHidden = false;
      this.onBack();
    });
  }
  onLike() {
    this._writeupService
      .likeWriteup(this.full_writeup.writeup_id)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data.status && data.status.code == '00') {
            console.log('liked');
            this.full_writeup.total_likes += 1;
            this.full_writeup.is_liked = 1;
          }
        } else {
        }
      });
  }
  onUnLike() {
    // this._writeupService
    //   .unlikeWriteup(this.full_writeup.writeup_id)
    //   .subscribe((data: any) => {
    //     if (data) {
    //       console.log(data);
    //       if (data.status && data.status.code == '00') {
    //         console.log('liked');
    //         this.full_writeup.total_likes -= 1;
    //         this.full_writeup.is_liked = 0;
    //       }
    //     } else {
    //     }
    //   });
  }
  share() {
    let urlTxt=btoa("writeup|"+this.full_writeup.writeup_id);
    if (navigator.share) {
      navigator.share({
        title: 'MadScientist | Technology Network',
        text: this.full_writeup.title,
        url: window.location.origin+"/home/public/"+urlTxt,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    } else {
      this.fallbackShare(urlTxt);
    }
  }

  fallbackShare(urlTxt:any) {
    const url = window.location.origin+"/home/public/"+urlTxt;
    const textArea = document.createElement('textarea');
    textArea.value = url;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.alertService.showAlert("success","Link copied to Clipboard");
  }

}
