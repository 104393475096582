import { Component } from '@angular/core';
import {
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-new-chat',
  templateUrl: './new-chat.component.html',
  styleUrls: ['./new-chat.component.css']
})
export class NewChatComponent {
  search_text="";
  seachUserList:any=[]
  search_focus: boolean = false;
  toggleFlag: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<NewChatComponent>,
    private _generalService: GeneralService,
    private router: Router,
    private cookieService: CookieService

  ){
    localStorage.setItem("mad_id",this.cookieService.get('mad_id'));

  }
  ngOnInit(): void {
  }

  search(event: any) {
    const param = {
      "text": this.search_text,
      "userId":localStorage.getItem('mad_id')
    };
    this._generalService.userSearch(param)
      .subscribe((data: any) => {
        if (data) {
          this.seachUserList=data;
          console.log(this.search_text)
        } else {

        }
      }
      );
}

getDownloadUrl(path: String) {
  if (path) {
    return this._generalService.API_ENDPOINT + "/users/file/download?filename=" + path;
  } else {
    return "";
  }
}

  onBack(){
    this.dialogRef.close();
  }
  message(username:any){
    this.dialogRef.close();
    let path = "/home"; // Navigate to a different route
    this.router.navigateByUrl(path, { skipLocationChange: true }).then(() => {
      // Navigate back to the profile route
      path = "/home/messages/" + username;
      this.router.navigate([path]);
    });
  }
}
