import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectionService {
  public readonly API_ENDPOINT: string = 'https://mad-api.azurewebsites.net'; 
  
  constructor(private http: HttpClient ) {

  }

  fetchProjections(payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/projection/posts",payload,{ headers: headers });
  }
  fetchProjectionsByUser(payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/projection/getProjectionById",payload,{ headers: headers });
  }
  fetchSavedProjections(payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/save/list/projections",payload,{ headers: headers });
  }
  fetchSearchProjections(payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/search/projection",payload,{ headers: headers });
  }
  fetchSavedWriteups(payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/save/list/writeups",payload,{ headers: headers });
  }
  fetchSearchWriteups(payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/search/writeup",payload,{ headers: headers });
  }
  fetchSavedForums(payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/save/list/forums",payload,{ headers: headers });
  }
  fetchSearchForums(payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/search/forum",payload,{ headers: headers });
  }
  fetchSearchAll(payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('content-type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/search/all",payload,{ headers: headers });
  }

  private toUrlEncodedString(data: any): string {
    const urlSearchParams = new URLSearchParams();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        urlSearchParams.append(key, data[key]);
      }
    }
    return urlSearchParams.toString();
  }
  fetchReactions(projectionId:number,payload: any){
    let headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/projection/"+projectionId+"/reaction/",payload,{ headers: headers });
  }
  fetchComments(projectionId:number,payload: any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
    .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/projection/"+projectionId+"/getcomments/",payload,{ headers: headers });
  }
  fetchCommentReplies(payload: any){
    let headers = new HttpHeaders()
    .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/comments/comments-with-replies",payload,{ headers: headers });
  }
  deleteComment(payload:any){
    let projectionId=payload.projectionId;
    let commentId=payload.commentId;
    let headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Authorization',"Bearer " + localStorage.getItem("token"))
    // formData.append('reply_to','like');
    return this.http.delete(this.API_ENDPOINT+"/users/projection/"+projectionId+"/comments/"+commentId,{ headers: headers });  
  }
  likeProjection(projectionId:number){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    const formData = new FormData();
    formData.append('react_type','like');
    return this.http.post(this.API_ENDPOINT+"/users/projection/"+projectionId+"/reaction/react",formData,{ headers: headers });
  }
  unlikeProjection(projectionId:number){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.delete(this.API_ENDPOINT+"/users/projection/"+projectionId+"/reaction/unreact",{ headers: headers });
  }

  commentProjection(payload:any){
    console.log(localStorage.getItem("token")); 
    let projectionId=payload.projectionId;
    let headers = new HttpHeaders()
      .set('Content-Type','application/x-www-form-urlencoded')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    let body = new URLSearchParams();
    body.set('comment_by', payload.comment_by);
    body.set('comment', payload.comment);
    // formData.append('reply_to','like');
    return this.http.post(this.API_ENDPOINT+"/users/projection/"+projectionId+"/comments",body,{ headers: headers });  
  }
  commentReplyProjection(payload:any){
    console.log(localStorage.getItem("token")); 
    let projectionId=payload.projectionId;
    let commentId=payload.commentId;
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    const param={
      "comment_by":payload.comment_by,
      "comment":payload.comment
    }
    // formData.append('reply_to','like');
    return this.http.post(this.API_ENDPOINT+"/users/projection/"+projectionId+"/comments/"+commentId+"/reply",param,{ headers: headers });  
  }
  editCommentProjection(payload:any){
    console.log(localStorage.getItem("token")); 
    let projectionId=payload.projectionId;
    let commentId=payload.commentId;
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    const param={
      "comment_by":payload.comment_by,
      "comment":payload.comment
    }
    // formData.append('reply_to','like');
    return this.http.put(this.API_ENDPOINT+"/users/projection/"+projectionId+"/comments/"+commentId+"/edit/",param,{ headers: headers });  
  }
  deleteProjection(projectionId:number){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.delete(this.API_ENDPOINT+"/users/projection/delete/"+projectionId,{ headers: headers });
  }

}
