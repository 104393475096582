import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GeneralService } from 'src/app/services/general.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-projection',
  templateUrl: './add-projection.component.html',
  styleUrls: ['./add-projection.component.css']
})
export class AddProjectionComponent {
  @ViewChild('textarea') textarea!: ElementRef<HTMLTextAreaElement>;
  url:any = "";
  projection_caption:any="";
  file:any;
  filePath:any="";
  errorMsg="";
  discardFlag=false;
  submitFlag=false;
  successFlg=false;
  isLoading=false;

  constructor(private _userService:UserService,private _generalService:GeneralService,public dialogRef: MatDialogRef<AddProjectionComponent>,    private renderer: Renderer2 // Inject Renderer2
  ){
    
  }


  ngOnInit() {
    this.dialogRef.addPanelClass('add-projection-modal');
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.renderer.selectRootElement(this.textarea.nativeElement).focus();
    }, 100);
    this.textarea.nativeElement.addEventListener('blur', () => {
      this.textarea.nativeElement.focus(); 
    });
  }
  

  addPost(){
    if(this.projection_caption.trim()=="" && !this.file){
      this.errorMsg="Please select file/Enter the caption";
      return;
    }
    this.submitFlag=true;
    this.isLoading=true;
    if(this.file){
      this.uploadFile();
    }else{
      this.addProjection();
    }
  }
  onDiscardClick(){
    this.discardFlag=true;
  }
  uploadFile(){
    console.log(this.file);
    const formData = new FormData();
    formData.append('file', this.file);
    this._generalService.fileUpload(formData)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        if(data.Response){
          if(data.Response.file_path){
            this.filePath=data.Response.file_path;
            this.addProjection();
          }else{
            this.isLoading=false;
            this.successFlg=false;
            this.submitFlag=false;
          }
        }else{
          this.isLoading=false;
          this.successFlg=false;
          this.submitFlag=false;
        }
      }else{
        
      }
    }
    );
  }
  close(): void {
    this.dialogRef.close();
  }
  addProjection(){
    let projData = {
      created_by: localStorage.getItem("mad_id"), // temporarily use mad id
      shared_from_id: localStorage.getItem("mad_id"), // temporarily use mad id
      description: this.projection_caption.trim(),
      title: "",
      attachment_type: this.filePath.split(".").pop(),
      has_attachment: this.filePath?true:false,
      attachment_path: this.filePath,
      likes: 0,
      is_posted: true,
      requestType: "create",
  }
  this._userService.addPost(projData)
  .subscribe((data:any) => {
    if(data){
      console.log(data);
      if(data.status){
        if(data.status.code=="00"){
          this.successFlg=true;
          this.isLoading=false;
          console.log("Posted");
          this.close();
        }else{
          this.isLoading=false;
          this.successFlg=false;
          this.submitFlag=false;
        }
      }else{
        this.isLoading=false;
        this.successFlg=false;
        this.submitFlag=false;
      }
    }else{
      
    }
  }
  );

  }
  
  onSelectFile(event: any) {
    this.errorMsg="";
    console.log(event)
    if (event.target.files && event.target.files[0]) {
      this.file=event.target.files[0];
      if(!(this.file.type.includes("video") || this.file.type.includes("image") || this.file.type.includes("/pdf") || this.file.type.includes(".gltf") || this.file.type.includes(".glb") || this.file.name.includes(".gltf") || this.file.name.includes(".glb"))){
        this.errorMsg="Invalid File Type";
        this.file="";
        this.url="";
        return;
      }
      if(this.file.type.includes("video") && this.file.size>60000000){
          this.errorMsg="Video should be less than 60 MB";
          this.file="";
          this.url="";
          return;
      }
      else if(this.file.type.includes("image") && this.file.size>7000000){
        this.errorMsg="Image should be less than 7 MB";
        this.file="";
        this.url="";
        return;
      }
      else if(this.file.type.includes("/pdf") && this.file.size>12000000){
        this.errorMsg="PDF should be less than 12 MB";
        this.file="";
        this.url="";
        return;
      }
      else if((this.file.type.includes(".gltf") || this.file.type.includes(".glb") || this.file.name.includes(".gltf") || this.file.name.includes(".glb")) && this.file.size>80000000){
        this.errorMsg="3D file should be less than 80 MB";
        this.file="";
        this.url="";
        return;
      }else{
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event:any) => { // called once readAsDataURL is completed
          this.url = event.target.result;
        }
      }
        
    }
  }
  cancelUpload(){
    this.file="";
    this.url="";
  }

}
