<!-- <div class="report_box"> -->
<div class="report">
    <div class="report_header">
        <div class="report_header_text_box">
            <p>Report what's wrong with this?</p>
        </div>
        <button class="report_header_close_btn" (click)="onBack()">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18.257 20.2C18.6557 20.5987 19.3022 20.5987 19.7009 20.2C20.0997 19.8013 20.0997 19.1548 19.7009 18.7561L13.4444 12.4998L19.7005 6.24389C20.0992 5.84516 20.0992 5.19871 19.7005 4.79998C19.3018 4.40126 18.6553 4.40126 18.2566 4.79998L12.0005 11.0559L5.74345 4.79904C5.34471 4.40032 4.69823 4.40032 4.2995 4.79904C3.90076 5.19777 3.90076 5.84422 4.2995 6.24295L10.5565 12.4998L4.29905 18.7571C3.90032 19.1558 3.90032 19.8022 4.29905 20.201C4.69779 20.5997 5.34426 20.5997 5.743 20.201L12.0005 13.9437L18.257 20.2Z"
                    fill="white" />
            </svg>
        </button>
    </div>
    <div class="report_type_box no-scrollbar">
        <input type="checkbox" class="report_type_checkbox" name="report_1" id="report_1"
            [(ngModel)]="reportFlgList[0]">
        <label class="report_type" for="report_1">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#BABABA" />
                <path d="M4 8L6.22569 10.2257C6.6415 10.6415 7.32457 10.6105 7.70102 10.1588L12 5" stroke="#CCCCCC"
                    stroke-linecap="round" />
            </svg>
            <div class="report_type_text">
                <h2>Misinformation</h2>
                <p>Spreading fake information or wrong facts to mislead people.</p>
            </div>
        </label>
        <input type="checkbox" class="report_type_checkbox" name="report_2" id="report_2"
            [(ngModel)]="reportFlgList[1]">
        <label class="report_type" for="report_2">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#BABABA" />
                <path d="M4 8L6.22569 10.2257C6.6415 10.6415 7.32457 10.6105 7.70102 10.1588L12 5" stroke="#CCCCCC"
                    stroke-linecap="round" />
            </svg>
            <div class="report_type_text">
                <h2>IPR Infringement or Plagiarism</h2>
                <p>Using someone's work without permission and claiming it as one's own.</p>
            </div>
        </label>
        <input type="checkbox" class="report_type_checkbox" name="report_3" id="report_3"
            [(ngModel)]="reportFlgList[2]">
        <label class="report_type" for="report_3">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#BABABA" />
                <path d="M4 8L6.22569 10.2257C6.6415 10.6415 7.32457 10.6105 7.70102 10.1588L12 5" stroke="#CCCCCC"
                    stroke-linecap="round" />
            </svg>
            <div class="report_type_text">
                <h2>Adult Content (Nudity)</h2>
                <p>Sexual images or suggestive content.</p>
            </div>
        </label>
        <input type="checkbox" class="report_type_checkbox" name="report_4" id="report_4"
            [(ngModel)]="reportFlgList[3]">
        <label class="report_type" for="report_4">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#BABABA" />
                <path d="M4 8L6.22569 10.2257C6.6415 10.6415 7.32457 10.6105 7.70102 10.1588L12 5" stroke="#CCCCCC"
                    stroke-linecap="round" />
            </svg>
            <div class="report_type_text">
                <h2>Spam or Bots</h2>
                <p>These are not real people and may be misleading or provoke bad influence.</p>
            </div>
        </label>
        <input type="checkbox" class="report_type_checkbox" name="report_5" id="report_5"
            [(ngModel)]="reportFlgList[4]">
        <label class="report_type" for="report_5">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#BABABA" />
                <path d="M4 8L6.22569 10.2257C6.6415 10.6415 7.32457 10.6105 7.70102 10.1588L12 5" stroke="#CCCCCC"
                    stroke-linecap="round" />
            </svg>
            <div class="report_type_text">
                <h2>Irrelevant on MadScientist</h2>
                <p>Non-knowledge or non-information related topics such as sports, entertainment, excessive
                    professional talk, etc.</p>
            </div>
        </label>
        <input type="checkbox" class="report_type_checkbox" name="report_6" id="report_6"
            [(ngModel)]="reportFlgList[5]">
        <label class="report_type" for="report_6">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#BABABA" />
                <path d="M4 8L6.22569 10.2257C6.6415 10.6415 7.32457 10.6105 7.70102 10.1588L12 5" stroke="#CCCCCC"
                    stroke-linecap="round" />
            </svg>
            <div class="report_type_text">
                <h2>Hate Speech or Bullying</h2>
                <p>Attacking and threatening a person or group either verbally or in writing.</p>
            </div>
        </label>
        <input type="checkbox" class="report_type_checkbox" name="report_7" id="report_7"
            [(ngModel)]="reportFlgList[6]">
        <label class="report_type" for="report_7">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#BABABA" />
                <path d="M4 8L6.22569 10.2257C6.6415 10.6415 7.32457 10.6105 7.70102 10.1588L12 5" stroke="#CCCCCC"
                    stroke-linecap="round" />
            </svg>
            <div class="report_type_text">
                <h2>Sexual Abuse or Harassment (Children, Adult & Animal)</h2>
                <p>Sexually harassing or threatening someone with vulgarity.</p>
            </div>
        </label>
        <input type="checkbox" class="report_type_checkbox" name="report_8" id="report_8"
            [(ngModel)]="reportFlgList[7]">
        <label class="report_type" for="report_8">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#BABABA" />
                <path d="M4 8L6.22569 10.2257C6.6415 10.6415 7.32457 10.6105 7.70102 10.1588L12 5" stroke="#CCCCCC"
                    stroke-linecap="round" />
            </svg>
            <div class="report_type_text">
                <h2>Bad Image</h2>
                <p>Creating and producing statements against someone or others to create a bad image.</p>
            </div>
        </label>
        <input type="checkbox" class="report_type_checkbox" name="report_9" id="report_9"
            [(ngModel)]="reportFlgList[8]">
        <label class="report_type" for="report_9">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#BABABA" />
                <path d="M4 8L6.22569 10.2257C6.6415 10.6415 7.32457 10.6105 7.70102 10.1588L12 5" stroke="#CCCCCC"
                    stroke-linecap="round" />
            </svg>
            <div class="report_type_text">
                <h2>Illegal Fraud or Money Scams</h2>
                <p>Illegal manipulation or deception of people.</p>
            </div>
        </label>
        <input type="checkbox" class="report_type_checkbox" name="report_10" id="report_10"
            [(ngModel)]="reportFlgList[9]">
        <label class="report_type" for="report_10">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#BABABA" />
                <path d="M4 8L6.22569 10.2257C6.6415 10.6415 7.32457 10.6105 7.70102 10.1588L12 5" stroke="#CCCCCC"
                    stroke-linecap="round" />
            </svg>
            <div class="report_type_text">
                <h2>Spreading Violence or Terrorism</h2>
                <p>Evoking and inducing thoughts about raising violent and terrorism-based activities.</p>
            </div>
        </label>
        <input type="checkbox" class="report_type_checkbox" name="report_11" id="report_11"
            [(ngModel)]="reportFlgList[10]">
        <label class="report_type" for="report_11">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#BABABA" />
                <path d="M4 8L6.22569 10.2257C6.6415 10.6415 7.32457 10.6105 7.70102 10.1588L12 5" stroke="#CCCCCC"
                    stroke-linecap="round" />
            </svg>
            <div class="report_type_text">
                <h2>Others</h2>
                <p>Anything other than the above will be considered as "others."</p>
            </div>
        </label>
    </div>
    <div class="report_submit_btn_box">
        <button class="report_submit_btn" (click)="report()">
            <p>Report</p>
        </button>
    </div>
</div>
<!-- </div> -->