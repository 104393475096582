<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="
          margin: auto;
          background: rgba(0, 0, 0, 0);
          display: block;
          shape-rendering: auto;
          width: 25%;
        " viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
  <g transform="rotate(0 50 50)">
    <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9444444444444444s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(20 50 50)">
    <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8888888888888888s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(40 50 50)">
    <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(60 50 50)">
    <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.7777777777777778s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(80 50 50)">
    <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.7222222222222222s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(100 50 50)">
    <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(120 50 50)">
    <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6111111111111112s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(140 50 50)">
    <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5555555555555556s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(160 50 50)">
    <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite">
      </animate>
    </rect>
  </g>
  <g transform="rotate(180 50 50)">
    <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4444444444444444s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(200 50 50)">
    <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3888888888888889s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(220 50 50)">
    <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(240 50 50)">
    <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.2777777777777778s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(260 50 50)">
    <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.2222222222222222s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(280 50 50)">
    <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(300 50 50)">
    <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.1111111111111111s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(320 50 50)">
    <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.05555555555555555s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(340 50 50)">
    <rect x="49" y="26" rx="0.72" ry="0.72" width="2" height="12" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite">
      </animate>
    </rect>
  </g>
</svg>

<!-- <div class="loader-container">
        <div class="loader"></div>
      </div> -->