import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportComponent } from 'src/app/popups/report/report.component';
import { ForumService } from 'src/app/services/forum.service';
import { GeneralService } from 'src/app/services/general.service';
import { WriteupService } from 'src/app/services/writeup.service';

@Component({
  selector: 'app-public-view',
  templateUrl: './public-view.component.html',
  styleUrls: ['./public-view.component.css']
})
export class PublicViewComponent implements OnInit{
  post_type:any;
  post_id:any;
  proj:any;

  constructor(private route: ActivatedRoute,
    private _generalService: GeneralService,
    private _writeupService: WriteupService,
    private router: Router,
    private _forumService: ForumService,


  ){

  }
  ngOnInit(): void {
    this.mad_id = localStorage.getItem('mad_id');
    const hashText = this.route.snapshot.paramMap.get('hashText');
    if(hashText){
      let params=atob(hashText);
      let words: string[] = params.split("|");
      this.post_type=words[0];
      this.post_id=words[1]
      if(this.post_type=="projection"){
        this.fetchProjection();
      }else if(this.post_type=="writeup"){
        this.getWriteup();
      }else if(this.post_type=="forum"){
        this.fetchForum();
      }  
    }
  }
  fetchProjection(){
    this._generalService.fetchProjectionByProjId(this.post_id)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        if(data.response){
          if(data.response){
            this.proj=data.response[0];
          }
        }
      }else{
        
      }
    }
    );
  }
  writeup: any = {};
  full_writeup: any;
  isHidden = false;
  mad_id: any;
  onBack(): void {
    // this.dialogRef.close({"edit":false,"writeup":""});
  }
  onEdit(){
    // this.dialogRef.close({"edit":true,"writeup":this.full_writeup});
  }

  getWriteup() {
    this._writeupService
      .getWriteupbyId(this.post_id)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data) {
            if (data.response) {
              this.full_writeup = data.response[0];
            }
          }
        } else {
        }
      });
  }
  navigateToProfile(username: any) {
    let path = '/home/profile/' + username;
    this.router.navigate([path]);
    this.onBack();
  }
  getDownloadUrl(path: String) {
    if (path) {
      return (
        this._generalService.API_ENDPOINT +
        '/users/file/download?filename=' +
        path
      );
    } else {
      return '';
    }
  }
  save() {
    let pararm = {
      mad_id: this.mad_id,
      post_type: 'writeups',
      post_id: this.writeup.writeup_id,
    };
    this._generalService.save(pararm).subscribe((data: any) => {
      if (data) {
        console.log(data);
        if (data.status && data.status.code == '00') {
          console.log('saved');
          this.isHidden = false;
        }
      } else {
      }
    });
  }
  deleteWriteup() {
    this._writeupService
      .deleteWriteup(this.writeup.writeup_id)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data.status && data.status.code == '00') {
            console.log('deleted');
            this.writeup = {};
            this.onBack();
          }
        } else {
        }
      });
  }
  report() {
    // const dialogRef = this.dialog.open(ReportComponent, {
    //   data: { post_type: 'writeups', post_id: this.writeup.writeup_id },
    //   width: 'min(100%, 33rem)',
    //   height: 'min(90%, 42rem)',
    //   backdropClass: 'bdrop',
    //   position: { bottom: '0px', left: '25%', right: '0px', top: '6.5%' },
    // });

    // dialogRef.afterClosed().subscribe((result) => {
    //   console.log(`Dialog result: ${result}`);
    //   this.isHidden = false;
    //   this.onBack();
    // });
  }
  onLike() {
    this._writeupService
      .likeWriteup(this.writeup.writeup_id)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data.status && data.status.code == '00') {
            console.log('liked');
            this.writeup.total_likes += 1;
            this.writeup.is_liked = 1;
          }
        } else {
        }
      });
  }
  onUnLike() {
    this._writeupService
      .unlikeWriteup(this.writeup.writeup_id)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data.status && data.status.code == '00') {
            console.log('liked');
            this.writeup.total_likes -= 1;
            this.writeup.is_liked = 0;
          }
        } else {
        }
      });
  }

  forum: any;
  answersList: any = [];
  answer = '';
  @ViewChild('commentF') commentField!: ElementRef;

  fetchForum(){
    this._generalService.fetchForumByProjId(this.post_id)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        if(data.response){
          if(data.response){
            this.forum=data.response[0];
            this.getAnswers();
          }
        }
      }else{
        
      }
    }
    );
  }

  focusAns(){
    this.commentField.nativeElement.focus();
  }
  getAnswers() {
    const param = {
      range: 10,
      offset: 0,
    };
    this._forumService
      .getForumAnswers(this.forum.forum_id, param)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data) {
            if (data.success == false) {
              this.onBack();
            }
          }
          if (data.response) {
            if (data.response.list) {
              this.answersList = data.response.list;
              this.forum.total_comments = data.response.totalRecords;
            }
          }
        } else {
        }
      });
  }

  addAnswer() {
    console.log('clicked');
    let param = {
      comment_by: localStorage.getItem('mad_id'),
      comment: this.answer,
      forum_id: this.forum.forum_id,
    };
    this._forumService.addForumAnswer(param).subscribe((data: any) => {
      if (data) {
        console.log(data);
        if (data.status && data.status.code == '00') {
          console.log('answered');
          this.answer = '';
          this.answersList.unshift(data.response);
          this.forum.total_comments+=1;
        }
      } else {
      }
    });
  }
  // onEdit(){
  //   this.dialogRef.close("edit");
  // }
  // save() {
  //   let pararm = {
  //     mad_id: this.mad_id,
  //     post_type: 'writeups',
  //     post_id: this.forum.forum_id,
  //   };
  //   this._generalService.save(pararm).subscribe((data: any) => {
  //     if (data) {
  //       console.log(data);
  //       if (data.status && data.status.code == '00') {
  //         console.log('saved');
  //         this.isHidden = false;
  //       }
  //     } else {
  //     }
  //   });
  // }
  // deleteWriteup() {
  //   this._forumService
  //     .deleteForum(this.forum.forum_id)
  //     .subscribe((data: any) => {
  //       if (data) {
  //         console.log(data);
  //         if (data.status && data.status.code == '00') {
  //           console.log('deleted');
  //           this.forum = {};
  //           this.onBack();
  //         }
  //       } else {
  //       }
  //     });
  // }
  // onLike() {
  //   this._forumService.likeForum(this.forum.forum_id).subscribe((data: any) => {
  //     if (data) {
  //       console.log(data);
  //       if (data.status && data.status.code == '00') {
  //         console.log('liked');
  //         this.forum.total_likes += 1;
  //         this.forum.is_liked = 1;
  //       }
  //     } else {
  //     }
  //   });
  // }
  // onUnLike() {
  //   this._forumService
  //     .unlikeForum(this.forum.forum_id)
  //     .subscribe((data: any) => {
  //       if (data) {
  //         console.log(data);
  //         if (data.status && data.status.code == '00') {
  //           console.log('liked');
  //           this.forum.total_likes -= 1;
  //           this.forum.is_liked = 0;
  //         }
  //       } else {
  //       }
  //     });
  // }
  // report() {
  //   const dialogRef = this.dialog.open(ReportComponent, {
  //     data: { post_type: 'forums', post_id: this.forum.forum_id },
  //     width: 'min(100%, 33rem)',
  //     height: 'min(90%, 42rem)',
  //     backdropClass: 'bdrop',
  //     position: { bottom: '0px', left: '25%', right: '0px', top: '6.5%' },
  //   });

  //   dialogRef.afterClosed().subscribe((result) => {
  //     console.log(`Dialog result: ${result}`);
  //     this.isHidden = false;
  //     this.onBack();
  //   });
  // }
}
