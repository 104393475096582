import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-crop-image',
  templateUrl: './crop-image.component.html',
  styleUrls: ['./crop-image.component.css']
})
export class CropImageComponent {

  file:any;
  imageChangedEvent: any = '';
  croppedImage: any = null;
  croppedFile: File | null = null;

  onImageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  constructor(public dialogRef: MatDialogRef<CropImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){
      this.imageChangedEvent=data;
  }
  crop(){
    const fileName = this.imageChangedEvent.target.files[0].name; // Use original file name
    this.croppedFile = this.base64ToFile(this.croppedImage, fileName);
    this.dialogRef.close(this.croppedFile);
  }
  private base64ToFile(base64: string | null, fileName: string): File {
    if (!base64) {
      throw new Error('Base64 string is null or undefined');
    }
  
    const base64Header = base64.split(',')[0];
    const match = base64Header.match(/:(.*?);/);
    const mimeType = match?.[1] ?? 'application/octet-stream'; // Default to a generic binary MIME type
  
    const byteString = atob(base64.split(',')[1] ?? '');
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
  
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
  
    const blob = new Blob([uint8Array], { type: mimeType });
    return new File([blob], fileName, { type: mimeType });
  }
  
}

