import { Component, ElementRef, ViewChild } from '@angular/core';
import { AlertService } from './services/alert.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Mad-UI';
  auth2: any;

  constructor(public alertService: AlertService,) { }
  ngOnInit() {

  }
}
