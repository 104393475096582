import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ForumService {

  public readonly API_ENDPOINT: string = 'https://mad-api.azurewebsites.net'; 
  constructor(private http: HttpClient ) {
    
  }
  createForum(payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('Content-Type','application/x-www-form-urlencoded')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    let body = new URLSearchParams();
    body.set('title', payload.title);
    body.set('your_view', payload.your_view);
    body.set('created_by', payload.created_by);
    body.set('requestType', "create");
    return this.http.post(this.API_ENDPOINT+"/users/forums",body,{ headers: headers });  
  }
  editForum(payload:any){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.put(this.API_ENDPOINT+"/users/forums/edit",payload,{ headers: headers });  
  }
  getForums(payload:any){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/forums/get",payload,{ headers: headers });  
  }
  getForumsByUser(payload:any){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/forums/getForumById",payload,{ headers: headers });  
  }
  addForumAnswer(payload:any){
    let forum_id=payload.forum_id;
    let headers = new HttpHeaders()
      .set('Content-Type','application/x-www-form-urlencoded')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    let body = new URLSearchParams();
    body.set('comment_by', payload.comment_by);
    body.set('comment', payload.comment);
    return this.http.post(this.API_ENDPOINT+"/users/forums/"+forum_id+"/comments/",body,{ headers: headers }); 
  }

  getForumAnswers(forum_id:number,payload:any){
    let headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.post(this.API_ENDPOINT+"/users/forums/"+forum_id+"/getcomments/",payload,{ headers: headers });  
  }
  deleteForum(forumId:number){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.delete(this.API_ENDPOINT+"/users/forums/delete/"+forumId,{ headers: headers });
  }
  likeForum(forumId:number){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    const formData = new FormData();
    formData.append('react_type','like');
    return this.http.post(this.API_ENDPOINT+"/users/forums/"+forumId+"/reaction/react",formData,{ headers: headers });
  }
  unlikeForum(forumId:number){
    console.log(localStorage.getItem("token")); 
    let headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization',"Bearer " + localStorage.getItem("token"))
    return this.http.delete(this.API_ENDPOINT+"/users/forums/"+forumId+"/reaction/unreact",{ headers: headers });
  }


}
