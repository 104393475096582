<div class="feed" (scroll)="scrollHandler($event)">
  <div class="forumsWrappper" style="width: 100%;">
    <div class="forums_header_box">
      <!-- <p class="forums_header">Participate in forum discussions by sharing thoughts, raising doubts and asking
        questions to the community.</p>
    </div> -->
    <p class="feedHeaderTitle">
      Hello, {{mad_data?.first_name}} {{mad_data?.last_name}}! <br>Your personalized timeline begins here.
    </p>
    <div class="forums">
      <app-loader *ngIf="loadingFlg"></app-loader>
      <app-forum [forum]="forum" *ngFor="let forum of forumList"></app-forum>
    </div>
  </div>
</div>