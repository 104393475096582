import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { PostComponent } from './components/post/post.component';
import { FeedComponent } from './pages/feed/feed.component';
import { ProjectionsComponent } from './components/projections/projections.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { CommunityComponent } from './pages/community/community.component';
import { CommunityBoxComponent } from './pages/community-box/community-box.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { WriteupsComponent } from './pages/writeups/writeups.component';
import {MatDialogModule} from '@angular/material/dialog'; 
import {MatButtonModule} from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ForumsComponent } from './pages/forums/forums.component';
import { HelpComponent } from './pages/help/help.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { CreateWriteupComponent } from './pages/create-writeup/create-writeup.component';
import { ForumViewComponent } from './popups/forum-view/forum-view.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { WriteupComponent } from './components/writeup/writeup.component';
import { ForumComponent } from './components/forum/forum.component';
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import { SettingsComponent } from './pages/settings/settings.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { NotificationsSettingComponent } from './components/notifications-setting/notifications-setting.component';
import { AccountSettingComponent } from './components/account-setting/account-setting.component';
import { CreatePostComponent } from './popups/create-post/create-post.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AddProjectionComponent } from './popups/add-projection/add-projection.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { ProjectionViewComponent } from './popups/projection-view/projection-view.component';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TimeAgoPipe } from './pipes/timeago.pipe';
import { QuillModule } from 'ngx-quill';
import { AddWriteupComponent } from './popups/add-writeup/add-writeup.component';
import { DisplaysizePipe } from './pipes/displaysize.pipe';
import { AddForumComponent } from './popups/add-forum/add-forum.component';
import { ResponseInterceptorService } from './services/response-interceptor.service';
import { ReportComponent } from './popups/report/report.component';
import { InviteComponent } from './pages/invite/invite.component';
import { WriteupViewComponent } from './popups/writeup-view/writeup-view.component';
import { FeedActionsComponent } from './popups/feed-actions/feed-actions.component';
import { SocialCountPipe } from './pipes/social-count.pipe';
import { ViewProfilePhotoComponent } from './popups/view-profile-photo/view-profile-photo.component';
import { DeactivateComponent } from './popups/deactivate/deactivate.component';
import { ProfileReportComponent } from './popups/profile-report/profile-report.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { LoaderComponent } from './components/loader/loader.component';
import { EditProjectionComponent } from './popups/edit-projection/edit-projection.component';
import { SearchComponent } from './pages/search/search.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { GoogleLoginProvider, SocialAuthServiceConfig, GoogleSigninButtonDirective, GoogleSigninButtonModule, SocialLoginModule } from '@abacritt/angularx-social-login';
import { PublicViewComponent } from './pages/public-view/public-view.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ViewWriteupComponent } from './pages/view-writeup/view-writeup.component';

const materialModules = [
  MatButtonModule,
  MatDialogModule
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PostComponent,
    FeedComponent,
    ProjectionsComponent,
    NavBarComponent,
    CommunityComponent,
    CommunityBoxComponent,
    NotificationsComponent,
    WriteupsComponent,
    ForumsComponent,
    HelpComponent,
    MessagesComponent,
    CreateWriteupComponent,
    ProfileComponent,
    WriteupComponent,
    ForumComponent,
    SettingsComponent,
    EditProfileComponent,
    NotificationsSettingComponent,
    AccountSettingComponent,
    CreatePostComponent,
    AddProjectionComponent,
    SignInComponent,
    SignUpComponent,
    ProjectionViewComponent,
    TimeAgoPipe,
    AddWriteupComponent,
    DisplaysizePipe,
    AddForumComponent,
    ForumViewComponent,
    ReportComponent,
    InviteComponent,
    WriteupViewComponent,
    FeedActionsComponent,
    SocialCountPipe,
    ViewProfilePhotoComponent,
    DeactivateComponent,
    ProfileReportComponent,
    LandingPageComponent,
    LoaderComponent,
    EditProjectionComponent,
    SearchComponent,
    ResetPasswordComponent,
    PublicViewComponent,
    ViewWriteupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatDialogModule, 
    MatButtonModule, 
    BrowserAnimationsModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    PdfViewerModule,
    FormsModule,
    HttpClientModule,
    QuillModule,
    SocialLoginModule,
    GoogleSigninButtonModule,
    ClipboardModule,
    MatSnackBarModule,
  ],
  exports: [
    ...materialModules
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptorService,
      multi: true
    },
    {

      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '632595482143-m78e3krq1mmk3a23f9029kpjv3v9qn2a.apps.googleusercontent.com'
            ),
          },
        ],
        onError: (err: unknown) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    GoogleSigninButtonDirective,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
