import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private alertSubject = new Subject<{ flag: string, message: string }>();

  // Observable alert stream
  alert$ = this.alertSubject.asObservable();

  // Method to trigger an alert
  showAlert(flag: string, message: string) {
    this.alertSubject.next({ flag, message });
  }
}
