import { Component, OnInit } from '@angular/core';
import { CommunityService } from 'src/app/services/community.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.css']
})
export class CommunityComponent implements OnInit {
  current_tab = "community";
  communityList = [];
  page = 1;
  fetchingData = false;
  communityData: any = {};
  helperText = '';

  constructor(private _generalService: GeneralService, private _communityService: CommunityService) { }

  ngOnInit(): void {
    this.changeTab('community', true);
    this.getCommunityData();
  }

  getCommunityData() {
    this._communityService.GetCommunityData()
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          this.communityData = data;
        }
      });
  }

  onFollowEvent(follow_type: any) {
    if (follow_type == "follow") {
      this.communityData.following += 1;
    }
    if (follow_type == "unfollow") {
      this.communityData.following -= 1;
    }
  }

  onScrollEvt() {
    this.changeTab(this.current_tab, true);
  }

  onExploreEvent() {
    this.changeTab('community', false);
  }

  changeTab(tab: any, scFlg: boolean) {
    this.fetchingData = true;
    this.page = scFlg ? this.page : 1;
    this.current_tab = tab;

    // Set helper text based on current tab
    switch (tab) {
      case 'community':
        this.helperText = 'Your personalized interests matched recommendations';
        break;
      case 'followers':
        this.helperText = 'See who follows you and interact with them';
        break;
      case 'following':
        this.helperText = 'Check out the people you’re following';
        break;
      default:
        this.helperText = '';
    }

    if (tab === "followers") {
      this.fetchFollowers(scFlg);
    } else if (tab === "following") {
      this.fetchFollowing(scFlg);
    } else {
      this.fetchCommunitySuggestions(scFlg);
    }
  }

  fetchFollowers(scFlg: boolean) {
    this._communityService.getFollowers(this.page)
      .subscribe((data: any) => {
        if (data?.response) {
          this.communityList = scFlg ? this.communityList.concat(data.response) : data.response;
          this.page += 1;
        }
        this.fetchingData = false;
      });
  }

  fetchFollowing(scFlg: boolean) {
    this._communityService.getFollowing(this.page)
      .subscribe((data: any) => {
        if (data?.response) {
          this.communityList = scFlg ? this.communityList.concat(data.response) : data.response;
          this.page += 1;
        }
        this.fetchingData = false;
      });
  }

  fetchCommunitySuggestions(scFlg: boolean) {
    this._communityService.getCommunitySuggestions(this.page)
      .subscribe((data: any) => {
        if (data?.response) {
          this.communityList = scFlg ? this.communityList.concat(data.response) : data.response;
          this.page += 1;
        }
        this.fetchingData = false;
      });
  }
}