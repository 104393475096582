import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddForumComponent } from 'src/app/popups/add-forum/add-forum.component';
import { AddWriteupComponent } from 'src/app/popups/add-writeup/add-writeup.component';
import { ForumViewComponent } from 'src/app/popups/forum-view/forum-view.component';
import { ReportComponent } from 'src/app/popups/report/report.component';
import { AlertService } from 'src/app/services/alert.service';
import { ForumService } from 'src/app/services/forum.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForumComponent {
  @Input() forum: any = {};
  answer = '';
  mad_id: any;
  isHidden = false;

  constructor(
    public dialog: MatDialog,
    private _generalService: GeneralService,
    private _forumService: ForumService,
    private router: Router,
    private alertService: AlertService
  ) {
    this.mad_id = localStorage.getItem('mad_id');
  }

  getDownloadUrl(path: String) {
    if (path) {
      return (
        this._generalService.API_ENDPOINT +
        '/users/file/download?filename=' +
        path
      );
    } else {
      return '';
    }
  }
  onLike() {
    this._forumService.likeForum(this.forum.forum_id).subscribe((data: any) => {
      if (data) {
        console.log(data);
        if (data.status && data.status.code == '00') {
          console.log('liked');
          this.forum.total_likes += 1;
          this.forum.is_liked = 1;
        }
      } else {
      }
    });
  }
  onUnLike() {
    // this._forumService
    //   .unlikeForum(this.forum.forum_id)
    //   .subscribe((data: any) => {
    //     if (data) {
    //       console.log(data);
    //       if (data.status && data.status.code == '00') {
    //         console.log('liked');
    //         this.forum.total_likes -= 1;
    //         this.forum.is_liked = 0;
    //       }
    //     } else {
    //     }
    //   });
  }
  navigateToProfile(username: any) {
    let path = '/home/profile/' + username;
    this.router.navigate([path]);
  }
  addAnswer() {
    console.log('clicked');
    let param = {
      comment_by: localStorage.getItem('mad_id'),
      comment: this.answer,
      forum_id: this.forum.forum_id,
    };
    this._forumService.addForumAnswer(param).subscribe((data: any) => {
      if (data) {
        console.log(data);
        if (data.status && data.status.code == '00') {
          console.log('answered');
          this.answer = '';
        }
      } else {
      }
    });
  }
  openDialog() {
    const dialogRef = this.dialog.open(ForumViewComponent, {
      data: this.forum,
      width: '100%',
      height: 'auto',
      backdropClass: 'bdrop',
      position: { bottom: '0px', left: '10%', right: '0px', top: '0.8%' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if(result=="edit"){
        this.onEdit();
      }
    });
  }
  save() {
    let pararm = {
      mad_id: this.mad_id,
      post_type: 'forums',
      post_id: this.forum.forum_id,
    };
    this._generalService.save(pararm).subscribe((data: any) => {
      if (data) {
        console.log(data);
        if (data.status && data.status.code == '00') {
          console.log('saved');
          this.isHidden = false;
        }
      } else {
      }
    });
  }
  onEdit(){
    const dialogRefIn = this.dialog.open(AddForumComponent, {
      data: {
        type: 'edit',
        forum:this.forum
      },
      width: '60%',
      height: '60%',
      backdropClass: "bdrop",
      position: {
        'bottom': '0px',
        'left': '20%',
        'right': '0px',
        'top': '10%'
      }
    }
    );
  }
  deleteWriteup() {
    this._forumService
      .deleteForum(this.forum.forum_id)
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          if (data.status && data.status.code == '00') {
            console.log('deleted');
            this.forum = {
              forum_id: 0,
              title: '',
              your_view: '',
              created_by: 0,
              hashtags: null,
              created_on: '',
              forum_likes: null,
              total_comments: 0,
              total_likes: 0,
              mad_id: 0,
              user_name: '',
              first_name: '',
              last_name: '',
              tagline: '',
              profile_photo_path: '',
            };
            this.isHidden = false;
          }
        } else {
        }
      });
  }
  report() {
    const dialogRef = this.dialog.open(ReportComponent, {
      data: { post_type: 'forums', post_id: this.forum.forum_id },
      width: 'min(100%, 33rem)',
      height: 'min(90%, 42rem)',
      backdropClass: 'bdrop',
      position: { bottom: '0px', left: '25%', right: '0px', top: '6.5%' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  share() {
    let urlTxt=btoa("forum|"+this.forum.forum_id);
    if (navigator.share) {
      navigator.share({
        title: 'MadScientist | Technology Network',
        text: this.forum.title,
        url: window.location.origin+"/home/public/"+urlTxt,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    } else {
      this.fallbackShare(urlTxt);
    }
  }

  fallbackShare(urlTxt:any) {
    const url = window.location.origin+"/home/public/"+urlTxt;
    const textArea = document.createElement('textarea');
    textArea.value = url;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.alertService.showAlert("success","Link copied to Clipboard");
  }
}
