import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit{
  mobile_popup=false;
  view="home";
  shop_view=false;
  subtitles: string[] = ["XR", "Space Tech", "Robotics","AI"];
  currentSubtitleIndex: number = 0;
  currentSubtitle: string = this.subtitles[0];
  animation='fadeInUp-animation2';
  constructor(private router: Router,
    private cookieService: CookieService
  ){
    if(this.cookieService.get('token')){
      localStorage.setItem("token",this.cookieService.get('token'));
      localStorage.setItem("mad_user",this.cookieService.get('mad_user'));
      localStorage.setItem("mad_id",this.cookieService.get('mad_id')); 
      this.router.navigate(['home']); 
    }
  }
  ngOnInit(): void {
    this.changeSubtitle();
    setInterval(() => this.changeSubtitle(), 2000);
  }
  changeSubtitle(): void {
    this.animation='fadeInUp-animation3';
    this.currentSubtitle = this.subtitles[this.currentSubtitleIndex];
    this.currentSubtitleIndex = (this.currentSubtitleIndex + 1) % this.subtitles.length;
    this.animation='fadeInUp-animation2';
  }

  changeTab(tab:any){
    this.view=tab;
    this.shop_view=false;
    this.mobile_popup=false;

  }
  navigateTo(path:string){
    this.router.navigate([path])

  }

}
