import { Component } from '@angular/core';

@Component({
  selector: 'app-create-writeup',
  templateUrl: './create-writeup.component.html',
  styleUrls: ['./create-writeup.component.css']
})
export class CreateWriteupComponent {

}
