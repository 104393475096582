import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class ResponseInterceptorService implements HttpInterceptor {
  constructor(private router: Router,
    private cookieService: CookieService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // Handle HTTP errors globally
        this.handleErrorResponse(error);
        return throwError(error);
      })
    );
  }

  private handleErrorResponse(error: HttpErrorResponse): void {
    // Handle response codes globally
    console.error(`HTTP Error: ${error.status}`);
    // Add your code to handle specific response codes here
    if(error.status==401 && error.url!="https://mad-api.azurewebsites.net/users/notification/count"){
      localStorage.clear();
      this.router.navigate(["signin"]);
      this.cookieService.delete('token');
      this.cookieService.delete('mad_user');
      this.cookieService.delete('mad_id');

    }
  }
}
