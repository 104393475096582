import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DeactivateComponent } from 'src/app/popups/deactivate/deactivate.component';
import { GeneralService } from 'src/app/services/general.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-account-setting',
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountSettingComponent {
  @Input() userData:any={};
  old_password="";
  new_password="";
  validations={
    "secondary_mail":false,
    "primary_mobile":false,
    "new_password":false,
    "old_password":false
  }
  constructor(private _generalService:GeneralService, 
    private _userService:UserService,   
    public dialog: MatDialog,
    private router: Router,
    private cookieService: CookieService
    ){  
  }
  addPlusSign(inputElement: any) {
    const userInput = inputElement.value;
    const sanitizedInput = userInput.replace(/[^0-9]/g, ''); // Remove non-numeric characters

    if (sanitizedInput !== '') {
      if (sanitizedInput.length <= 3) inputElement.value = '+' + sanitizedInput;
      else inputElement.value = '+' + sanitizedInput.slice(0, 3);
    } else inputElement.value = '';
  }
  validateEmail(){
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(emailRegex.test(this.userData.secondary_mail)){
      this.validations.secondary_mail=false;
      return true;
    }else{
      this.validations.secondary_mail=true;
      return false;
    }
  }
  validateMobile(){
    const mobileRegex = /^\+?[0-9]{1,3}[\s-]?[0-9]{3,14}$/;
    if(mobileRegex.test(this.userData.primary_mobile)){
      this.validations.primary_mobile=false;
      return true;
    }else{
      this.validations.primary_mobile=true;
      return false;
    }
  }
  validatePassword(){
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{6,}$/;
    if(passwordRegex.test(this.new_password)){
      this.validations.new_password=false;
      return true;
    }else{
      this.validations.new_password=true;
      return false;
    }
  }

  verifyPhone(inputElement: any) {
    const userInput = inputElement.value;
    const sanitizedInput = userInput.replace(/[^0-9]/g, '');

    if (sanitizedInput !== '') {
      if (sanitizedInput.length <= 10) {
        let formattedInput = sanitizedInput.slice(0, 5);
        if (sanitizedInput.length > 5)
          formattedInput += '' + sanitizedInput.slice(5);
        inputElement.value = formattedInput;
      } else {
        inputElement.value =
          sanitizedInput.slice(0, 5) + '' + sanitizedInput.slice(5, 10);
      }
    } else {
      inputElement.value = '';
    }
  }

  togglePasswordType(inputElement: any) {
    if (inputElement.getAttribute('type') === 'password')
      inputElement.setAttribute('type', 'text');
    else inputElement.setAttribute('type', 'password');
  }
  deactivate(){
    const dialogRef = this.dialog.open(DeactivateComponent,{
      data: this.userData,
      width: '40%',
      height:'73%',
      backdropClass: "bdrop",
      position:{'bottom': '0px',
      'left': '35%',
      'right': '0px',
      'top': '6.5%'}
    }
    );
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result=='help'){
        this.router.navigate(["/home/help"]);
      }
      if(result=='deactivated'){
        localStorage.clear();
        this.cookieService.delete('token');
        this.cookieService.delete('mad_user');
        this.cookieService.delete('mad_id');
        this.router.navigate(["signin"]);
      }
    });
  }
  editProfile() {
    if(!(this.validateEmail() && this.validateMobile())){
      return;
    }
    const param={
      "secondary_mail":this.userData.secondary_mail,
      "primary_mobile":this.userData.primary_mobile  
  };
    this._userService.editProfile(param)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        if(data.status){
          if(data.status.code=="00"){
            console.log("interests added");
          }
        }
      }else{
        
      }
    }
    );
    return;
  }
  changePassword(){
    if(!this.validatePassword()){
      return;
    }
    if(this.new_password){
      const param={
        "mad_id":this.userData.mad_id,
        "oldpassword":this.old_password,
        "newpassword":this.new_password
    };
      this._userService.passwordChange(param)
      .subscribe((data:any) => {
        if(data){
          console.log(data);
          if(data.response){
            this.validations.old_password=false;
          }else{
            this.validations.old_password=true;
          }
          if(data.status){
            if(data.status.code=="00"){
              console.log("password added");
            }
          }
        }else{
          
        }
      }
      );  
    }
    return;
  }
}
