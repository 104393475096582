import { Component } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent {
  category="";
  description="";
  file:any;
  filePath:any="";
  url:any = "";
  errorMsg="";


  constructor(private _generalService: GeneralService){

  }
  uploadFile(){
    console.log(this.file);
    const formData = new FormData();
    formData.append('file', this.file);
    this._generalService.fileUpload(formData)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        if(data.Response){
          if(data.Response.file_path){
            this.filePath=data.Response.file_path;
            this.addHelp();
          }
        }
      }else{
        
      }
    }
    );
  }
  addHelp(){
    let projData = 
    {
      "mad_id":localStorage.getItem("mad_id"),
      "email":"",
      "category":this.category,
      "description":this.description,
      attachment_type: this.filePath.split(".").pop(),
      has_attachment: this.filePath?true:false,
      attachment_path: this.filePath,
  };
  this._generalService.addHelp(projData)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        if(data.status){
          if(data.status.code=="00"){
            console.log("Posted");
          }
        }
      }else{
        
      }
    }
    );
  }
  onSelectFile(event: any) {
    this.errorMsg="";
    console.log(event)
    if (event.target.files && event.target.files[0]) {
      this.file=event.target.files[0];
      if(this.file.size>59000000){
        this.errorMsg="File size should be less than 10 Bytes";
        this.file="";
        this.url="";
      }else{
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event:any) => { // called once readAsDataURL is completed
          this.url = event.target.result;
        }  
      }
    }
  }
  cancelUpload(){
    this.file="";
    this.url="";
  }

  save(){
    if(this.file){
      this.uploadFile();
    }else{
      this.addHelp();
    }
  }

}
