<body
  data-bs-spy="scroll"
  data-bs-target="#site-navbar-target"
  data-bs-offset="100"
  class="bg-light"
>
  <nav
    class="site-nav js-site-navbar site-navbar-target"
    id="site-navbar-target"
  >
    <div class="container site-navigation">
      <div class="row-alt">
        <div class="col-6 col-md-2 col-lg-2 logo-box">
          <a
          (click)="gotoHome()"
            class="logo m-0 float-start img-fluid"
            style="
              margin: 0;
              float: left;
              max-width: 100%;
              height: 20px;
              width: 149px;
              margin-top: 1px;
            "
            ><img loading="lazy" src="assets/images/logo.svg" alt="Logo"
          /></a>
        </div>
        <div class="col-md-8 d-none d-md-block col-lg-4 text-center"></div>
        <div class="col-6 col-md-2 col-lg-6">
          <ul
            style="margin: 0; padding: 0; text-align: right; float: right"
            class="js-clone-nav d-none d-lg-inline-block text-end float-end site-menu p-0 m-0"
          >
            <li
              style="
                padding-right: 5px;
                font-family: 'Exo 2', sans-serif;
                color: white;
              "
            >
              Don't have an account?
            </li>
            <li>
              <a
                (click)="signup()"
                class="signupbutton"
                style="
                  font-family: 'Exo 2', sans-serif;
                  color: #0b0b0b;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 93.5%;
                "
                >Sign up
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                >
                  <path
                    d="M0.984389 9.21276C0.691495 9.50566 0.691495 9.98053 0.984389 10.2734C1.27728 10.5663 1.75216 10.5663 2.04505 10.2734L0.984389 9.21276ZM10.75 1.25781C10.75 0.843599 10.4142 0.507813 10 0.507813L3.25 0.507812C2.83579 0.507812 2.5 0.843599 2.5 1.25781C2.5 1.67203 2.83579 2.00781 3.25 2.00781L9.25 2.00781L9.25 8.00781C9.25 8.42203 9.58579 8.75781 10 8.75781C10.4142 8.75781 10.75 8.42203 10.75 8.00781L10.75 1.25781ZM2.04505 10.2734L10.5303 1.78814L9.46967 0.727482L0.984389 9.21276L2.04505 10.2734Z"
                    fill="#10776B"
                  /></svg
              ></a>
            </li>
          </ul>
          <a
            (click)="signup()"
            class="signupbutton d-lg-none"
            style="
              font-family: 'Exo 2', sans-serif;
              color: #0b0b0b;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 93.5%;
              float: right;
              width: fit-content;
            "
            >Sign up
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
            >
              <path
                d="M0.984389 9.21276C0.691495 9.50566 0.691495 9.98053 0.984389 10.2734C1.27728 10.5663 1.75216 10.5663 2.04505 10.2734L0.984389 9.21276ZM10.75 1.25781C10.75 0.843599 10.4142 0.507813 10 0.507813L3.25 0.507812C2.83579 0.507812 2.5 0.843599 2.5 1.25781C2.5 1.67203 2.83579 2.00781 3.25 2.00781L9.25 2.00781L9.25 8.00781C9.25 8.42203 9.58579 8.75781 10 8.75781C10.4142 8.75781 10.75 8.42203 10.75 8.00781L10.75 1.25781ZM2.04505 10.2734L10.5303 1.78814L9.46967 0.727482L0.984389 9.21276L2.04505 10.2734Z"
                fill="#10776B"
              /></svg
          ></a>
        </div>
      </div>
    </div>
  </nav>
  <div class="hero bg-dark" id="home-section" style="z-index: 500">
    <div *ngIf="alert" class="alert">
      <!-- Error Icon SVG -->
      <svg
        *ngIf="alert.flag == 'failure'"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="18" height="18" rx="9" fill="#FF0000" />
        <path d="M6 6L12 12" stroke="white" stroke-linecap="round" />
        <path d="M6 12L12 6" stroke="white" stroke-linecap="round" />
      </svg>
      <!-- Success Icon SVG -->
      <svg
        *ngIf="alert.flag == 'success'"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="18" height="18" rx="9" fill="#10776B" />
        <path d="M6 9L8 11L12 7" stroke="white" stroke-linecap="round" />
      </svg>
      <p>{{ alert.message }}</p>
    </div>
    <div class="container">
      <div class="row">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <div class="login-box">
            <div class="login-light text-center">
              <p>Log in</p>
            </div>

            <div class="login-dark text-center">
              <p class="welcome">Welcome back to MadScientist...🖖🏻</p>

              <p class="reality">
                /Reality prompt:
                <span class="technology"
                  >Technology is advancing rapidly and so should you. Login to
                  stay updated with the technoverse.</span
                >
              </p>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
              "
              class="social row"
            >
              <!-- <label class="loginusing">Login using</label> -->
              <asl-google-signin-button 
              class="col"
              type='standard' 
              shape="rectangular" 
              size='large' 
              theme="filled_black" 
              text="signin_with" 
              locale="en-GB"
              size="large" 
              logo_alignment="left"></asl-google-signin-button>
              <div class="col"></div>
              <!-- <div id="g_id_onload"
         data-client_id="467564314205-7lu47muih8jno45os7inbrrnsi4if4no.apps.googleusercontent.com"
         data-ux_mode="redirect"
         data-login_uri="https://madscientist.in/">
    </div>
    <div class="g_id_signin" data-type="standard"></div> -->

              <!-- <div
                class="col"
                style="
                  margin-right: -15px;
                  flex: 1 0 0%;
                  color: white;
                  line-height: 1.5;
                "
              >
                <p class="signin login-dark-signin">
                  <img
                    src="assets/images/google.svg"
                    class=""
                    style="
                      margin-bottom: 0px;
                      display: inline-block;
                      padding-right: 10px;
                      vertical-align: middle;
                    "
                  />Google
                </p>
              </div> -->
              <!-- <div
                class="col"
                style="
                  margin-right: -15px;
                  flex: 1 0 0%;
                  color: white;
                  line-height: 1.5;
                "
              >
                <p class="signin login-dark-signin">
                  <img
                    src="assets/images/apple.svg"
                    style="
                      margin-bottom: 5px;
                      display: inline-block;
                      padding-right: 10px;
                      vertical-align: middle;
                    "
                  />Apple
                </p>
              </div> -->
              <!-- <div
                class="col"
                style="flex: 1 0 0%; color: white; line-height: 1.5"
              >
                <p class="signin login-dark-signin">
                  <img
                    src="assets/images/git.svg"
                    style="
                      margin-bottom: 5px;
                      display: inline-block;
                      padding-right: 10px;
                      height: 20px;
                      vertical-align: middle;
                    "
                  />GitHub
                </p>
              </div> -->
            </div>

            <div
              class="d-flex align-items-center"
              style="margin-bottom: 15px; display: flex; align-items: center"
            >
              <div
                style="
                  flex-grow: 1;
                  height: 1px;
                  background-color: #212121;
                  margin-top: 12px;
                  margin-left: 20px;
                "
              ></div>
              <div
                class="loginemail"
                style="margin-left: 0.5rem; margin-right: 0.5rem"
              >
                Or login with email
              </div>
              <div
                style="
                  flex-grow: 1;
                  height: 1px;
                  background-color: #212121;
                  margin-top: 12px;
                  margin-right: 20px;
                "
              ></div>
            </div>

            <div class="login-form-box">
              <form class="contact-form" autocomplete="off">
                <div class="mb-3" style="margin-bottom: 0.75rem">
                  <label class="text-black testusername">Username/ Email</label>
                  <label class="errorusername" *ngIf="!userNamevalidationFlg"
                    >Wrong email or username</label
                  >
                  <input
                    type="text"
                    id="mademail"
                    class="form-control"
                    autocomplete="off"
                    name="mademail"
                    [(ngModel)]="user_name"
                    (focusout)="userNameValidation()"
                  />
                </div>
                <div class="mb-3" style="margin-bottom: 0.75rem">
                  <label class="text-black testusername">Password </label>
                  <input
                    type="password"
                    style="-webkit-text-security: star"
                    autocomplete="off"
                    name="pass"
                    class="form-control"
                    id="madpassword"
                    [(ngModel)]="password"
                  />
                  <svg
                    onclick="ViewPassword()"
                    id="visibleIcon"
                    style="
                      display: none;
                      margin-top: -27px;
                      margin-right: 5px;
                      float: right;
                    "
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.9132 13.3333C18.1452 13.3333 18.3345 13.1451 18.3228 12.9133C18.2748 11.9625 18.0641 11.0258 17.699 10.1443C17.2802 9.13326 16.6664 8.2146 15.8926 7.44078C15.1187 6.66696 14.2001 6.05313 13.189 5.63434C12.178 5.21555 11.0944 5 10 5C8.90567 5 7.82203 5.21555 6.81099 5.63434C5.79994 6.05313 4.88128 6.66696 4.10746 7.44078C3.33364 8.2146 2.71981 9.13326 2.30102 10.1443C1.93589 11.0258 1.72525 11.9625 1.67727 12.9133C1.66558 13.1451 1.8548 13.3333 2.08685 13.3333C2.31891 13.3333 2.5058 13.1451 2.5188 12.9134C2.56598 12.0729 2.7545 11.2454 3.0774 10.4659C3.45395 9.5568 4.00588 8.73078 4.70167 8.03499C5.39746 7.3392 6.22348 6.78727 7.13257 6.41071C8.04166 6.03416 9.01602 5.84034 10 5.84034C10.984 5.84034 11.9584 6.03416 12.8675 6.41071C13.7765 6.78727 14.6026 7.3392 15.2984 8.03499C15.9941 8.73078 16.5461 9.5568 16.9226 10.4659C17.2455 11.2454 17.434 12.0729 17.4812 12.9134C17.4942 13.1451 17.6811 13.3333 17.9132 13.3333ZM12.2543 13.757C13.3751 14.303 14.1667 14.6886 14.1667 13.3333C14.1667 11.0321 12.3012 9.16667 10 9.16667C7.69883 9.16667 5.83335 11.0321 5.83335 13.3333C5.83335 14.6196 6.286 14.3378 7.08713 13.8389C7.71928 13.4454 8.56841 12.9167 9.58335 12.9167C10.5293 12.9167 11.472 13.3759 12.2543 13.757Z"
                      fill="#333333"
                    />
                    <path
                      d="M16.6667 3.3335L2.5 17.5002"
                      stroke="#333333"
                      stroke-width="0.833333"
                      stroke-linecap="round"
                    />
                  </svg>
                  <svg
                    onclick="ViewPassword()"
                    id="hiddenIcon"
                    style="
                      display: inline-block;
                      margin-top: -27px;
                      margin-right: 5px;
                      float: right;
                    "
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.2982 8.00498C14.6323 7.34573 13.8472 6.81692 12.9842 6.44588C12.9472 6.42996 12.91 6.41433 12.8727 6.39899L12.8673 6.39676C11.9582 6.02393 10.9838 5.83203 9.99982 5.83203C9.01583 5.83203 8.04147 6.02393 7.13238 6.39676L7.12696 6.39899C7.08964 6.41433 7.05246 6.42996 7.01543 6.44588C6.1524 6.81692 5.36731 7.34573 4.70148 8.00498C4.00569 8.69389 3.45376 9.51174 3.0772 10.4118C2.75485 11.1824 2.56641 12.0003 2.51885 12.831C2.50558 13.0627 2.31872 13.2509 2.08666 13.2509C1.85461 13.2509 1.66536 13.0627 1.67729 12.8309C1.72568 11.8909 1.93623 10.9649 2.30083 10.0934C2.71962 9.09239 3.33344 8.18281 4.10727 7.41664C4.85006 6.68119 5.72632 6.0917 6.68964 5.67886C6.72987 5.66162 6.77026 5.64469 6.81079 5.62807L6.81621 5.62585C7.82571 5.21266 8.90743 5 9.99982 5C11.0922 5 12.1739 5.21266 13.1834 5.62585L13.1889 5.62806C13.2294 5.64469 13.2698 5.66162 13.31 5.67886C14.2733 6.0917 15.1496 6.68119 15.8924 7.41664C16.6662 8.18281 17.28 9.09239 17.6988 10.0934C18.0634 10.9649 18.274 11.8909 18.3224 12.8309C18.3343 13.0627 18.145 13.2509 17.913 13.2509C17.6809 13.2509 17.4941 13.0627 17.4808 12.831C17.4332 12.0003 17.2448 11.1824 16.9224 10.4118C16.5459 9.51174 15.994 8.69389 15.2982 8.00498ZM12.2541 13.6705C13.3749 14.2111 14.1665 14.5929 14.1665 13.251C14.1665 10.9726 12.301 9.12557 9.99982 9.12557C7.69864 9.12557 5.83316 10.9726 5.83316 13.251C5.83316 14.5246 6.2858 14.2455 7.08694 13.7517C7.71909 13.362 8.56822 12.8385 9.58316 12.8385C10.5291 12.8385 11.4718 13.2932 12.2541 13.6705Z"
                      fill="white"
                    />
                  </svg>
                  <!-- <img
                    id="passwordicon"
                    src="assets/images/hidden.svg"
                    style="
                      height: 20px;
                      display: inline-block;
                      margin-top: -27px;
                      margin-right: 5px;
                      float: right;
                    "
                    onclick="ViewPassword()"
                  /> -->
                  <a
                    (click)="forgotPass()"
                    class="forgot-password float-end"
                    style="float: right"
                    >Forgot password?</a
                  >
                </div>
                <label class="errorusername" *ngIf="!passwordValidationFlg">Invalid Username/Password</label>

                <button
                  (click)="onLogin('web')"
                  type="submit"
                  class="btn-mad"
                  style="
                    width: 100%;
                    font-family: 'Exo 2', sans-serif;
                    color: white;
                  "
                >
                  <p>
                    Login<img
                      src="assets/images/arrow.svg"
                      style="
                        display: inline-block;
                        padding-right: 10px;
                        padding-left: 5px;
                        vertical-align: middle;
                      "
                    />
                  </p>
                </button>
                <p class="termsnc">
                  By continuing, you agree to our
                  <a href="https://madscientist-tech.notion.site/PRIVACY-POLICIES-35259db25d1c431494b2e7cffe364b8f?pvs=4">privacy policies</a>,
                  <a href="https://madscientist-tech.notion.site/TERMS-OF-SERVICE-5e7f553e19c247ca83ef7e2744516987?pvs=4">terms & conditions</a> and
                  <a href="https://madscientist-tech.notion.site/COMMUNITY-GUIDELINES-247ef1e57b754179a1d2d3c48e10aa96?pvs=4">community Guidelines</a>
                </p>
              </form>
            </div>
          </div>
        </div>
        <p class="text-center issues" style="text-align: center">
          For any issues contact hello@madscientist.in
        </p>
      </div>
    </div>
  </div>
</body>
