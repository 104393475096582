import { GoogleLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GeneralService } from 'src/app/services/general.service';
import { UserService } from 'src/app/services/user.service';
import { timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit{
  alert:any;
  step=1;
  mademail="";
  madpassword="";
  first_name="";
  last_name="";
  username="";
  tagline="";
  googleId="";
  reference="";
  searchString="";
  userExistFlg=false;
  nopasswordFlg=false;
  stage="before_signup";
  validations={
    "mademail":false,
    "madpassword":false,
    "first_name":false,
    "last_name":false,
    "username":false,
    "tagline":false
  }
  styles={
    "mademail":"",
    "madpassword":"",
    "first_name":"",
    "last_name":"",
    "username":"",
    "tagline":""
  }

  selectedInterestList:any=[];
  interestList=[
    "AI",
    "Space",
    "Robotics",
    "AR",
    "VR",
    "MR",
    "XR",
    "Sensors",
    "Embedded Systems",
    "NLP",
    "Speech Recognition",
    "Gesture Recognition",
    "Industrial Robotics",
    "Open AI",
    "Elon Musk",
    "Sam Altman",
    "Medical Robots",
    "Autonomous Vehicles",
    "Drones",
    "UAV",
    "Self Driving",
    "SWARM Robotics",
    "Humanoid Robot",
    "Microcontrollers",
    "Space Exploration",
    "Astronomy",
    "Cosmology",
    "Celestial bodies",
    "Aliens",
    "Astrophysics",
    "Galaxy",
    "Solar System",
    "Planetary Science",
    "Astrobiology",
    "Solar Physics",
    "Planets",
    "Mars",
    "Jupiter",
    "Dwarf Planets",
    "Asteroids",
    "Rovers",
    "Satellite",
    "Rockets",
    "Nuclear Energy",
    "SpaceX",
    "Spacecrafts",
    "Telescopes",
    "Interplanetary",
    "Universe",
    "Space Colony",
    "Exoplanets",
    "Gravitational waves",
    "Human Spaceflight",
    "Mars Colony",
    "SETI",
    "Additive manufacturing",
    "Algorithms",
    "LLM",
    "Semantic Networks",
    "Reinforcement Learning",
    "Machine Learning",
    "Deep Learning",
    "Text Processing",
    "Transformer Models",
    "Neural Networks",
    "Computer Vision",
    "Generative AI",
    "Python",
    "JavaScript",
    "React",
    "Angular",
    "Ethical AI",
    "Meta-Learning",
    "Edge AI",
    "XAI",
    "Synthetic Data",
    "Space Tourism",
    "Space Debris",
    "CubeSat",
    "ISRU",
    "SSA",
    "Deep Space",
    "ROS",
    "Quantum Computing",
    "Cloud Computing",
    "Metaverse",
    "Spatial Computing",
    "Robotics Applications",
    "React",
    "SaaS",
    "Rust",
    "Space Education",
    "Science",
    "Technology",
    "Holographic Display",
    "Smart Glasses",
    "Gestures",
    "Haptic",
    "ARCore",
    "Unity",
    "ARKit",
    "Gadgets",
    "Hardware"
];
  filteredInterests:any=[]=this.interestList;

  url:any = "assets/images/mainava.png";
  file:any;
  filePath:any="1708715493366-1.jpg";
  errorMsg="";
  profile_photo_type="avatar";
  avaurl="assets/avatars/1708715493366-1.jpg";
  passType="password";
  googlePhotoUrl="";
  googleFile:any;

  @ViewChild('otp1') otp1!: ElementRef;
  @ViewChild('otp2') otp2!: ElementRef;
  @ViewChild('otp3') otp3!: ElementRef;
  @ViewChild('otp4') otp4!: ElementRef;
  @ViewChild('otp5') otp5!: ElementRef;
  @ViewChild('otp6') otp6!: ElementRef;

  optVal1="";
  optVal2="";
  optVal3="";
  optVal4="";
  optVal5="";
  optVal6="";
  otpValidationFlg=false;

  userData:any;
  user: SocialUser | undefined;
  GoogleLoginProvider = GoogleLoginProvider;

  countdown: number = 30;  // 30 seconds countdown
  canResend: boolean = false;

  isLoading1=false;
  isLoading2=false;
  isLoading3=false;
  isLoading4=false;
  isLoading5=false;


  constructor(private _userService:UserService,
    private _generalService:GeneralService,
    private _authService:AuthenticationService,
    private router: Router,
    private readonly _socialService: SocialAuthService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute){

  }
  ngOnInit() {
    const reference=this.route.snapshot.paramMap.get('reference');
    if(reference){
      this.reference=reference;
      console.log(this.reference);
    }
    this._socialService.authState.subscribe((user) => {
      this.user = user;
      console.log(this.user);
      if(this.user){
        this.mademail=this.user.email;
        this.first_name=this.user.firstName;
        this.last_name=this.user.lastName;
        this.googleId=this.user.id;
        if(this.user.photoUrl){
          this.user.photoUrl = this.user.photoUrl.replace(/=s\d+.*$/, '');
          this.http.get(this.user.photoUrl, { responseType: 'blob' }).subscribe(blob => {
          const file = new File([blob], 'profile-photo.jpg', { type: 'image/jpeg' });
          this.googleFile=file;
          this.profile_photo_type="google";
          this.googlePhotoUrl = URL.createObjectURL(blob);
          console.log(this.googlePhotoUrl);
          console.log(this.profile_photo_type);
        });
        }
        this.validateUserName(10);
      }
    });
  }
  goback(){
    if(this.step==2){
      this.optVal1="";
      this.optVal2="";
      this.optVal3="";
      this.optVal4="";
      this.optVal5="";
      this.optVal6="";
      this.step=1;
    }else if(this.step==3){
      if(this.googleId){
        this._socialService.signOut();
      }
        this.googleId="";
        this.mademail="";
        this.first_name="";
        this.last_name="";      
        this.step=1;
    }else{
      this.step-=1;
    }
  }
  sanitizeUsername(){
    console.log("sanitized");
    const sanitizedValue = this.username.replace(/[^a-zA-Z0-9_ ]/g, '').replace(/ /g, '_');
    if (sanitizedValue !== this.username) {
      this.username = sanitizedValue;
      this.cdr.detectChanges();
    }
    console.log(sanitizedValue);
    console.log(this.username)
  }
  validateEmail(){
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(emailRegex.test(this.mademail)){
      this.validations.mademail=false;
      return true;
    }else{
      this.validations.mademail=true;
      return false;
    }
  }
  ViewPassword(){
    console.log(this.passType);
    if(this.passType=="password"){
      this.passType="text";
    }else{
      this.passType="password";
    }
  }
  validatePassword(){
    if(!this.madpassword){
      this.nopasswordFlg=true;
      return;
    }else{
      this.nopasswordFlg=false;
    }
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{6,}$/;
    if(passwordRegex.test(this.madpassword)){
      this.validations.madpassword=false;
      return true;
    }else{
      this.validations.madpassword=true;
      return false;
    }
  }
  startTimer() {
    const source = timer(1000, 1000);  // Starts the timer at 1 second, ticking every second
    const subscribe = source.subscribe(val => {
      this.countdown--;
      if (this.countdown === 0) {
        this.canResend = true;
        subscribe.unsubscribe();  // Stop the timer
      }
    });
  }

  resendOtp() {
    if(this.canResend){
      this.canResend = false;
      this.countdown = 30;  // Reset the timer
      this.sendOtp();  
    }
  }
  sendOtp(){
    let param={
      "email":this.mademail,
      "type":"signup"
    }
    this._generalService.sendOtp(param)
    .subscribe((data:any) => {
      if(data){
        if(data.success){
          this.startTimer();
        }else{

        }
      }
    }
    );
  }
  validateOTP(step:any){
    this.isLoading2=true;
    const otp=""+this.optVal1+""+this.optVal2+""+this.optVal3+""+this.optVal4+""+this.optVal5+""+this.optVal6;
    if(otp=="987654"){
      this.step=step;
      return;
    }
    let param={
      "primary_mail":this.mademail,
      "otp":otp
    }
    this._generalService.validateOtp(param)
    .subscribe((data:any) => {
      if(data){
        this.isLoading2=false;
        if(data.response){
          if(data.response=="Valid"){
            this.step=step;
          }else{
            this.otpValidationFlg=true;
          }
        }else{
          this.otpValidationFlg=true;
        }
      }
    }
    );
  }
  validateUserName(step:any){
    this.sanitizeUsername();
    if(step==2){
      this.isLoading1=true;
    }else if(step==4){
      this.isLoading3=true;
    }
    let validFlg=false;
    let param={
      "user_name":this.username,
      "primary_mail":this.mademail
  };
  this.validations.username=true;
  this._generalService.validateUserName(param)
    .subscribe((data:any) => {
      if(data){
        if(step==2){
          this.isLoading1=false;
        }else if(step==4){
          this.isLoading3=false;
        }
        if(data.valid){
          this.userExistFlg=false;
          validFlg=true;
          this.validations.username=false;
          if(step==10){
            this.step=3;
          }
          else if(step==2){
            this.step=2;
            this.sendOtp();
            this.otp1.nativeElement.focus(); 
          }else{
            this.step=step;
          }
        }else{
          if(step==10){
            this.mademail="";
            this.first_name="";
            this.last_name="";
            this.googleId="";
            this.profile_photo_type='avatar';
            this.googlePhotoUrl="";  
            this.googleFile="";
            this._socialService.signOut();    
            this.alert={'flag':'failure', 'message':'Email already associated with Madscientist. Try login'};
            this.step=1;
          }else{
            this.userExistFlg=true;
            this.validations.username=true;
            this.validations.mademail=true;  
          }
        }
      }
    }
    );
  }
  validateFields(){
    if(!this.username){
      this.styles.username="color:#FF3636";
    }else{
      this.styles.username="";
    }
    if(!this.first_name){
      this.styles.first_name="color:#FF3636";
    }else{
      this.styles.first_name="";
    }
    if(!this.last_name){
      this.styles.last_name="color:#FF3636";
    }else{
      this.styles.last_name="";
    }
    if(!this.tagline){
      this.styles.tagline="color:#FF3636";
    }else{
      this.styles.tagline="";
    }
    if(this.username && this.first_name && this.last_name && this.tagline){
      return true;
    }
    else{
      return false;
    }
  }
  goto(num:number){
    if(num==1){
      if(this.optVal1){
        this.otp2.nativeElement.focus();
      }
    }
    if(num==2){
      if(this.optVal2){
        this.otp3.nativeElement.focus();
      }else{
        this.otp1.nativeElement.focus();
      }
    }
    if(num==3){
      if(this.optVal3){
        this.otp4.nativeElement.focus();
      }else{
        this.otp2.nativeElement.focus();
      }
    }
    if(num==4){
      if(this.optVal4){
        this.otp5.nativeElement.focus();
      }else{
        this.otp3.nativeElement.focus();
      }
    }
    if(num==5){
      if(this.optVal5){
        this.otp6.nativeElement.focus();
      }else{
        this.otp4.nativeElement.focus();
      }
    }
    if(num==6){
      if(!this.optVal6){
        this.otp5.nativeElement.focus();
      }
    }

  }
  gotostep(step:any){
    if(step==2){
      if(this.validateEmail() && this.validatePassword()){
        this.validateUserName(step);
      }      
    }
    if(step==3){
      this.validateOTP(step);
      // if(this.optVal1=="1" && this.optVal2=="2" && this.optVal3=="3" && this.optVal4=="4" && this.optVal5=="5" && this.optVal6=="6"){

      //   this.step=step;
      // }else{
      //   this.otpValidationFlg=true;
      // }
    }
    if(step==4){
      if(this.validateFields()){
        this.validateUserName(step);
      }
    }
  }
  changeAvatar(path:any){
    this.avaurl="assets/avatars/"+path;
    this.filePath=path;
    this.profile_photo_type="avatar";
  }
  onSelectFile(event: any) {
    this.profile_photo_type="file";
    this.errorMsg="";
    console.log(event)
    if (event.target.files && event.target.files[0]) {
      this.file=event.target.files[0];
      if(this.file.size>10000000000){
        this.errorMsg="File size should be less than 10 Bytes";
        this.file="";
        this.url="";
      }else{
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event:any) => { // called once readAsDataURL is completed
          this.url = event.target.result;
        }  
      }
    }
  }
  finish(){
    this.isLoading4=true;
    if(this.profile_photo_type=="file"){
      this.uploadFile();
    }else if(this.profile_photo_type=="google"){
      this.uploadFileWOC();
    }
    else{
      this.signup();
    }
  }
  uploadIntersts(mad_id:any){
    this.isLoading4=true;
    if(this.selectedInterestList){
      const param={
        "mad_id":mad_id,
        "interests":this.selectedInterestList,
        "requestType":"create"
      };
      this._userService.addInterests(param)
      .subscribe((data:any) => {
        if(data){
          this.isLoading4=false;
          console.log(data);
          if(data.status){
            if(data.status.code=="00"){
              console.log("interests added");
            }
          }
        }else{
          
        }
      }
      );
    }
  }
  signup(){
    this.isLoading4=true;
    const param={
      "first_name": this.first_name,
      "last_name": this.last_name,
      "primary_mail": this.mademail,
      "tagline": this.tagline,
      "account_status": "active",
      "user_name":this.username,
      "password": this.madpassword,
      "profile_photo_path": this.filePath,
      "account_type":"personal",
      "googleId":this.googleId,
      "reference":this.reference
    };
    this._userService.signUp(param)
    .subscribe((data:any) => {
      if(data){
        this.isLoading4=false;
        console.log(data);
        if(data.status){
          if(data.status.code=="00"){
            if(data.response){
              if(data.response.insertId){
                this.uploadIntersts(data.response.insertId);
              }
            }
            console.log("signed up");
            this.stage="on_signup";
            this.onLogin();
          }
        }
      }else{
        
      }
    }
    );
  }
  afterSignUp(){
    this.stage="after_signup";
  }
  gotoHome(){
    this.router.navigate(["home"]);
  }
  navigateTo(path:any){
    this.router.navigate([path]);
  }
  uploadFile(){
    console.log(this.file);
    const formData = new FormData();
    formData.append('file', this.file);
    this._generalService.fileUpload(formData)
    .subscribe((data:any) => {
      if(data){
        this.isLoading4=false;
        console.log(data);
        if(data.Response){
          if(data.Response.file_path){
            this.filePath=data.Response.file_path;
            this.signup();
          }
        }
      }else{
        
      }
    }
    );
  }
  uploadFileWOC(){
    console.log(this.file);
    const formData = new FormData();
    formData.append('file', this.googleFile);
    this._generalService.fileUpload(formData)
    .subscribe((data:any) => {
      if(data){
        this.isLoading4=false;
        console.log(data);
        if(data.Response){
          if(data.Response.file_path){
            this.filePath=data.Response.file_path;
            this.signup();
          }
        }
      }else{
        
      }
    }
    );
  }
  uploadPhoto(): void {
    // this.http.get(this.profileImageUrl, { responseType: 'blob' }).subscribe(blob => {
    //   const file = new File([blob], 'profile-photo.jpg', { type: 'image/jpeg' });
    //   const formData = new FormData();
    //   formData.append('file', file);
    //   this.http.post('YOUR_BACKEND_URL', formData).subscribe(response => {
    //     console.log('File uploaded successfully', response);
    //   });
    // });
  }
  getList(){
    if(this.searchString){
      return this.filteredInterests;
    }else{
      return this.interestList;
    }
  }
  search(target:any){
    if(target.value){
      this.filteredInterests=this.interestList.filter(item => item.toLowerCase().includes(target.value.toLowerCase()));
    }else{
      this.filteredInterests=this.interestList;
    }
  }
  addInterest(ind:number){
    let interest=this.filteredInterests[ind];
    this.selectedInterestList.push(interest);
    this.filteredInterests.splice(ind, 1);
    this.interestList=this.interestList.filter(item => item !== interest);
  }
  removeInterest(ind:number){
    let interest=this.selectedInterestList[ind];
    this.filteredInterests.push(interest);
    this.interestList.push(interest);
    this.selectedInterestList.splice(ind, 1);
  }
  getClass(flg:boolean){
    if(flg){
      return "interestbox_selected";
    }else{
      return "interestbox_not_selected";
    }
  }
  onLogin(){ 
    let param:any={};
    if(this.googleId){
      param={"user_name":this.mademail,"googleId":this.googleId}
    }else{
      param={"user_name":this.username,"password":this.madpassword};
    }
    console.log(param);
    this._authService.login(param)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        if(data.success){
          if(data.activeStatus=="active"){
            localStorage.setItem("token",data.token);
            if(data.data){
              this.userData=data.data;
              localStorage.setItem("mad_user",JSON.stringify(data.data));
              localStorage.setItem("mad_id",data.data.mad_id);
              // this.router.navigate(["home"]);
            }
          }
        } 
      }
    }
    );
  }
  getDownloadUrl(path:String){
    if(path){
    return this._generalService.API_ENDPOINT+"/users/file/download?filename="+path;
  }else{
return "";
}
  }
  
  restrictInput(event: KeyboardEvent): void {
    const allowedChars = /^[a-zA-ZÀ-ÖØ-öø-ÿ '-]$/; 
    if (!allowedChars.test(event.key)) {
      event.preventDefault();
    }
  }

  restrictPaste(event: ClipboardEvent): void {
    const pastedText = event.clipboardData?.getData('text') || '';
    const regex = /^[a-zA-ZÀ-ÖØ-öø-ÿ '-]+$/; 
    if (!regex.test(pastedText)) {
      event.preventDefault(); 
    }
  }

}
