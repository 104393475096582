import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { GeneralService } from 'src/app/services/general.service';
import { UserService } from 'src/app/services/user.service';
import { CropImageComponent } from '../crop-image/crop-image.component';

@Component({
  selector: 'app-view-profile-photo',
  templateUrl: './view-profile-photo.component.html',
  styleUrls: ['./view-profile-photo.component.css']
})
export class ViewProfilePhotoComponent {
  userData:any;
  url:any = "";
  file:any="";
  filePath:any="";
  errorMsg="";
  profile_photo_type="file";
  avaurl="assets/avatars/1708715493366-1.jpg";

  imageChangedEvent: any = '';
  croppedImage: any = null;

  constructor(public dialogRef: MatDialogRef<ViewProfilePhotoComponent>,
    private _userService:UserService,
    private _generalService: GeneralService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog){
      this.userData=data;
  }
  getDownloadUrl(path: String) {
    if (path) {
      return this._generalService.API_ENDPOINT + "/users/file/download?filename=" + path;
    } else {
      return "";
    }
  }
  changeAvatar(path:any){
    this.avaurl="assets/avatars/"+path;
    this.filePath=path;
    this.profile_photo_type="avatar";
  }
  close(data:any){
    this.dialogRef.close(data);
  }
  save(){
    if(this.file){
      this.uploadFile();
    }else{
      this.editProfile();
    }
  }
  uploadFile(){
    console.log(this.file);
    const formData = new FormData();
    formData.append('file', this.file);
    this._generalService.fileUpload(formData)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        if(data.Response){
          if(data.Response.file_path){
            this.filePath=data.Response.file_path;
            this.editProfile();
          }
        }
      }else{
        
      }
    }
    );
  }
  onSelectFile(event: any) {
    this.errorMsg="";
    console.log(event)
    if (event.target.files && event.target.files[0]) {
      this.file=event.target.files[0];
    }
  }
  editProfile() {
    const param={
      "profile_photo_path": this.filePath,
  };
    this._userService.editProfile(param)
    .subscribe((data:any) => {
      if(data){
        console.log(data);
        if(data.status){
          if(data.status.code=="00"){
            console.log("profile uploaded");
            this.close(this.filePath)
          }
        }
      }else{
        
      }
    }
    );
  }
  onFileChange(event: any): void {
    this.imageChangedEvent = event;
    const dialogRef = this.dialog.open(CropImageComponent, {
      data: this.imageChangedEvent,
      width: '30%',
      height: '60%',
      backdropClass: "bdrop",
      position: {
        'bottom': '0px',
        'left': '35%',
        'right': '0px',
        'top': '6.5%'
      }
    }
    );
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.file=result;
        const reader = new FileReader();
        reader.onload = () => {
          this.url = reader.result as string; // Assign data URL
        };
        reader.readAsDataURL(this.file);
        // var reader = new FileReader();
        // reader.readAsDataURL(event.target.files[0]); // read file as data url
        // reader.onload = (event:any) => { // called once readAsDataURL is completed
        //   this.url = event.target.result;
        // }    
      }
      console.log(`Dialog result: ${result}`);
    });
  }

}
